import React from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";

const Heading = ({
  text,
  highlightedText,
  highlightColor = "#164ED4",
  className = "",
}) => {
  return (
    <div
      className={`text-xl md:text-2xl lg:text-3xl leading-tight font-bold ${className}`}
    >
      <Typography
        variant="inherit"
        component="h2"
        className="text-[#4A4C56]"
        style={{
          fontFamily: "inherit",
          fontWeight: "bold",
          fontSize: "inherit",
          lineHeight: "inherit",
        }}
      >
        {text}
      </Typography>
      {highlightedText && (
        <Typography
          variant="inherit"
          component="span"
          className="text-[#164ED4]"
          style={{
            fontFamily: "inherit",
            fontWeight: "bold",
            fontSize: "inherit",
            lineHeight: "inherit",
          }}
        >
          {highlightedText}
        </Typography>
      )}
    </div>
  );
};

Heading.propTypes = {
  text: PropTypes.string.isRequired,
  highlightedText: PropTypes.string,
  highlightColor: PropTypes.string,
  className: PropTypes.string,
};

export default Heading;
