/**
 * Function to get the yearly change data
 * @param {*} data - The data object containing yearly summaries
 * @returns {Object} yearlyChange - Object containing the change in total filings, approvals, and denials
 */
export const getYearlyChangeData = (data) => {
  const yearlyChange = {
    totalFilings: 0,
    approvals: 0,
    denials: 0,
    latestYear: null,
    previousYear: null,
    hasPreviousYearData: false,
  };

  if (!data.yearly_summary) {
    return yearlyChange;
  }

  const yearlySummary = data.yearly_summary.reduce((acc, curr) => {
    acc[curr.year] = curr;
    return acc;
  }, {});

  const years = Object.keys(yearlySummary)
    .map(Number)
    .sort((a, b) => b - a);
  const latestYear = years[0];
  const previousYear = years[1];

  if (latestYear && previousYear) {
    const latestSummary = yearlySummary[latestYear];
    const previousSummary = yearlySummary[previousYear];

    yearlyChange.totalFilings =
      (latestSummary.total_approved_cases || 0) +
      (latestSummary.total_denied_cases || 0) -
      ((previousSummary.total_approved_cases || 0) +
        (previousSummary.total_denied_cases || 0));
    yearlyChange.approvals =
      (latestSummary.total_approved_cases || 0) -
      (previousSummary.total_approved_cases || 0);
    yearlyChange.denials =
      (latestSummary.total_denied_cases || 0) -
      (previousSummary.total_denied_cases || 0);

    yearlyChange.latestYear = latestYear;
    yearlyChange.previousYear = previousYear;
    yearlyChange.hasPreviousYearData = true;
  } else if (latestYear) {
    const latestSummary = yearlySummary[latestYear];

    yearlyChange.totalFilings =
      (latestSummary.total_approved_cases || 0) +
      (latestSummary.total_denied_cases || 0);
    yearlyChange.approvals = latestSummary.total_approved_cases || 0;
    yearlyChange.denials = latestSummary.total_denied_cases || 0;
    yearlyChange.latestYear = latestYear;
  }

  return yearlyChange;
};

/**
 * Function to get the data for the latest year available in the dataset
 * @param {*} data - The data object containing yearly summaries
 * @returns {Object|null} latestYearData - Object containing the latest year's filings, approvals, denials, approval rate, and denial rate
 */
export const getLatestYearData = (data) => {
  let latestYear = 0;
  let latestSummary = null;

  if (!data.yearly_summary) {
    return null;
  }

  data.yearly_summary.forEach((summary) => {
    if (summary.year > latestYear) {
      latestYear = summary.year;
      latestSummary = summary;
    }
  });

  if (!latestSummary) {
    return null;
  }

  const totalFilings =
    (latestSummary.total_approved_cases || 0) +
    (latestSummary.total_denied_cases || 0);
  const approvals = latestSummary.total_approved_cases;
  const denials = latestSummary.total_denied_cases;
  const approvalRate = totalFilings ? (approvals / totalFilings) * 100 : 0;
  const denialRate = totalFilings ? (denials / totalFilings) * 100 : 0;

  const latestYearData = {
    totalFilings,
    approvals,
    denials,
    approvalRate,
    denialRate,
    latestYear,
  };

  return latestYearData;
};
