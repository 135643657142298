import React from "react";

const TermsAndConditions = () => {
  return (
    <div className="px-6 py-10 max-w-6xl mx-auto">
      {/* Header */}
      <header className="mb-10">
        <h1 className="text-4xl font-bold text-gray-900 mb-2">
          Terms and Conditions
        </h1>
        <p className="text-gray-600 text-lg">
          <strong>Last Updated:</strong> November 19, 2024
        </p>
        <p className="mt-4 text-gray-700">
          Welcome to <strong>VisJobs</strong>! These terms and conditions
          outline the rules and regulations for using <strong>VisJobs</strong>'
          services and website, located at{" "}
          <a
            href="https://www.visjobs.com"
            className="text-blue-500 underline"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.visjobs.com
          </a>
          .
        </p>
      </header>

      {/* Terms Content */}
      <section className="space-y-8">
        <div id="acceptance-of-terms">
          <h2 className="text-2xl font-semibold text-gray-900 mb-2">
            1. Acceptance of Terms
          </h2>
          <p className="text-gray-700">
            By accessing <strong>VisJobs</strong>, you agree to these Terms.
            These Terms constitute a legally binding agreement between you (the
            “Client”) and <strong>VisJobs LLC</strong>. If you do not accept
            these Terms, you must refrain from using our services.
          </p>
        </div>

        <div id="nature-of-services">
          <h2 className="text-2xl font-semibold text-gray-900 mb-2">
            2. Nature of Services
          </h2>
          <p className="text-gray-700">
            <strong>VisJobs</strong> provides tools and resources to support
            your job search and career advancement. These services include, but
            are not limited to:
          </p>
          <ul className="list-disc list-inside text-gray-700 mt-2">
            <li>Job matching</li>
            <li>Resume and cover letter review</li>
            <li>Auto-apply job application services</li>
            <li>Visa guidance for H1B-related employment opportunities</li>
            <li>Interview preparation and career consulting</li>
          </ul>
          <p className="mt-4 text-gray-700">
            While we strive to deliver high-quality services,{" "}
            <strong>VisJobs</strong> does not guarantee specific outcomes, such
            as job placement, interviews, or promotions, as these depend on
            factors beyond our control.
          </p>
        </div>

        <div id="user-responsibilities">
          <h2 className="text-2xl font-semibold text-gray-900 mb-2">
            3. User Responsibilities
          </h2>
          <p className="text-gray-700">
            - You must be at least 18 years old to use our services.
          </p>
          <p className="text-gray-700">
            - You are solely responsible for the accuracy and completeness of
            the information provided to <strong>VisJobs</strong>, including your
            resume, cover letter, and preferences.
          </p>
          <p className="text-gray-700">
            - Any decisions you make based on the recommendations provided by{" "}
            <strong>VisJobs</strong> are at your discretion and risk.
          </p>
        </div>

        <div id="refund-policy">
          <h2 className="text-2xl font-semibold text-gray-900 mb-2">
            4. Refund Policy
          </h2>
          <p className="text-gray-700">
            Services provided by <strong>VisJobs</strong> are non-refundable, as
            the fees contribute to extensive personalized work, including
            research, consultations, and document preparation.
          </p>
          <p className="text-gray-700">
            Unused services may be transferred to a nominee of your choice
            within 3 months of purchase if no progress has been made on the
            service.
          </p>
          <p className="text-gray-700">
            Services not initiated within 3 months will be considered complete.
          </p>
        </div>

        <div id="auto-apply-terms">
          <h2 className="text-2xl font-semibold text-gray-900 mb-2">
            5. Auto-Apply Service Terms
          </h2>
          <p className="text-gray-700">For Auto-Apply services:</p>
          <ul className="list-disc list-inside text-gray-700 mt-2">
            <li>
              Job applications will be made based on your preferences, including
              job titles, locations, and visa requirements.
            </li>
            <li>
              <strong>VisJobs</strong> will not guarantee that job applications
              meet additional salary expectations, nor do we guarantee
              interviews or job offers.
            </li>
            <li>
              You must approve the list of jobs provided by{" "}
              <strong>VisJobs</strong> within 2 business days, or consent will
              be assumed.
            </li>
            <li>
              H1B-related searches are based on historical data from the H1B
              database and do not guarantee current sponsorship availability.
            </li>
            <li>
              If a job listing is no longer available before we apply on your
              behalf, we will notify you and attempt to find a suitable
              replacement. If we do not receive a response from you within 24
              hours, we will proceed to apply for a job that closely matches the
              original listing based on your preferences.
            </li>
          </ul>
        </div>

        <div id="limitation-of-liability">
          <h2 className="text-2xl font-semibold text-gray-900 mb-2">
            6. Limitation of Liability
          </h2>
          <p className="text-gray-700">
            In no event shall <strong>VisJobs</strong> or its employees be
            liable for:
          </p>
          <ul className="list-disc list-inside text-gray-700 mt-2">
            <li>
              Any direct, indirect, incidental, or consequential damages arising
              from the use of our services.
            </li>
            <li>
              Loss of data or opportunities due to errors, omissions, or delays
              in service delivery.
            </li>
          </ul>
        </div>

        <div id="governing-law">
          <h2 className="text-2xl font-semibold text-gray-900 mb-2">
            7. Governing Law and Jurisdiction
          </h2>
          <p className="text-gray-700">
            These Terms shall be governed by the laws of the State of Wisconsin,
            USA. Any disputes shall be resolved through arbitration in
            accordance with the rules of the American Arbitration Association,
            with proceedings held in Wisconsin, USA.
          </p>
        </div>
      </section>
    </div>
  );
};

export default TermsAndConditions;
