import React, { useEffect } from "react";
import { Card, CardBody, CardHeader } from "@material-tailwind/react";
import InfoIcon from "@mui/icons-material/Info";
import { Tooltip } from "react-tooltip";

const ApprovalCard = ({
  approvals,
  approvalRate,
  totalFilings,
  year,
  denials,
  denialRate,
  dataFromPreviousYear,
}) => {
  useEffect(() => {}, [
    approvalRate,
    approvals,
    totalFilings,
    year,
    denials,
    denialRate,
    dataFromPreviousYear,
  ]);

  const displayApprovalRate =
    approvalRate !== undefined && approvalRate !== null ? approvalRate : "0.00";

  const displayYear = dataFromPreviousYear ? year - 1 : year;

  const dataAvailable =
    approvalRate !== undefined &&
    approvals !== undefined &&
    totalFilings !== undefined &&
    denials !== undefined;

  const approvalRateColor =
    approvalRate > 90 ? "text-[#164ED4]" : "text-orange-500";
  const approvalsColor = "text-green-500";

  return (
    <Card className="relative p-5 border border-[#E9E9EA] space-y-4 rounded-3xl w-full lg:w-1/3 h-96 overflow-hidden flex flex-col text-center">
      <CardHeader className="shadow-none m-0 flex justify-center items-center gap-2">
        <p className="text-lg font-bold capitalize text-black">
          {`${displayYear} H-1B Approval Rate`}
        </p>
        {dataFromPreviousYear && (
          <>
            <InfoIcon data-tooltip-id="infoTooltip" fontSize="small" />
            <Tooltip
              id="infoTooltip"
              place="top"
              effect="solid"
              content={`${year} data not available, displaying ${
                year - 1
              } data instead`}
              className="z-50 !important"
              style={{ zIndex: 9999 }}
            />
          </>
        )}
      </CardHeader>
      <CardBody className="p-0 space-y-4 py-4 overflow-auto flex-grow flex flex-col items-center justify-center">
        {dataAvailable ? (
          <div className="flex flex-col items-center gap-4">
            <h2 className={`text-[40px] font-bold ${approvalRateColor}`}>
              {displayApprovalRate}%
            </h2>
            <hr className="w-full border-t border-gray-300 my-2" />
            <div className="space-y-2">
              <p className="text-sm font-normal text-[#4A4C56]">
                Approved Cases: {approvals}
              </p>
              <p className="text-sm font-normal text-[#4A4C56]">
                Denied Cases: {denials}
              </p>
              <p className="text-sm font-normal text-[#4A4C56]">
                Total: {totalFilings}
              </p>
            </div>
          </div>
        ) : (
          <p className="text-sm font-normal text-[#4A4C56]">
            Data not available
          </p>
        )}
      </CardBody>
    </Card>
  );
};

export default ApprovalCard;
