import { baseUrl, Token } from "../Utils/constants";
import CallApi from "../Utils/callApi";

export const searchCompany = (CompanyName) => {
  return new Promise(async (resolve, reject) => {
    try {
      const url = `${baseUrl}/company/search/?name=${CompanyName}`;
      const apiOptions = {
        method: "GET",
        headers: {
          Authorization: `Token ${Token}`,
        },
      };
      const response = await CallApi(url, apiOptions);
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};
