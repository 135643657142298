import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  margin: "0",
  "&:before": {
    display: "none",
  },
}));

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  minHeight: "70px",
  backgroundColor: "#f7f7f7",
  "& .MuiAccordionSummary-content": {
    margin: "0",
  },
}));

const JobCartFAQ = () => {
  const navigate = useNavigate();

  return (
    <div className="mx-auto max-w-screen-3xl px-6">
      <div className="mx-auto max-w-[1320px] space-y-[40px] md:py-[80px]">
        {/* How It Works Section */}

        <div className="flex flex-col items-center justify-center space-y-4">
          <div className="text-center">
            <Typography
              variant="h3"
              className="text-2xm md:text-4xl lg:text-5xl leading-tight md:leading-tight lg:leading-tight text-[#1D1F2C] mb-4"
            >
              <span className="text-[#164ED4]"> How Job Cart Works </span>
            </Typography>
            <div className="max-w-4xl mt-12">
              <Typography variant="h6" align="center" gutterBottom>
                The Job Cart is designed for users who already know which jobs
                they want to apply to. Simply select specific roles from our
                platform and add them to your cart.
              </Typography>

              {/* Adding an unordered list */}
              <Typography
                variant="h6"
                align="left"
                gutterBottom
                className="text-left mt-8"
                component="ul"
              >
                <ul className="list-disc list-inside text-lg text-gray-800">
                  <li>Choose the jobs you’re interested in.</li>
                  <li>Add them to your cart.</li>
                  <li>
                    Proceed to checkout to let us handle the applications.
                  </li>
                  <li>
                    Track the progress of your applications in the “Track Jobs”
                    tab on your profile.
                  </li>
                </ul>
              </Typography>
            </div>
          </div>
        </div>

        <div className="heading text-center">
          <Typography
            variant="h3"
            className="text-2xl md:text-4xl lg:text-5xl leading-tight md:leading-tight lg:leading-tight text-[#1D1F2C]"
          >
            Job Cart <span className="text-[#164ED4]">FAQs </span>
          </Typography>
        </div>
        <div className="faq-section">
          <StyledAccordion>
            <StyledAccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>What is the Job Cart option?</Typography>
            </StyledAccordionSummary>
            <AccordionDetails>
              <Typography>
                The Job Cart option allows you to select specific jobs from our
                platform and add them to your cart. We’ll apply to those jobs on
                your behalf without requiring a subscription.
              </Typography>
            </AccordionDetails>
          </StyledAccordion>

          <StyledAccordion>
            <StyledAccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography>
                How does the Job Cart differ from AutoApply option?
              </Typography>
            </StyledAccordionSummary>
            <AccordionDetails>
              <Typography>
                Use the Job Cart to select the jobs you'd like us to apply for
                on your behalf. You can choose a one-time payment or opt for a
                subscription, allowing us to continuously apply to the jobs you
                add to your cart.
              </Typography>
            </AccordionDetails>
          </StyledAccordion>

          <StyledAccordion>
            <StyledAccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3a-content"
              id="panel3a-header"
            >
              <Typography>Who is the Job Cart ideal for?</Typography>
            </StyledAccordionSummary>
            <AccordionDetails>
              <Typography>
                The Job Cart is perfect for users who already know which jobs
                they want to apply for and want more control over the
                application process. It’s also great for those who prefer not to
                commit to a subscription.
              </Typography>
            </AccordionDetails>
          </StyledAccordion>

          <StyledAccordion>
            <StyledAccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel4a-content"
              id="panel4a-header"
            >
              <Typography>
                Can I use both the Job Cart and Subscription services?
              </Typography>
            </StyledAccordionSummary>
            <AccordionDetails>
              <Typography>
                Yes, you can use both services depending on your needs. The Job
                Cart allows for a one-time application, while the Subscription
                option provides continuous monthly support.
              </Typography>
            </AccordionDetails>
          </StyledAccordion>

          <StyledAccordion>
            <StyledAccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel5a-content"
              id="panel5a-header"
            >
              <Typography>
                Can I cancel the Job Cart after adding jobs?
              </Typography>
            </StyledAccordionSummary>
            <AccordionDetails>
              <Typography>
                Once you’ve added jobs to your cart and submitted the payment,
                you’ll receive job credits that will be used to apply for the
                positions you've selected. Please note that cancellations are
                not allowed, as the application process begins immediately after
                payment.
              </Typography>
            </AccordionDetails>
          </StyledAccordion>
        </div>
      </div>
    </div>
  );
};

export default JobCartFAQ;
