import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoggedIn: false,
  user: {},
};

export const Auth = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state, response) => {
      state.user = response?.payload;
      state.isLoggedIn = Object.keys(response?.payload ?? {})?.length > 0;
    },
    logout: (state) => {
      state.user = {};
      state.isLoggedIn = false;
    },
  },
});

export const { login, logout } = Auth.actions;

export default Auth.reducer;
