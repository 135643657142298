import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import BlogCard from "./BlogCard/BlogCard";

const BlogList = () => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [subject, setSubject] = useState("Immigration News"); // Default subject
  const [page, setPage] = useState(1); // Track current page
  const [hasMore, setHasMore] = useState(false); // Track if there are more blogs
  const BASE_URL = "https://kazisai.us";
  const pageSize = 10; // Number of blogs per page

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await axios.get(
          `https://kazisai.us/api/blogs?filters[Subject][$eq]=${subject}&pagination[page]=${page}&pagination[pageSize]=${pageSize}&populate=*`
        );
        const sortedBlogs = response.data.data.sort(
          (a, b) => new Date(b.publishedAt) - new Date(a.publishedAt)
        );
        setBlogs(sortedBlogs);

        // Check if there are more blogs
        const totalBlogs = response.data.meta.pagination.total;
        setHasMore(page * pageSize < totalBlogs); // If there are more blogs to load
      } catch (error) {
        console.error("Error fetching blogs", error);
      } finally {
        setLoading(false);
      }
    };

    fetchBlogs();
  }, [subject, page]); // Refetch blogs whenever subject or page changes

  const predefinedSubjects = [
    "News",
    "Market Insights",
    "Job Tips",
    "Company Spotlight",
    "Legal Advice",
    "Career Growth",
    "Work Culture",
    "Job Automation",
    "Success Stories",
    "New Jobs",
    "Visa Process",
    "Remote Jobs",
    "Student",
  ];

  const mainStory = blogs.length ? blogs.find((blog) => blog.IsFeatured) : null;

  const LoadingSkeleton = () => (
    <div className="animate-pulse">
      <div className="bg-gray-200 h-10 w-1/2 mb-4"></div>
      <div className="bg-gray-300 h-64 mb-4"></div>
      <div className="bg-gray-200 h-6 w-3/4 mb-4"></div>
      <div className="bg-gray-200 h-6 w-1/2"></div>
    </div>
  );

  const handleNextPage = () => {
    setPage((prevPage) => prevPage + 1); // Increase page number on next click
  };

  return (
    <div className="container mx-auto p-4">
      {/* Centered Heading */}
      <h1 className="text-2xl font-bold text-center mb-8">{subject}</h1>

      <div className="grid grid-cols-1 lg:grid-cols-12 gap-4 gap-12">
        {/* Left Sidebar - Subjects */}
        <div className="col-span-12 lg:col-span-2 hidden lg:flex flex-col justify-between bg-gray-100 p-4 space-y-12">
          <ul>
            {predefinedSubjects.map((subjectItem, index) => (
              <li
                key={index}
                className={`hover:text-[#164ED4] mb-4 ${
                  subject === subjectItem ? "font-bold text-[#164ED4]" : ""
                }`}
                onClick={() => {
                  setSubject(subjectItem);
                  setPage(1); // Reset to page 1 when subject changes
                }} // Change subject on click
              >
                <span className="block cursor-pointer">{subjectItem}</span>
              </li>
            ))}
          </ul>
        </div>

        {/* Right Content - Blogs */}
        <div className="col-span-12 lg:col-span-10">
          {loading ? (
            <LoadingSkeleton />
          ) : (
            <>
              <div className="grid grid-cols-1 gap-4 mt-8">
                {blogs.map((blog) => (
                  <Link
                    to={`/app/news/${blog.documentId}`}
                    key={blog.id}
                    className="block"
                  >
                    <BlogCard
                      blog={{
                        Title: blog.Title,
                        Author: blog.Author,
                        MetaDescription: blog.MetaDescription,
                        CoverImage: blog.CoverImage,
                        PublishedDate: blog.PublishedDate,
                        Subject: blog.Subject,
                      }}
                    />
                  </Link>
                ))}
              </div>

              {/* Next Button for Pagination */}
              {hasMore && ( // Only show Next button if there are more blogs
                <div className="flex justify-center mt-8">
                  <button
                    onClick={handleNextPage}
                    className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
                  >
                    Next
                  </button>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default BlogList;
