import React from "react";

const GreenKeyIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="190"
      height="193"
      viewBox="0 0 190 193"
      fill="none"
    >
      <g filter="url(#filter0_d_348_3307)">
        <rect
          width="64.5802"
          height="65.5455"
          rx="20"
          transform="matrix(0.965185 0.261827 -0.238477 0.971213 71.6309 52.343)"
          fill="#57C9A0"
        />
      </g>
      <g clipPath="url(#clip0_348_3307)">
        <path
          d="M106.83 106.909L100.415 97.9037C102.404 96.2299 103.811 93.941 104.43 91.371C105.118 88.5979 104.873 85.6551 103.734 83.0159C102.595 80.3767 100.629 78.1946 98.1505 76.8205C95.6724 75.4464 92.827 74.9602 90.0719 75.4401C87.3169 75.92 84.8122 77.3381 82.961 79.4663C81.1097 81.5945 80.0194 84.3092 79.8654 87.1736C79.7115 90.0379 80.5028 92.8856 82.1121 95.2584C83.7214 97.6312 86.0551 99.3914 88.7379 100.256C91.4208 101.12 94.2967 101.038 96.9032 100.023L101.51 110.032C101.679 110.457 101.935 110.841 102.261 111.158C102.586 111.476 102.973 111.718 103.396 111.87C103.818 112.023 104.266 112.08 104.71 112.04C105.154 111.999 105.582 111.861 105.967 111.636C106.352 111.41 106.683 111.101 106.94 110.731C107.196 110.361 107.371 109.937 107.453 109.489C107.535 109.041 107.522 108.579 107.414 108.134C107.307 107.689 107.108 107.271 106.83 106.909ZM95.4759 85.1228C95.1677 85.0392 94.8894 84.8641 94.6761 84.6197C94.4627 84.3753 94.324 84.0726 94.2775 83.7498C94.231 83.427 94.2787 83.0986 94.4146 82.8062C94.5505 82.5138 94.7686 82.2705 95.0412 82.1071C95.3137 81.9436 95.6286 81.8674 95.946 81.888C96.2633 81.9086 96.5689 82.0251 96.8241 82.2228C97.0792 82.4205 97.2725 82.6905 97.3795 82.9986C97.4865 83.3068 97.5023 83.6393 97.425 83.954C97.3214 84.3761 97.0578 84.7364 96.6923 84.9556C96.3268 85.1747 95.8892 85.2349 95.4759 85.1228ZM103.712 109.051L100.63 102.375L104.92 108.394C104.994 108.483 105.047 108.589 105.074 108.703C105.101 108.816 105.101 108.934 105.075 109.047C105.037 109.201 104.95 109.336 104.829 109.433C104.707 109.53 104.557 109.582 104.402 109.583C104.247 109.583 104.096 109.531 103.971 109.435C103.846 109.339 103.755 109.204 103.712 109.051Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_348_3307"
          x="0.184082"
          y="0.881104"
          width="189.594"
          height="191.491"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="30" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.135538 0 0 0 0 0.879167 0 0 0 0 0.43299 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_348_3307"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_348_3307"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_348_3307">
          <rect
            width="38.7481"
            height="39.3273"
            fill="white"
            transform="matrix(0.965185 0.261827 -0.238477 0.971213 80.9707 68.4565)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default GreenKeyIcon;
