import { Button, Card, Typography } from "@material-tailwind/react";
import moment from "moment/moment";

const TABLE_HEAD = [
  "Particular",
  "Status",
  "Points Earned",
  "Points Used",
  "Expiry Date",
  "",
];

const CustomTable = ({ data = [], cancelSubscription }) => {
  return (
    <>
      <p>
        Here you can view a detailed record of all your past and current
        transactions
      </p>
      <Card className="">
        <table className=" table-auto min-w-max text-left">
          <thead>
            <tr>
              {TABLE_HEAD.map((head) => (
                <th
                  key={head}
                  className="border-b border-blue-gray-100 bg-[#E8EDFB] p-4"
                >
                  <Typography
                    variant="small"
                    color="#777980"
                    className="font-normal text-sm sm:text-base text-[#777980] leading-none opacity-70"
                  >
                    {head}
                  </Typography>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data?.length > 0 &&
              data?.map((transaction, index) => (
                <tr key={index} className="even:bg-[#F6F8FA]">
                  <td className="p-4 min-w-[8vw]">
                    <Typography
                      variant="small"
                      className="font-normal text-[#1D1F2C] text-base capitalize"
                    >
                      {transaction?.plan_type}
                    </Typography>
                  </td>

                  <td class="p-4 min-w-[8vw]">
                    <div class="w-max">
                      {transaction?.is_active ? (
                        <div class="relative grid items-center px-2 py-1 font-sans text-xs font-bold text-green-900 uppercase rounded-md select-none whitespace-nowrap bg-green-500/20">
                          <span class="">Active</span>
                        </div>
                      ) : moment(transaction?.points_expiry_date).isBefore(
                          moment()
                        ) ? (
                        <div class="relative grid items-center px-2 py-1 font-sans text-xs font-bold text-red-900 uppercase rounded-md select-none whitespace-nowrap bg-red-500/20">
                          <span class="">Expired</span>
                        </div>
                      ) : (
                        <div class="relative grid items-center px-2 py-1 font-sans text-xs font-bold text-red-900 uppercase rounded-md select-none whitespace-nowrap bg-red-500/20">
                          <span class="">Cancelled</span>
                        </div>
                      )}
                    </div>
                  </td>
                  <td className="p-4 min-w-[8vw]">
                    <Typography
                      variant="small"
                      // color="blue-gray"
                      className="font-normal text-[#1D1F2C] text-sm sm:text-base"
                    >
                      {transaction?.points_earned}
                    </Typography>
                  </td>
                  <td className="p-4 min-w-[8vw]">
                    <Typography
                      variant="small"
                      // color="blue-gray"
                      className="font-normal text-[#1D1F2C] text-sm sm:text-base"
                    >
                      {transaction?.points_used}
                    </Typography>
                  </td>
                  <td className="p-4 min-w-[8vw]">
                    <Typography
                      variant="small"
                      // color="blue-gray"
                      className="font-normal text-[#1D1F2C] text-sm sm:text-base"
                    >
                      <span className="hidden lg:block">
                        {moment(transaction?.points_expiry_date).format(
                          "DD-MM-YYYY"
                        )}
                      </span>
                      <span className="block lg:hidden">
                        {moment(transaction?.points_expiry_date).format(
                          "MMM-YY"
                        )}
                      </span>
                    </Typography>
                  </td>
                  {transaction?.plan_type == "subscribe" &&
                  transaction?.is_active ? (
                    <td className="px-4">
                      <Button
                        className="bg-[#EB3D4D] flex gap-1 text-xs min-w-fit font-normal capitalize"
                        onClick={() => cancelSubscription(transaction)}
                      >
                        <span>Cancel</span>
                        <span className="hidden xl:block">Membership</span>
                      </Button>
                    </td>
                  ) : (
                    <td className="p-4 "></td>
                  )}
                </tr>
              ))}
          </tbody>
        </table>
      </Card>
    </>
  );
};
export default CustomTable;
