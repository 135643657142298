import React from "react";
import { Button, Card, CardBody, Typography } from "@material-tailwind/react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useNavigate } from "react-router-dom";
import Heading from "../../../CustomComponents/Heading";
const FlexibleOptions = () => {
  const navigate = useNavigate();
  const parameters = {
    title: "",
    location: "Seattle, Washington",
    duration: "Full-Time",
    department: "Engineering",
    experience: "",
    job_sponsorship: false,
    salary: 30000,
    industry: "Computer Software",
  };

  return (
    <div className="py-10 px-4">
      <div className="text-center lg:text-center lg:pr-8 mb-10">
        <Typography
          variant="h3"
          style={{ color: "#164ED4" }}
          className="!text-2xl md:!text-4xl lg:!text-6xl"
          // fontWeight="bold"
          gutterBottom
        ></Typography>
        <Heading
          text=""
          highlightedText="Choose a service that works best for you"
          highlightClass="text-[#164ED4]"
          containerClass="my-4"
        />
      </div>

      <div className="max-w-6xl mx-auto space-y-10">
        {/* Flexible Options Section */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <Card className="flex flex-col justify-between">
            <CardBody>
              <div className="flex justify-center items-center">
                <Typography
                  variant="h5"
                  style={{ color: "#164ED4" }}
                  className="mb-4"
                >
                  You Select, We Apply
                </Typography>
              </div>
              <Typography variant="body2" className="mb-4">
                Choose the jobs you’re interested in, add them to your cart, and
                checkout. We’ll handle the application process for you, ensuring
                your resume and cover letter are professionally submitted.
                Perfect for users who want control over job selection while
                leaving the tedious application process to us.
              </Typography>
            </CardBody>
            <div className="flex justify-center items-center mb-4">
              <Button
                className="bg-transparent shadow-none border border-primary rounded-none text-primary text-sm font-normal"
                onClick={() => {
                  navigate("/app/job-list", {
                    state: {
                      jobParameters: parameters,
                      source: "selectJobsNow",
                    },
                  });
                }}
              >
                Select Jobs Now
              </Button>
            </div>
          </Card>

          <Card className="flex flex-col justify-between">
            <CardBody>
              <div className="flex justify-center items-center">
                <Typography
                  variant="h5"
                  style={{ color: "#164ED4" }}
                  className="mb-4"
                >
                  Complete Job Search Service
                </Typography>
              </div>
              <Typography variant="body2" className="mb-4">
                Let us handle everything! We’ll research and identify the best
                job opportunities based on your preferences, tailor your resume
                and cover letter for each role, and professionally submit
                applications on your behalf. This full-service option is ideal
                for those who want a completely hands-free, hassle-free job
                search experience.
              </Typography>
            </CardBody>
            <div className="flex justify-center items-center mb-4">
              <Button
                className="bg-transparent shadow-none border border-primary rounded-none text-primary text-sm font-normal"
                onClick={() => {
                  navigate("/app/complete-job-search-service");
                }}
              >
                Learn more
              </Button>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default FlexibleOptions;
