import React, { useEffect, useState, useRef } from "react";
import * as echarts from "echarts";
import { Skeleton } from "@mui/material";

const JobDistributionChart = ({ data }) => {
  const [loading, setLoading] = useState(false);
  const chartRef = useRef(null);
  let myChart = useRef(null);

  const renderChart = () => {
    if (!data || data.length === 0) return;

    const uniqueJobsMap = new Map();
    data.forEach((item) => {
      if (
        !uniqueJobsMap.has(item.job_title) ||
        uniqueJobsMap.get(item.job_title).total_sponsored < item.total_sponsored
      ) {
        uniqueJobsMap.set(item.job_title, item);
      }
    });

    const uniqueJobs = Array.from(uniqueJobsMap.values());
    const sortedData = uniqueJobs
      .sort((a, b) => b.total_sponsored - a.total_sponsored)
      .slice(0, 10);

    const jobTitles = sortedData.map((item) => item.job_title);
    const totalSponsored = sortedData.map((item) => item.total_sponsored);

    const chartDom = chartRef.current;
    if (chartDom) {
      myChart.current = echarts.init(chartDom);

      const option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        xAxis: {
          type: "category",
          data: jobTitles,
          axisLabel: {
            rotate: 45,
            interval: 0,
          },
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: totalSponsored,
            type: "bar",
          },
        ],
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        responsive: true,
        maintainAspectRatio: false,
      };

      myChart.current.setOption(option);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (data.length > 0 && chartRef.current) {
      renderChart();

      const handleResize = () => {
        if (myChart.current) {
          myChart.current.resize(); // Resize the chart on window resize
        }
      };

      window.addEventListener("resize", handleResize);

      // Clean up the resize event listener
      return () => {
        window.removeEventListener("resize", handleResize);
        if (myChart.current) {
          myChart.current.dispose();
        }
      };
    }
  }, [data]);

  return (
    <div style={{ width: "100%", maxWidth: "100%", height: "100%" }}>
      {loading ? (
        <Skeleton variant="rectangular" width="100%" height={400} />
      ) : (
        <div
          ref={chartRef}
          style={{
            width: "100%",
            height: "400px", // Can be dynamic based on device, here it's fixed for now
            minHeight: "300px", // Minimum height for small screens
          }}
        ></div>
      )}
    </div>
  );
};

export default JobDistributionChart;
