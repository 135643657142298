import React from "react";
import CountryChart from "./IndividualCharts/CountryChart";
import PetitionsFiledChart from "./IndividualCharts/PetitionsFiledChart";
import ApprovedPetitionsChart from "./IndividualCharts/ApprovedPetitionsChart";
import AgeChart from "./IndividualCharts/AgeChart";
import EducationChart from "./IndividualCharts/EducationChart";
import CompensationChart from "./IndividualCharts/CompensationChart";
// import AgeChart from "./AgeChart";
// import EducationChart from "./EducationChart";
// import CompensationChart from "./CompensationChart";
// import PetitionsFiledChart from "./PetitionsFiledChart";
// import ApprovedPetitionsChart from "./ApprovedPetitionsChart";
// import OccupationChart from "./OccupationChart";
// import IndustrySectorChart from "./IndustrySectorChart";
// import LocationStatusChart from "./LocationStatusChart";
// import ValidityPeriodChart from "./ValidityPeriodChart";
// import PremiumProcessingChart from "./PremiumProcessingChart";
// import RequestsForEvidenceChart from "./RequestsForEvidenceChart";

const ChartContainer = ({
  startYear,
  endYear,
  dataMetric,
  country,
  ageRange,
  metricContexts,
  education,
  occupation,
}) => {
  switch (dataMetric) {
    case "PetitionsApprovedByCountryOfBirthAndGender":
      return (
        <CountryChart
          startYear={startYear}
          endYear={endYear}
          country={country}
          metricContexts={metricContexts}
        />
      );
    case "PetitionsApprovedByAgeAndGender":
      return (
        <AgeChart
          startYear={startYear}
          endYear={endYear}
          ageRange={ageRange}
          metricContexts={metricContexts}
        />
      );
    case "PetitionsApprovedByEducationAndGender":
      return (
        <EducationChart
          startYear={startYear}
          endYear={endYear}
          education={education}
          metricContexts={metricContexts}
        />
      );
    case "CompensationByOccupationsApproved":
      return (
        <CompensationChart
          startYear={startYear}
          endYear={endYear}
          occupation={occupation}
          metricContexts={metricContexts}
        />
      );
    case "PetitionsFiled":
      return (
        <PetitionsFiledChart
          startYear={startYear}
          endYear={endYear}
          metricContexts={metricContexts}
        />
      );
    case "ApprovedPetitions":
      return (
        <ApprovedPetitionsChart
          startYear={startYear}
          endYear={endYear}
          metricContexts={metricContexts}
        />
      );
    // case "PetitionsApprovedByOccupation":
    //   return (
    //     <OccupationChart
    //       startYear={startYear}
    //       endYear={endYear}
    //       graphType={graphType}
    //       metricContexts={metricContexts}
    //     />
    //   );
    // case "IndustrySector":
    //   return (
    //     <IndustrySectorChart
    //       startYear={startYear}
    //       endYear={endYear}
    //       graphType={graphType}
    //       metricContexts={metricContexts}
    //     />
    //   );
    // case "LocationAndPriorStatus":
    //   return (
    //     <LocationStatusChart
    //       startYear={startYear}
    //       endYear={endYear}
    //       graphType={graphType}
    //       metricContexts={metricContexts}
    //     />
    //   );
    // case "ValidityPeriod":
    //   return (
    //     <ValidityPeriodChart
    //       startYear={startYear}
    //       endYear={endYear}
    //       graphType={graphType}
    //       metricContexts={metricContexts}
    //     />
    //   );
    // case "PremiumProcessing":
    //   return (
    //     <PremiumProcessingChart
    //       startYear={startYear}
    //       endYear={endYear}
    //       graphType={graphType}
    //       metricContexts={metricContexts}
    //     />
    //   );
    // case "RequestsForEvidence":
    //   return (
    //     <RequestsForEvidenceChart
    //       startYear={startYear}
    //       endYear={endYear}
    //       graphType={graphType}
    //       metricContexts={metricContexts}
    //     />
    //   );
    default:
      return <div>No chart available for the selected metric.</div>;
  }
};

export default ChartContainer;
