import React from "react";

const CircleBackground = () => {
  return (
    <div className="absolute top-[50px] left-0 z-[-1] hidden md:block">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="72"
        height="72"
        viewBox="0 0 72 72"
        fill="none"
      >
        <circle cx="36" cy="36" r="36" fill="#75E3EA" />
      </svg>
    </div>
  );
};

export default CircleBackground;
