import moment from "moment";
import Supabase from "../Utils/supabase";

export const createTransaction = async (payload) => {
  const response = await Supabase.from("transaction")
    .insert([{ ...payload }])
    .select("id");
  return response;
};

export const getTransactionsByUserId = async (userId) => {
  const { data, error } = await Supabase.from("transaction")
    .select(
      "id, plan_type, points_earned, points_used, is_active,points_expiry_date, payment_date, subscription_id"
    )
    .eq("user_id", userId);
  return data;
};

export const getCreditsByUserId = async (userId) => {
  const { data, error } = await Supabase.from("transaction")
    .select("points_earned, points_used, is_active, points_expiry_date")
    .eq("user_id", userId)
    .eq("is_active", true) // Ensuring active transactions
    .gt("points_expiry_date", moment().toISOString());
  return data;
};

export const updateTransactionStatus = async (id, toggle) => {
  const { data, error } = await Supabase.from("transaction")
    .update({ is_active: toggle }) // Update values
    .eq("id", id);
  return data;
};

export const getTrackJobsByUserId = async (userId) => {
  const { data, error } = await Supabase.from("track_jobs")
    .select("*")
    .eq("user_id", userId);
  return data;
};

export const getAutoAppliedTransactions = async (userId) => {
  const { data, error } = await Supabase.from("transaction")
    .select("id, points_earned, points_used, points_expiry_date")
    .eq("user_id", userId)
    .eq("auto_applied", true) // Ensuring active transactions
    .gt("points_expiry_date", moment().toISOString());
  return data;
};
