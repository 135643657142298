import React, { useMemo, useRef, useEffect } from "react";
import ReactECharts from "echarts-for-react";
import { getAgeData } from "../dataReader";

const AgeChart = ({ startYear, endYear, ageRange, metricContexts }) => {
  const chartRef = useRef();
  const processedData = useMemo(
    () => getAgeData(parseInt(startYear, 10), parseInt(endYear, 10), ageRange),
    [startYear, endYear, ageRange]
  );

  const chartOptions = useMemo(() => {
    const years = Object.keys(processedData);

    // Check if processedData has content to avoid errors
    if (!years.length) {
      return null; // Return null if no data is available
    }

    if (!ageRange) {
      // Create a stacked chart for all age ranges
      const ageGroups = Object.keys(processedData[years[0]] || {});
      const series = ageGroups.map((ageGroup) => ({
        name: ageGroup,
        type: "bar",
        stack: "total",
        data: years.map((year) => processedData[year]?.[ageGroup]?.Total || 0),
        barWidth: "60%",
        itemStyle: {
          borderRadius: [10, 10, 0, 0],
          shadowColor: "rgba(0, 0, 0, 0.2)",
          shadowBlur: 10,
        },
      }));

      return {
        backgroundColor: "#f4f6f8",
        title: {
          text: `${metricContexts.PetitionsApprovedByAgeAndGender} from ${startYear} to ${endYear}`,
          left: "center",
          textStyle: {
            color: "#333",
            fontWeight: "bold",
            fontSize: 16,
          },
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        legend: {
          data: ageGroups,
          top: "5%",
        },
        grid: {
          left: "5%",
          right: "5%",
          bottom: "10%",
          top: "20%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: years,
          axisLabel: {
            rotate: 45,
            color: "#666",
          },
          axisLine: {
            lineStyle: {
              color: "#aaa",
            },
          },
        },
        yAxis: {
          type: "value",
          axisLabel: {
            color: "#666",
          },
          splitLine: {
            lineStyle: {
              color: "#eee",
            },
          },
          axisLine: {
            lineStyle: {
              color: "#aaa",
            },
          },
        },
        series: series,
      };
    } else {
      // Create a split line chart for a specific age range showing gender distribution
      const series = [
        {
          name: "Male",
          type: "line",
          data: years.map((year) => processedData[year]?.[ageRange]?.Male || 0),
          smooth: true,
        },
        {
          name: "Female",
          type: "line",
          data: years.map(
            (year) => processedData[year]?.[ageRange]?.Female || 0
          ),
          smooth: true,
        },
      ];

      return {
        backgroundColor: "#f4f6f8",
        title: {
          text: `${metricContexts.PetitionsApprovedByAgeAndGender} (${ageRange}) from ${startYear} to ${endYear}`,
          left: "center",
          textStyle: {
            color: "#333",
            fontWeight: "bold",
            fontSize: 16,
          },
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "line",
          },
        },
        legend: {
          data: ["Male", "Female"],
          top: "5%",
        },
        grid: {
          left: "5%",
          right: "5%",
          bottom: "10%",
          top: "20%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: years,
          axisLabel: {
            rotate: 45,
            color: "#666",
          },
          axisLine: {
            lineStyle: {
              color: "#aaa",
            },
          },
        },
        yAxis: {
          type: "value",
          axisLabel: {
            color: "#666",
          },
          splitLine: {
            lineStyle: {
              color: "#eee",
            },
          },
          axisLine: {
            lineStyle: {
              color: "#aaa",
            },
          },
        },
        series: series,
      };
    }
  }, [processedData, startYear, endYear, metricContexts, ageRange]);

  return (
    <div className="flex flex-col items-center">
      <div className="w-full lg:w-full h-[400px]">
        {chartOptions ? (
          <ReactECharts
            ref={chartRef}
            option={chartOptions}
            style={{ height: "100%", width: "100%" }}
            className="shadow-lg rounded-md border mb-4"
          />
        ) : (
          <div className="text-center mt-4 text-gray-600">
            No data available for the selected range.
          </div>
        )}
      </div>
    </div>
  );
};

export default AgeChart;
