import React from "react";

const YellowIcon = () => {
  return (
    <div className="absolute right-0 top-[100%] z-[-1] hidden md:block">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
      >
        <circle cx="20" cy="20" r="20" fill="#FFD540" />
      </svg>
    </div>
  );
};

export default YellowIcon;
