import React from "react";
import Skeleton from "@mui/material/Skeleton";
import { Card, CardBody, CardFooter } from "@material-tailwind/react";

const JobCardSkeleton = () => {
  return (
    <Card className="mt-6 border border-[#E8EDFB] w-full h-fit">
      <CardBody className="flex flex-col gap-3">
        <Skeleton variant="text" width="60%" height={30} />
        <Skeleton variant="text" width="40%" height={20} />
        <Skeleton variant="rectangular" width="100%" height={20} />
        <Skeleton variant="text" width="80%" height={20} />
        <Skeleton variant="text" width="60%" height={20} />
        <Skeleton variant="rectangular" width="100%" height={80} />
      </CardBody>
      <CardFooter className="flex gap-4 pt-0">
        <Skeleton variant="rectangular" width="38%" height={36} />
        <Skeleton variant="rectangular" width="38%" height={36} />
      </CardFooter>
    </Card>
  );
};

export default JobCardSkeleton;
