import React from "react";
import ln from "../../assets/icons/in.png";
import gd from "../../assets/icons/gd.png";
import indeed from "../../assets/icons/indeed.png";
import visjobs from "../../assets/icons/visjobs.png";
import { Card, Typography } from "@material-tailwind/react";
import moment from "moment";

const TABLE_HEAD = [
  "Source",
  "Applied On",
  "Job Title",
  "Company Name",
  "Link To Applied Job",
];

function JobApplicationsTable({ data }) {
  const getLogo = (link) => {
    if (link.includes("linkedin.com")) return ln;
    if (link.includes("glassdoor.com")) return gd;
    if (link.includes("indeed.com")) return indeed;
    else return visjobs;
  };

  return (
    <div className="flex flex-col gap-8 md:gap-16">
      {data?.length > 0 &&
        data?.map((transaction, index) => (
          <Card className="rounded-none" key={index}>
            <div className="flex items-center justify-between mb-4 flex-wrap">
              <div className="flex items-center gap-2 text-xl">
                <div className="font-medium">Transaction No: </div>
                <div className="font-bold">{transaction?.id}</div>
              </div>
              <div className="flex items-center gap-2 text-base">
                <div>Total Jobs: </div>
                <div className="font-medium">{transaction?.points_earned}</div>
              </div>
              <div className="flex items-center gap-2 text-base">
                <div>Applied Jobs: </div>
                <div className="font-medium">{transaction?.points_used}</div>
              </div>
              <div className="flex items-center gap-2 text-base">
                <div>Expiry Date: </div>
                <div className="font-medium">
                  {moment(transaction?.points_expiry_date).format("DD/MM/YYYY")}
                </div>
              </div>
            </div>

            <table className=" table-auto min-w-max text-left">
              <thead>
                <tr>
                  {TABLE_HEAD.map((head) => (
                    <th
                      key={head}
                      className="border-b border-blue-gray-100 bg-[#E8EDFB] p-4"
                    >
                      <Typography
                        variant="small"
                        color="#777980"
                        className="font-normal text-sm sm:text-base text-[#777980] leading-none opacity-70"
                      >
                        {head}
                      </Typography>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {transaction?.items?.length > 0 &&
                  transaction?.items?.map((app, index) => (
                    <tr key={index} className="even:bg-[#F6F8FA]">
                      <td className="p-4 min-w-[8vw]">
                        <img
                          src={getLogo(app.link)}
                          alt="Logo"
                          className="w-10 h-10"
                        />
                      </td>

                      <td class="p-4 min-w-[8vw]">
                        <Typography
                          variant="small"
                          className="font-normal text-[#1D1F2C] text-sm sm:text-base"
                        >
                          {app.applied_on}
                        </Typography>
                      </td>
                      <td className="p-4 min-w-[8vw]">
                        <Typography
                          variant="small"
                          // color="blue-gray"
                          className="font-normal text-[#1D1F2C] text-sm sm:text-base"
                        >
                          {app?.job_title}
                        </Typography>
                      </td>
                      <td className="p-4 min-w-[8vw]">
                        <Typography
                          variant="small"
                          // color="blue-gray"
                          className="font-normal text-[#1D1F2C] text-sm sm:text-base"
                        >
                          {app?.company_name}
                        </Typography>
                      </td>
                      <td className="p-4 min-w-[8vw]">
                        <a
                          href={app.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-blue-600 hover:underline"
                        >
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M5 15L19 1"
                              stroke="rgb(22, 78, 212)"
                              strokeWidth="2"
                            />
                            <path
                              d="M19 1L19 11"
                              stroke="rgb(22, 78, 212)"
                              strokeWidth="2"
                            />
                            <path
                              d="M19 1L9 1"
                              stroke="rgb(22, 78, 212)"
                              strokeWidth="2"
                            />
                          </svg>
                        </a>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </Card>
        ))}
    </div>
  );
}

export default JobApplicationsTable;
