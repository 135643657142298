import React from "react";
import NewsCard from "./Cards/newsCard";
import ApprovalCard from "./Cards/approvalRateCard";
import DescriptionCard from "./Cards/denialRateCard";

const CompanySummary = ({ companySummaryData, companyInfo, newsData }) => {
  const { latestYearData, yearlyChangeData, year, name } = companySummaryData;
  const hasPreviousYearData = yearlyChangeData.hasPreviousYearData;
  let cname = companySummaryData.name ? companySummaryData.name : "google llc";
  const getFirstPart = (companyName) => companyName.toLowerCase().split(" ")[0];

  let filteredNewsData = cname
    ? newsData.filter(
        (newsData) => newsData.Company.toLowerCase() === getFirstPart(cname)
      )
    : newsData;
  const defaultNewsData =
    filteredNewsData.length > 0 ? filteredNewsData : newsData;
  return (
    <div className="space-y-10">
      <div className="flex flex-col lg:flex-row items-start w-full gap-4">
        {latestYearData && Object.keys(latestYearData).length > 0 && (
          <>
            <DescriptionCard companyInfo={companyInfo} />
            <ApprovalCard
              approvalRate={latestYearData.approvalRate?.toFixed(2)}
              approvals={latestYearData.approvals}
              denialRate={latestYearData.denialRate.toFixed(2)}
              denials={latestYearData.denials}
              totalFilings={latestYearData.totalFilings}
              year={year}
              dataFromPreviousYear={!hasPreviousYearData}
            />
          </>
        )}
        {/* Pass the filtered news data to NewsCard */}
        {filteredNewsData && filteredNewsData.length > 0 ? (
          <NewsCard news={filteredNewsData} />
        ) : (
          <NewsCard news={defaultNewsData} />
        )}
      </div>
    </div>
  );
};

export default CompanySummary;
