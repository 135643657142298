import React, { useState } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { styled } from "@mui/system";
import { useNavigate } from "react-router-dom";
import Supabase from "../../Utils/supabase";
import {
  createPaymentIntent,
  createSubscriptionPaymentIntent,
  findOrCreateCustomerForPayment,
} from "../../services/checkoutService";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const CustomCard = styled(Card)(({ theme }) => ({
  maxWidth: 500,
  margin: "0 auto",
  padding: theme.spacing(2),
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  borderRadius: "10px",
}));

const Title = styled(Typography)(({ theme }) => ({
  textAlign: "center",
  marginBottom: theme.spacing(2),
}));

const PaymentElementContainer = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const ExploreOptions = styled(Typography)({
  textAlign: "center",
  marginTop: "16px",
  color: "#1976d2", // Replace with your desired color
  cursor: "pointer",
  textDecoration: "underline",
});

const PaymentForm = ({
  amount,
  jobs = 0,
  onPaymentSuccess,
  isHome = false,
  type = "",
  plan_category = "",
  paymentDuration = "",
}) => {
  const authState = useSelector((state) => state.auth);
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate(); // Initialize useNavigate

  const customStyles = {
    base: {
      color: "#32325d",
      fontFamily: "Arial, sans-serif",
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError(null);

    // Uncomment this section to re-enable the actual payment process

    try {
      if (elements == null) {
        return;
      }

      // Trigger form validation and wallet collection
      const { error: submitError } = await elements.submit();
      if (submitError) {
        return;
      }

      const customerId = await findOrCreateCustomerForPayment(
        authState?.user?.email,
        authState?.user?.user_metadata?.name
      );

      let response;

      if (type == "subscribe") {
        const priceIdData = {
          60: "price_1QMG0jIwZxugLHe2FUnuy8JF",
          90: "price_1QMG4EIwZxugLHe2zzMfSaOI",
          100: "price_1QMG1iIwZxugLHe2tNLLIpRx",
          140: "price_1QMG3qIwZxugLHe2WVUzQOKW",
          240: "price_1QMG3OIwZxugLHe2ItYfXd14",
          360: "price_1QMG2oIwZxugLHe2CKV9VmQ6",
        };
        response = await createSubscriptionPaymentIntent(
          priceIdData[amount],
          customerId
        );
      } else response = await createPaymentIntent(amount, customerId);

      const card = elements.getElement(CardElement);

      const data = await stripe.confirmCardPayment(response?.clientSecret, {
        payment_method: {
          card: card,
        },
      });
      debugger;
      if (data.paymentIntent.id) {
        const res = await onPaymentSuccess(
          data?.paymentIntent,
          response?.subscriptionId || null
        );
        if (isHome) {
          navigate("/app/job-application-form", {
            state: {
              paymentIntent: data?.paymentIntent,
              subscription_id: response?.subscriptionId || null,
              jobs,
              paymentDuration,
              plan_category,
            },
          });
          toast.success("Payment Successful!");
        }
        setLoading(false);
      }
    } catch (e) {
      console.error("Error during payment", e);
      toast.error("All details should be correct!");
      setLoading(false);
    }
  };

  const handleExploreOptions = () => {
    navigate("/choose-options"); // Navigate to the choose options page
  };

  return (
    <CustomCard>
      <CardContent>
        <Title variant="h5">Payment Information</Title>
        <PaymentElementContainer>
          <CardElement options={{ style: customStyles }} />
        </PaymentElementContainer>
        {error && <Typography color="error">{error}</Typography>}
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={!stripe || loading}
          style={{
            marginTop: "16px",
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          {loading ? "Processing..." : `Pay $${amount}`}
        </Button>
        {/* <ExploreOptions onClick={handleExploreOptions}>
          Or Explore other Options
        </ExploreOptions> */}
      </CardContent>
    </CustomCard>
  );
};

export default PaymentForm;
