import React from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";
import parse from "html-react-parser";
import { ToastContainer } from "react-toastify";
import "./tc.css";

const TermsAndConditions = ({ open, handleClose }) => {
  const termsContent = `
    <h2><strong>Terms and Conditions</strong></h2>
    <p>Last updated: <strong>June 9, 2023</strong></p>
    <p>Welcome to <strong>VisJobs</strong>! These terms and conditions outline the rules and regulations for the use of <strong>VisJobs</strong>' Website, located at https://www.visjobs.com/app/home.</p>
    <p><strong>1. Acceptance of Terms</strong></p>
    <p>By accessing this website, we assume you accept these terms and conditions. Do not continue to use <strong>VisJobs</strong> if you do not agree to all of the terms and conditions stated on this page.</p>
    <p><strong>2. Intellectual Property Rights</strong></p>
    <p>Unless otherwise stated, <strong>VisJobs</strong> and/or its licensors own the intellectual property rights for all material on <strong>VisJobs</strong>. All intellectual property rights are reserved. You may access this from <strong>VisJobs</strong> for your own personal use subjected to restrictions set in these terms and conditions.</p>
    <p><strong>3. User Responsibilities</strong></p>
    <p>You must be at least 18 years of age to use this website. You agree not to use the site for any illegal purposes or in violation of any applicable laws or regulations. You are responsible for maintaining the confidentiality of your account and password.</p>
    <p><strong>4. Use of Content</strong></p>
    <p>You may not republish material from <strong>VisJobs</strong>. You may not sell, rent, or sub-license material from <strong>VisJobs</strong>. You may not reproduce, duplicate, or copy material from <strong>VisJobs</strong>. You may not redistribute content from <strong>VisJobs</strong>, unless content is specifically made for redistribution.</p>
    <p><strong>5. User-Generated Content</strong></p>
    <p>Certain parts of this website may offer the opportunity for users to post and exchange opinions and information in certain areas of the website. <strong>VisJobs</strong> does not filter, edit, publish, or review comments prior to their presence on the website. Comments do not reflect the views and opinions of <strong>VisJobs</strong>, its agents, and/or affiliates. Comments reflect the views and opinions of the person who posts their views and opinions. <strong>VisJobs</strong> reserves the right to monitor all Comments and to remove any Comments which can be considered inappropriate, offensive, or causes a breach of these Terms and Conditions.</p>
    <p><strong>6. Use of Scraped Content</strong></p>
    <p><strong>VisJobs</strong> aggregates job postings from various publicly accessible websites through automated means. By using <strong>VisJobs</strong>, you acknowledge and agree that the content sourced from these websites is for informational purposes only. <strong>VisJobs</strong> does not claim ownership of the content sourced from third-party websites and respects the intellectual property rights of others. If you believe that any content on <strong>VisJobs</strong> infringes your intellectual property rights, please contact us at building<strong>VisJobs</strong>@gmail.com. <strong>VisJobs</strong> makes no representations or warranties regarding the accuracy, completeness, or timeliness of the job postings sourced from third-party websites.</p>
    <p><strong>7. No Warranties</strong></p>
    <p>This website is provided "as is," with all faults, and <strong>VisJobs</strong> expresses no representations or warranties, of any kind related to this website or the materials contained on this website. Also, nothing contained on this website shall be interpreted as advising you.</p>
    <p><strong>8. Limitation of Liability</strong></p>
    <p>In no event shall <strong>VisJobs</strong>, nor any of its officers, directors, and employees, be held liable for anything arising out of or in any way connected with your use of this website whether such liability is under contract. <strong>VisJobs</strong>, including its officers, directors, and employees, shall not be held liable for any indirect, consequential, or special liability arising out of or in any way related to your use of this website.</p>
    <p><strong>9. Indemnification</strong></p>
    <p>You hereby indemnify to the fullest extent <strong>VisJobs</strong> from and against any and/or all liabilities, costs, demands, causes of action, damages, and expenses arising in any way related to your breach of any of the provisions of these Terms.</p>
    <p><strong>10. Severability</strong></p>
    <p>If any provision of these Terms is found to be invalid under any applicable law, such provisions shall be deleted without affecting the remaining provisions herein.</p>
    <p><strong>11. Variation of Terms</strong></p>
    <p><strong>VisJobs</strong> is permitted to revise these Terms at any time as it sees fit, and by using this website you are expected to review these Terms on a regular basis.</p>
    <p><strong>12. Assignment</strong></p>
    <p><strong>VisJobs</strong> is allowed to assign, transfer, and subcontract its rights and/or obligations under these Terms without any notification. However, you are not allowed to assign, transfer, or subcontract any of your rights and/or obligations under these Terms.</p>
    <p><strong>13. Entire Agreement</strong></p>
    <p>These Terms constitute the entire agreement between <strong>VisJobs</strong> and you in relation to your use of this website and supersede all prior agreements and understandings.</p>
    <p><strong>14. Governing Law & Jurisdiction</strong></p>
    <p>These Terms will be governed by and interpreted in accordance with the laws of TX, USA, and you submit to the non-exclusive jurisdiction of the state and federal courts located in TX, USA for the resolution of any disputes.</p>
    <p>If you have any questions about these Terms and Conditions, please contact us at building<strong>VisJobs</strong>@gmail.com.</p>
  `;

  return (
    <Dialog open={open} handler={handleClose} size="lg">
      <ToastContainer />
      <DialogHeader className="justify-end">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          className="h-5 w-5"
          onClick={handleClose}
        >
          <path
            fillRule="evenodd"
            d="M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z"
            clipRule="evenodd"
          />
        </svg>
      </DialogHeader>
      <DialogBody>
        <div className="h-[80vh] overflow-scroll px-4 md:px-10 job-detail-container pb-4 md:pb-10">
          {parse(termsContent)}
        </div>
      </DialogBody>
      <DialogFooter>
        <Button
          onClick={handleClose}
          className="jobCardButtons applyButton capitalize"
        >
          Close
        </Button>
      </DialogFooter>
    </Dialog>
  );
};

export default TermsAndConditions;
