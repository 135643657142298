import React, { useState } from "react";
import { saveReferralData } from "../../supabaseServices/reffer";

const ReferralSignUp = () => {
  const [seekerFormData, setSeekerFormData] = useState({
    full_name: "",
    email: "",
    resume: null,
    linkedin_url: "",
    company_of_interest: "",
    job_role_link: "",
  });

  const [referrerFormData, setReferrerFormData] = useState({
    full_name: "",
    email: "",
    linkedin_url: "",
    company: "",
  });

  const handleInputChange = (e, formSetter) => {
    const { name, value, files } = e.target;
    formSetter((prev) => ({
      ...prev,
      [name]: files ? files[0] : value,
    }));
  };

  const handleSubmitSeeker = async (e) => {
    e.preventDefault();
    try {
      const result = await saveReferralData(seekerFormData, "seekers");
      if (result.success) {
        alert("Referral seeker data saved successfully!");
        setSeekerFormData({
          full_name: "",
          email: "",
          resume: null,
          linkedin_url: "",
          company_of_interest: "",
          job_role_link: "",
        });
      } else {
        alert(`Failed to save referral seeker data: ${result.error}`);
      }
    } catch (error) {
      console.error(error);
      alert("An error occurred while submitting the form.");
    }
  };

  const handleSubmitReferrer = async (e) => {
    e.preventDefault();
    try {
      const result = await saveReferralData(referrerFormData, "referrers");
      if (result.success) {
        alert("Referrer data saved successfully!");
        setReferrerFormData({
          full_name: "",
          email: "",
          linkedin_url: "",
          company: "",
        });
      } else {
        alert(`Failed to save referrer data: ${result.error}`);
      }
    } catch (error) {
      console.error(error);
      alert("An error occurred while submitting the form.");
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center px-4 py-8">
      <div className="w-full max-w-6xl flex flex-col lg:flex-row gap-8 items-stretch">
        {/* Referral Seeker Form */}
        <div className="flex-1 bg-white shadow-md rounded-md p-6 flex flex-col">
          <h1 className="text-2xl font-bold mb-4">Looking for a Referral?</h1>
          <p className="text-gray-600 mb-6">
            Sign up and let us connect you with professionals who can refer you
            to your dream company.
          </p>
          <form className="space-y-4 flex-grow" onSubmit={handleSubmitSeeker}>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Full Name
              </label>
              <input
                type="text"
                name="full_name"
                value={seekerFormData.full_name}
                onChange={(e) => handleInputChange(e, setSeekerFormData)}
                placeholder="Enter your full name"
                className="mt-1 block w-full border border-gray-300 rounded-md p-2"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Email Address
              </label>
              <input
                type="email"
                name="email"
                value={seekerFormData.email}
                onChange={(e) => handleInputChange(e, setSeekerFormData)}
                placeholder="Enter your email"
                className="mt-1 block w-full border border-gray-300 rounded-md p-2"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Resume (PDF)
              </label>
              <input
                type="file"
                name="resume"
                accept=".pdf"
                onChange={(e) => handleInputChange(e, setSeekerFormData)}
                className="mt-1 block w-full border border-gray-300 rounded-md p-2"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                LinkedIn Profile
              </label>
              <input
                type="url"
                name="linkedin_url"
                value={seekerFormData.linkedin_url}
                onChange={(e) => handleInputChange(e, setSeekerFormData)}
                placeholder="Enter LinkedIn profile URL"
                className="mt-1 block w-full border border-gray-300 rounded-md p-2"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Company of Interest
              </label>
              <input
                type="text"
                name="company_of_interest"
                value={seekerFormData.company_of_interest}
                onChange={(e) => handleInputChange(e, setSeekerFormData)}
                placeholder="Enter the company you're interested in"
                className="mt-1 block w-full border border-gray-300 rounded-md p-2"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Job Role Link
              </label>
              <input
                type="url"
                name="job_role_link"
                value={seekerFormData.job_role_link}
                onChange={(e) => handleInputChange(e, setSeekerFormData)}
                placeholder="Enter the link to the job role"
                className="mt-1 block w-full border border-gray-300 rounded-md p-2"
              />
            </div>
            <button
              type="submit"
              className="w-full bg-[#164ED4] text-white rounded-md py-2 font-medium hover:bg-[#164ED4] mt-4"
            >
              Submit
            </button>
          </form>
        </div>

        {/* Referrer Sign-Up Form */}
        <div className="flex-1 bg-white shadow-md rounded-md p-6 flex flex-col">
          <h1 className="text-2xl font-bold mb-4">
            Want to Provide Referrals?
          </h1>
          <p className="text-gray-600 mb-6">
            Join our network of referrers and help others while expanding your
            professional connections.
          </p>
          <form className="space-y-4 flex-grow" onSubmit={handleSubmitReferrer}>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Full Name
              </label>
              <input
                type="text"
                name="full_name"
                value={referrerFormData.full_name}
                onChange={(e) => handleInputChange(e, setReferrerFormData)}
                placeholder="Enter your full name"
                className="mt-1 block w-full border border-gray-300 rounded-md p-2"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Email Address
              </label>
              <input
                type="email"
                name="email"
                value={referrerFormData.email}
                onChange={(e) => handleInputChange(e, setReferrerFormData)}
                placeholder="Enter your email"
                className="mt-1 block w-full border border-gray-300 rounded-md p-2"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                LinkedIn Profile
              </label>
              <input
                type="url"
                name="linkedin_url"
                value={referrerFormData.linkedin_url}
                onChange={(e) => handleInputChange(e, setReferrerFormData)}
                placeholder="Enter LinkedIn profile URL"
                className="mt-1 block w-full border border-gray-300 rounded-md p-2"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Company You Work For
              </label>
              <input
                type="text"
                name="company"
                value={referrerFormData.company}
                onChange={(e) => handleInputChange(e, setReferrerFormData)}
                placeholder="Enter the company you work for"
                className="mt-1 block w-full border border-gray-300 rounded-md p-2"
              />
            </div>
            <button
              type="submit"
              className="w-full bg-[#164ED4] text-white rounded-md py-2 font-medium hover:bg-[#164ED4] mt-4"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ReferralSignUp;
