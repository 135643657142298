import {
  ArrowRightIcon,
  FacebookIcon,
  InstagramIcon,
  LinkedInIcon,
  LocationIcon,
  MailIcon,
  TikTokIcon,
} from "../../assets/icons/icons";
import React from "react";
import Logo from "../../assets/icons/logo-icon.svg";
import { Link, useNavigate } from "react-router-dom";
import ContactForm from "../Contact/ContactForm";
import NewsletterSubscription from "../CustomComponents/NewsLetterSub";

const Footer = () => {
  const navigate = useNavigate();
  const handleNavigation = () => {
    navigate("/app/contact");
  };
  return (
    <div className="bg-[#F9F9F8] flex flex-col gap-[32px] max-xl:px-[6vw] px-[10vw] py-[36px] pt-[60px]">
      <div className="flex items-start justify-between gap-[32px] flex-col lg:flex-row">
        <div className="flex flex-col gap-[24px] lg:max-w-[320px] xl:max-w-[420px] full items-center sm:items-start">
          <img src={Logo} className="max-w-[144px]" alt="VisJobs Logo" />

          <p className="text-[#4A4C56] text-center sm:text-start">
            Our mission is to empower individuals seeking H1B opportunities by
            providing a user-friendly and intuitive job finding experience. With
            a deep understanding of the intricacies involved in securing H1B
            visas.
          </p>

          <div className="flex items-center gap-[12px] w-full justify-center sm:justify-start sm:items-start">
            {/* <a
              href="https://www.linkedin.com/company/visjobs"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center cursor-pointer justify-center group w-[32px] h-[32px] rounded-[4px] bg-[#ffffff]"
            >
              <LinkedInIcon className="w-[20px] group-hover:text-[#164ED4] transition-all text-[#A5A5AB]" />
            </a> */}
            <a
              href="https://www.instagram.com/visjobs/"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center cursor-pointer justify-center group w-[32px] h-[32px] rounded-[4px] bg-[#ffffff]"
            >
              <InstagramIcon className="w-[20px] group-hover:text-[#164ED4] transition-all text-[#A5A5AB]" />
            </a>
            <a
              href="https://www.tiktok.com/@visjobs"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center cursor-pointer justify-center group w-[32px] h-[32px] rounded-[4px] bg-[#ffffff]"
            >
              <TikTokIcon className="w-[20px] group-hover:text-[#164ED4] transition-all text-[#A5A5AB]" />
            </a>
          </div>
        </div>
        {/* <div className="flex justify-center items-center w-full">
          <div className="max-w-[320px] w-full bg-white p-4 rounded-md shadow-sm">
            <NewsletterSubscription />
          </div>
        </div> */}
        <div className="flex items-start gap-[42px] flex-col w-full sm:w-auto md:flex-row">
          <div className="flex flex-col gap-[24px] items-center sm:items-start w-full sm:w-auto">
            <span className="text-[20px] font-[600] text-[#4A4C56]">
              Legal & Privacy
            </span>
            <div className="flex flex-col gap-[16px] items-center sm:items-start">
              <div className="flex items-center gap-[6px]">
                <ArrowRightIcon className="w-[18px]" />
                <Link
                  to="app/legal"
                  className="text-[#4A4C56] hover:text-[#164ED4] cursor-pointer transition-all text-center sm:text-start"
                >
                  Terms of Service
                </Link>
              </div>
              <div className="flex items-center gap-[6px]">
                <ArrowRightIcon className="w-[18px]" />
                <Link
                  to="app/legal/"
                  className="text-[#4A4C56] hover:text-[#164ED4] cursor-pointer transition-all text-center sm:text-start"
                >
                  Privacy Policy
                </Link>
              </div>
              <div className="flex items-center gap-[6px]">
                <ArrowRightIcon className="w-[18px]" />
                <Link
                  to="app/legal/"
                  className="text-[#4A4C56] hover:text-[#164ED4] cursor-pointer transition-all text-center sm:text-start"
                >
                  Imprint
                </Link>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-[24px] items-center sm:items-start w-full sm:w-auto">
            <span className="text-[20px] font-[600] text-[#4A4C56]">
              Contact us
            </span>
            <div className="flex flex-col gap-[16px] items-center sm:items-start">
              <div className="flex items-center gap-[6px]">
                <div
                  className="flex items-center cursor-pointer justify-center group w-[32px] h-[32px] rounded-[4px] bg-[#ffffff]"
                  onClick={handleNavigation}
                >
                  <MailIcon className="w-[20px] text-[#A5A5AB]" />
                </div>
                <span
                  className="text-[#4A4C56] hover:text-[#164ED4] cursor-pointer transition-all text-center sm:text-start"
                  onClick={handleNavigation}
                >
                  Send us a Message
                </span>
              </div>
              {/* <div className="flex items-center gap-[6px]">
                <div className="flex items-center cursor-pointer justify-center group w-[32px] h-[32px] rounded-[4px] bg-[#ffffff]">
                  <LocationIcon className="w-[20px] text-[#A5A5AB]" />
                </div>
                <span className="text-[#4A4C56] hover:text-[#164ED4] cursor-pointer transition-all text-center sm:text-start">
                  30 N Gould St Ste N Sheridan, WY 82801
                </span>
              </div> */}
            </div>
          </div>
        </div>
      </div>

      <hr className="border-[#D2D2D5]" />

      <div className="flex flex-col items-center gap-[20px] w-full">
        <span className="text-[#4A4C56] font-[Montserrat]">
          © 2023 by VisJobs - All rights reserved.
        </span>

        {/* <p className="text-[#4A4C56] text-[14px] font-[300] max-w-[700px] text-center">
          As you pursue your dream EB-1 job, VisJobs stands as a completely
          independent entity, distinct from any company or affiliated companies.
          EB-1 data on this website is sourced from public sources, we are not
          responsible for any discrepancies.
        </p> */}
      </div>
    </div>
  );
};

export default Footer;
