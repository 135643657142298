import { Card, CardBody, CardHeader } from "@material-tailwind/react";
import React from "react";

import WageInformationGraph from "./WageInformationGraph";

const WageInformation = ({ data, companyName }) => {
  return (
    <div>
      <Card className="p-6">
        <CardHeader className="m-0 shadow-none space-y-4">
          <div className="flex items-center justify-between">
            <h1 className="text-2xl font-bold text-black">
              Top salaries of sponsored H-1B employees at {companyName}
            </h1>
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M21.75 3.5V9.5C21.75 9.914 21.414 10.25 21 10.25C20.586 10.25 20.25 9.914 20.25 9.5V5.31104L14.53 11.031C14.384 11.177 14.192 11.251 14 11.251C13.808 11.251 13.616 11.178 13.47 11.031C13.177 10.738 13.177 10.263 13.47 9.96997L19.1899 4.25H15C14.586 4.25 14.25 3.914 14.25 3.5C14.25 3.086 14.586 2.75 15 2.75H21C21.098 2.75 21.1951 2.77011 21.2871 2.80811C21.4701 2.88411 21.6161 3.03011 21.6931 3.21411C21.7301 3.30511 21.75 3.402 21.75 3.5ZM9.46997 13.97L3.75 19.6899V15.5C3.75 15.086 3.414 14.75 3 14.75C2.586 14.75 2.25 15.086 2.25 15.5V21.5C2.25 21.598 2.27011 21.6951 2.30811 21.7871C2.38411 21.9701 2.52989 22.1159 2.71289 22.1919C2.80489 22.2299 2.902 22.25 3 22.25H9C9.414 22.25 9.75 21.914 9.75 21.5C9.75 21.086 9.414 20.75 9 20.75H4.81104L10.531 15.03C10.824 14.737 10.824 14.262 10.531 13.969C10.238 13.676 9.76297 13.677 9.46997 13.97Z"
                fill="#4A4C56"
              />
            </svg>
          </div>
          <div className="flex items-center gap-8 ">
            <div className="flex items-center gap-2">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="16" height="16" rx="8" fill="#4571DD" />
              </svg>

              <p className="text-sm font-medium">Salary</p>
            </div>
          </div>
        </CardHeader>
        <CardBody className="m-0 p-0">
          <WageInformationGraph data={data} />
        </CardBody>
      </Card>
    </div>
  );
};

export default WageInformation;
