import React from "react";

const PersonalInformation = ({ formData, handleChange }) => {
  return (
    <div>
      {/* <h2 className="text-xl font-bold mb-4">Personal Information</h2> */}
      {/* <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">
          Full Name
        </label>
        <input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleChange}
          required
          className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
        />
      </div> */}
      <div className="flex flex-col md:flex-row items-center gap-4 ">
        <div className="mb-4 w-full space-y-2">
          <label className="text-base font-normal text-gray-800">Email</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            require
            placeholder="Enter your email address"
            className="w-full border  border-gray-600 rounded p-2 focus:outline-none focus:border-blue-500 focus:ring-blue-600 "
          />
        </div>
        {/* <div className="mb-4 w-full space-y-2">
          <label className="text-base font-normal text-gray-800">
            Number of Jobs You would like to apply to
          </label>
          <input
            type="number"
            name="numberOfJobs"
            value={formData.numberOfJobs}
            onChange={handleChange}
            placeholder="Enter the number of jobs you would like to apply to"
            required
            className="w-full border  border-gray-600 rounded p-2 focus:outline-none focus:border-blue-500 focus:ring-blue-600 "
          />
        </div> */}
      </div>
      {/* <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">
          Phone Number
        </label>
        <input
          type="text"
          name="phone"
          value={formData.phone}
          onChange={handleChange}
          className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
        />
      </div> */}
      {/* <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">
          Address
        </label>
        <input
          type="text"
          name="address"
          value={formData.address}
          onChange={handleChange}
          className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
        />
      </div> */}
    </div>
  );
};

export default PersonalInformation;
