const VisIconRed = () => {
  return (
    <div className="absolute top-0 right-0  z-[-1] hidden md:block">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="192"
        height="194"
        viewBox="0 0 192 194"
        fill="none"
      >
        <g filter="url(#filter0_d_348_3315)">
          <rect
            width="64.7654"
            height="65.3645"
            rx="20"
            transform="matrix(0.936134 -0.351877 0.325035 0.945784 55.3091 73.7894)"
            fill="#F3617C"
          />
        </g>
        <g clipPath="url(#clip0_348_3315)">
          <path
            d="M111.768 88.6663C112.559 91.6096 112.531 94.7341 111.685 97.6887C110.84 100.643 109.212 103.311 106.984 105.391C104.755 107.472 102.015 108.883 99.0715 109.467C96.1278 110.05 93.097 109.782 90.3197 108.693L97.0655 94.1928L111.768 88.6663Z"
            fill="white"
          />
          <path
            d="M111.025 86.5057L96.9488 91.7968L92.0161 77.4436C95.7546 76.3376 99.7349 76.6452 103.227 78.3097C106.718 79.9743 109.487 82.8843 111.025 86.5057Z"
            fill="white"
          />
          <path
            d="M88.2967 107.729C85.6986 106.255 83.5646 104.061 82.1344 101.393C80.7042 98.7242 80.0346 95.6876 80.2008 92.6239C80.367 89.5602 81.3625 86.5911 83.0753 84.0501C84.788 81.5091 87.1503 79.4971 89.8965 78.2401L95.0486 93.2317"
            fill="white"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_348_3315"
            x="0.708008"
            y="0.765259"
            width="191.077"
            height="193.079"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="30" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.920833 0 0 0 0 0.0498785 0 0 0 0 0.0498785 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_348_3315"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_348_3315"
              result="shape"
            />
          </filter>
          <clipPath id="clip0_348_3315">
            <rect
              width="38.8593"
              height="39.2187"
              fill="white"
              transform="matrix(0.936134 -0.351877 0.325035 0.945784 71.6841 81.5956)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default VisIconRed;
