import React from "react";
import AutoApplyFeature from "./components/AutoApplyLandingPage/AutoApplyDetailedInformation";
import UserImpression from "../UserImpression";

function LoadAutoApplyPage() {
  return (
    <>
      <AutoApplyFeature />
    </>
  );
}

export default LoadAutoApplyPage;
