import React, { useState, useEffect } from "react";
import axios from "axios";
import BlogCard from "./BlogCard/BlogCard";
import NewsletterSubscription from "../CustomComponents/NewsLetterSub";
import preloadedBlogs from "../../data/preloadedBlogs.json";
const BlogList = () => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [subjects, setSubjects] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState("All"); // Default to "All"
  const BASE_URL = "https://kazisai.us";

  useEffect(() => {
    const fetchAllBlogs = async () => {
      try {
        let allBlogs = [];
        let page = 1;
        let hasMorePages = true;

        // Loop through all pages
        while (hasMorePages) {
          const response = await axios.get(
            `${BASE_URL}/api/blogs?populate=*&pagination[page]=${page}&pagination[pageSize]=100`
          );
          const blogs = response.data.data;
          const pagination = response.data.meta.pagination;

          allBlogs = [...allBlogs, ...blogs];
          hasMorePages = pagination.page < pagination.pageCount;
          page += 1;
        }

        const sortedBlogs = allBlogs.sort(
          (a, b) => new Date(b.publishedAt) - new Date(a.publishedAt)
        );
        setBlogs(sortedBlogs);

        const uniqueSubjects = Array.from(
          new Set(sortedBlogs.map((blog) => blog.Subject || "General"))
        );
        setSubjects(["All", ...uniqueSubjects]);
      } catch (error) {
        console.error("Error fetching blogs", error);

        // Fallback to preloaded data
        const sortedPreloadedBlogs = preloadedBlogs.sort(
          (a, b) => new Date(b.publishedAt) - new Date(a.publishedAt)
        );
        setBlogs(sortedPreloadedBlogs);

        const uniqueSubjects = Array.from(
          new Set(sortedPreloadedBlogs.map((blog) => blog.Subject || "General"))
        );
        setSubjects(["All", ...uniqueSubjects]);
      } finally {
        setLoading(false);
      }
    };

    fetchAllBlogs();
  }, []);

  const filteredBlogs =
    selectedSubject === "All"
      ? blogs
      : blogs.filter((blog) => blog.Subject === selectedSubject);

  const mainStory = blogs.length ? blogs.find((blog) => blog.IsFeatured) : null;

  // Loading Skeleton Component
  const LoadingSkeleton = () => (
    <div className="animate-pulse">
      <div className="bg-gray-200 h-10 w-1/2 mb-4"></div>
      <div className="bg-gray-300 h-64 mb-4"></div>
      <div className="bg-gray-200 h-6 w-3/4 mb-4"></div>
      <div className="bg-gray-200 h-6 w-1/2"></div>
    </div>
  );

  return (
    <div className="container mx-auto p-4">
      <div className="grid grid-cols-1 lg:grid-cols-12 gap-4">
        {/* Left Sidebar - Subjects */}
        <div className="col-span-12 col-span-12 max-h-[600px] lg:col-span-2 col-span-12 lg:col-span-2 bg-gray-100 p-4 space-y-6 mt-6">
          <ul className="blog-list">
            {subjects.map((subject, index) => (
              <li
                key={index}
                className={`cursor-pointer mb-2 mt-3 ${
                  selectedSubject === subject
                    ? "text-[#164ED4] font-semibold"
                    : "hover:text-[#164ED4]"
                }`}
                onClick={() => setSelectedSubject(subject)}
              >
                {subject}
              </li>
            ))}
          </ul>
          <NewsletterSubscription />
        </div>

        {/* Right Content - Main Content or Filtered Blogs */}
        <div className="col-span-12 lg:col-span-9 mt-6 lg:ml-14">
          {selectedSubject === "All" ? (
            <>
              <div className="flex flex-col lg:flex-row gap-6">
                {/* Main Content */}
                <div className="w-full lg:w-2/3 space-y-1">
                  {loading ? (
                    <LoadingSkeleton />
                  ) : (
                    mainStory && (
                      <a
                        href={`/app/news/${mainStory.documentId}`}
                        className="block"
                      >
                        <h1 className="blog-title font-nytimes text-4xl font-bold leading-tight">
                          {mainStory.Title}
                        </h1>
                        <img
                          src={
                            mainStory.CoverImage &&
                            mainStory.CoverImage.length > 0
                              ? `${BASE_URL}${
                                  mainStory.CoverImage[0]?.formats?.medium
                                    ?.url || mainStory.CoverImage[0]?.url
                                }`
                              : mainStory.MetaTitle // Use MetaTitle as fallback if no cover image
                              ? mainStory.MetaTitle // MetaTitle contains the image URL in this case
                              : "/path/to/default-image.jpg" // Fallback to a default image path
                          }
                          className="w-full h-auto mt-6"
                          alt={mainStory.Title || "Default Alt Text"} // Fallback alt text
                        />
                      </a>
                    )
                  )}
                </div>

                {/* Right Sidebar - Latest Blogs */}
                <div className="w-full lg:w-1/3 flex flex-col justify-between pl-3 mt-6 lg:ml-14">
                  <div className="flex flex-col space-y-2">
                    {loading
                      ? Array(4)
                          .fill(0)
                          .map((_, index) => (
                            <div key={index} className="animate-pulse">
                              <div className="bg-gray-200 h-6 w-1/2 mb-2"></div>
                              <div className="bg-gray-300 h-8 mb-4"></div>
                              <div className="bg-gray-200 h-4 w-1/3"></div>
                            </div>
                          ))
                      : blogs.slice(1, 3).map((blog) => (
                          <a
                            href={`/app/news/${blog.documentId}`}
                            key={blog.id}
                            className="block"
                          >
                            <div className="border-b pb-4">
                              <span className="text-[#164ED4] font-semibold text-sm">
                                {blog.Subject || "General"}
                              </span>
                              <h3 className="font-bold text-lg mt-1 blog-subheading">
                                {blog.Title}
                              </h3>
                              <p className="text-gray-600 text-sm blog-paragraph">
                                {blog.Author}
                              </p>
                            </div>
                          </a>
                        ))}
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-1 gap-4">
                {loading
                  ? Array(3)
                      .fill(0)
                      .map((_, index) => (
                        <div key={index} className="animate-pulse">
                          <div className="bg-gray-200 h-6 w-1/2 mb-2"></div>
                          <div className="bg-gray-300 h-48 mb-4"></div>
                          <div className="bg-gray-200 h-4 w-3/4 mb-2"></div>
                          <div className="bg-gray-200 h-4 w-1/4"></div>
                        </div>
                      ))
                  : blogs.map((blog) => (
                      <a
                        href={`/app/news/${blog.documentId}`}
                        key={blog.id}
                        className="block"
                      >
                        <BlogCard
                          key={blog.id}
                          blog={{
                            Title: blog.Title,
                            Author: blog.Author,
                            MetaDescription: blog.MetaDescription,
                            CoverImage: blog.CoverImage,
                            MetaTitle: blog.MetaTitle,
                            PublishedDate: blog.PublishedDate,
                            Subject: blog.Subject,
                          }}
                        />
                      </a>
                    ))}
              </div>
            </>
          ) : (
            /* Display Filtered Blogs */
            <div className="grid grid-cols-1 gap-4">
              {loading
                ? Array(3)
                    .fill(0)
                    .map((_, index) => (
                      <div key={index} className="animate-pulse">
                        <div className="bg-gray-200 h-6 w-1/2 mb-2"></div>
                        <div className="bg-gray-300 h-48 mb-4"></div>
                        <div className="bg-gray-200 h-4 w-3/4 mb-2"></div>
                        <div className="bg-gray-200 h-4 w-1/4"></div>
                      </div>
                    ))
                : filteredBlogs.map((blog) => (
                    <a
                      href={`/app/news/${blog.documentId}`}
                      key={blog.id}
                      className="block"
                    >
                      <BlogCard
                        key={blog.id}
                        blog={{
                          Title: blog.Title,
                          Author: blog.Author,
                          MetaDescription: blog.MetaDescription,
                          CoverImage: blog.CoverImage,
                          MetaTitle: blog.MetaTitle,
                          PublishedDate: blog.PublishedDate,
                          Subject: blog.Subject,
                        }}
                      />
                    </a>
                  ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BlogList;
