import { logoutUser } from "../../services/auth";
import { logout } from "../reducers/auth";
import { clearCartData } from "../reducers/cart";

export const logoutUserAction = async (dispatch) => {
  const response = await logoutUser();
  await window.google.accounts.id.disableAutoSelect();
  if (!response) {
    localStorage.setItem("cart", JSON.stringify([]));
    await dispatch(logout());
    await dispatch(clearCartData());
    window.location.href = window.location.origin;
  }
};
