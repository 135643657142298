import Supabase from "../Utils/supabase";

export const saveSubscription = async (email) => {
  try {
    const response = await Supabase.from("NewsletterSubscription")
      .insert([{ email }]) // Insert the email into the table
      .select("id"); // Optionally, return the inserted row's ID

    if (response.error) {
      console.error("Error saving subscription:", response.error.message);
      return { success: false, error: response.error.message };
    }

    return { success: true, id: response.data[0].id };
  } catch (error) {
    console.error("Unexpected error:", error);
    return { success: false, error: "Unexpected error occurred." };
  }
};
