import React from "react";
import { Link } from "react-router-dom";

const NoCompanyJobFound = ({ text, link, linkText }) => {
  return (
    <div className="flex flex-col justify-center items-center space-y-4 my-8 mb-20 mt-10">
      <svg
        width="296"
        height="191"
        viewBox="0 0 296 191"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_4309_1231)">
          <path
            d="M269.364 190.435L260.953 165.044C260.953 165.044 270.205 174.298 270.205 181.892L268.544 164.332C268.544 164.332 273.289 170.739 272.814 180.468C272.34 190.197 269.364 190.435 269.364 190.435Z"
            fill="#E6E6E6"
          />
          <path
            d="M88.7364 167.317L80.5273 142.537C80.5273 142.537 89.5568 151.569 89.5568 158.98L87.9361 141.842C87.9361 141.842 92.5666 148.095 92.1035 157.59C91.6405 167.085 88.7364 167.317 88.7364 167.317Z"
            fill="#E6E6E6"
          />
          <path
            d="M216.862 178.752C216.876 195.053 184.555 190.039 144.986 190.073C105.417 190.107 73.5844 195.176 73.5703 178.875C73.5563 162.574 105.866 159.001 145.435 158.967C185.004 158.933 216.848 162.451 216.862 178.752Z"
            fill="#E6E6E6"
          />
          <path
            d="M295.25 190.062H0.75V190.808H295.25V190.062Z"
            fill="#3F3D56"
          />
          <path
            d="M135.595 173.953C148.563 173.953 159.076 163.437 159.076 150.465C159.076 137.493 148.563 126.977 135.595 126.977C122.626 126.977 112.113 137.493 112.113 150.465C112.113 163.437 122.626 173.953 135.595 173.953Z"
            fill="#6388E2"
          />
          <path
            d="M119.915 125.307C116.661 125.307 113.476 124.364 110.746 122.591C108.017 120.818 105.86 118.292 104.536 115.318L67.2749 31.3826C66.0406 28.6022 65.3878 25.5987 65.3566 22.5567C65.3255 19.5147 65.9166 16.4984 67.0936 13.6933C68.2707 10.8882 70.0088 8.35356 72.2012 6.24511C74.3935 4.13667 76.9938 2.499 79.8422 1.43277C82.6905 0.366533 85.7268 -0.105742 88.7643 0.044977C91.8019 0.195696 94.7765 0.966223 97.5054 2.30919C100.234 3.65217 102.66 5.53921 104.633 7.85433C106.606 10.1695 108.085 12.8637 108.978 15.7716L135.96 103.556C136.732 106.068 136.905 108.727 136.463 111.319C136.022 113.91 134.98 116.362 133.419 118.477C131.859 120.593 129.824 122.312 127.479 123.498C125.134 124.685 122.543 125.304 119.915 125.307Z"
            fill="#6388E2"
          />
        </g>
        <defs>
          <clipPath id="clip0_4309_1231">
            <rect
              width="294.5"
              height="191"
              fill="white"
              transform="translate(0.75)"
            />
          </clipPath>
        </defs>
      </svg>
      <p className="text-3xl line-height-[40px] font-semibold w-3/4">
        {text}{" "}
        <Link to={link} className="text-primary">
          {linkText}
        </Link>{" "}
      </p>
    </div>
  );
};

export default NoCompanyJobFound;
