import React, { useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Typography,
} from "@material-tailwind/react";
import { IconButton, Collapse } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

const NewsCard = ({ news }) => {
  const [expandedIndex, setExpandedIndex] = useState(null);

  const handleExpandClick = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  return (
    <Card className="p-5 border border-[#E9E9EA] space-y-4 rounded-3xl w-full lg:w-1/3 h-96 flex flex-col">
      {/* Card Header with flex-shrink-0 to ensure visibility */}
      <CardHeader className="shadow-none m-0 flex justify-center items-center flex-shrink-0">
        <Typography variant="h6" className="text-lg font-bold text-black">
          Top Headlines
        </Typography>
      </CardHeader>

      {/* Card Body with scrollable content */}
      <CardBody className="p-0 space-y-4 py-4 overflow-y-auto flex-grow">
        {news.map((item, index) => (
          <div key={item.id} className="space-y-2">
            {/* News Title and Expand Icon */}
            <div className="flex justify-between items-center">
              <Typography
                variant="subtitle2"
                onClick={() => handleExpandClick(index)}
                className="font-medium text-black cursor-pointer text-sm"
              >
                {item.Title}
              </Typography>
              <IconButton onClick={() => handleExpandClick(index)} size="small">
                {expandedIndex === index ? (
                  <ExpandLessIcon />
                ) : (
                  <ExpandMoreIcon />
                )}
              </IconButton>
            </div>
            {/* Divider */}
            <hr className="w-full border-t border-gray-300 my-2" />
            {/* Collapsible News Summary */}
            <Collapse in={expandedIndex === index} timeout="auto" unmountOnExit>
              <Typography
                variant="body2"
                className="text-gray-700 mt-1 text-xs"
              >
                {item.Summary}
              </Typography>
            </Collapse>
          </div>
        ))}
      </CardBody>
    </Card>
  );
};

export default NewsCard;
