import Tooltip from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))({
  [`& .MuiTooltip-tooltip`]: {
    backgroundColor: "#1d1f2c",
    color: "white",
    fontSize: 12,
    border: "1px solid #dadde9",
  },
  [`& .MuiTooltip-arrow`]: {
    color: "#1d1f2c",
  },
});

export default CustomTooltip;
