export const jsonData = {
  PetitionsFiled: {
    context:
      "Discover the total number of H-1B visa applications submitted each year. This gives you a clear picture of how many people are competing for these limited spots. It’s a great way to understand overall demand and plan your strategy.",
    2019: {
      Total: 420549,
    },
    2020: {
      Total: 427245,
    },
    2021: {
      Total: 426710,
    },
    2022: {
      Total: 474301,
    },
    2023: {
      Total: 386559,
    },
  },
  ApprovedPetitions: {
    context:
      "See how many H-1B visa applications get approved. Knowing the approval rate helps you understand your chances of success and gives insight into how policies may be affecting outcomes.",
    2019: {
      Total: 388403,
    },
    2020: {
      Total: 426710,
    },
    2021: {
      Total: 407071,
    },
    2022: {
      Total: 442043,
    },
    2023: {
      Total: 386318,
    },
  },
  PetitionsApprovedByCountryOfBirthAndGender: {
    context:
      "Explore the origins and gender distribution of H-1B applicants. This data provides insight into the diversity of applicants and highlights which countries and groups are most active.",
    2019: {
      Country: {
        India: {
          Total: 278491,
          Male: 0,
          Female: 0,
        },
        China: {
          Total: 50609,
          Male: 0,
          Female: 0,
        },
        Canada: {
          Total: 4615,
          Male: 0,
          Female: 0,
        },
        "South Korea": {
          Total: 3476,
          Male: 0,
          Female: 0,
        },
        Mexico: {
          Total: 2936,
          Male: 0,
          Female: 0,
        },
        Philippines: {
          Total: 2707,
          Male: 0,
          Female: 0,
        },
        Taiwan: {
          Total: 2637,
          Male: 0,
          Female: 0,
        },
        "United Kingdom": {
          Total: 1910,
          Male: 0,
          Female: 0,
        },
        Brazil: {
          Total: 1902,
          Male: 0,
          Female: 0,
        },
        Pakistan: {
          Total: 1766,
          Male: 0,
          Female: 0,
        },
        "Other Countries": {
          Total: 24694,
          Male: 0,
          Female: 0,
        },
      },
    },
    2020: {
      Country: {
        India: {
          Total: 319494,
          Male: 252395,
          Female: 67099,
        },
        China: {
          Total: 51597,
          Male: 28378,
          Female: 23219,
        },
        Canada: {
          Total: 3987,
          Male: 2552,
          Female: 1435,
        },
        "South Korea": {
          Total: 3665,
          Male: 2088,
          Female: 1577,
        },
        Philippines: {
          Total: 2711,
          Male: 1219,
          Female: 1492,
        },
        Mexico: {
          Total: 2543,
          Male: 1881,
          Female: 662,
        },
        Brazil: {
          Total: 1931,
          Male: 1275,
          Female: 656,
        },
        Pakistan: {
          Total: 1929,
          Male: 1543,
          Female: 386,
        },
      },
    },
    2021: {
      Country: {
        India: {
          Total: 301616,
          Male: 232244,
          Female: 69372,
        },
        China: {
          Total: 50328,
          Male: 27177,
          Female: 23151,
        },
        Canada: {
          Total: 3836,
          Male: 2493,
          Female: 1343,
        },
        "South Korea": {
          Total: 3481,
          Male: 2055,
          Female: 1426,
        },
        Philippines: {
          Total: 2786,
          Male: 1170,
          Female: 1616,
        },
        Mexico: {
          Total: 2611,
          Male: 1958,
          Female: 653,
        },
      },
    },
    2022: {
      Country: {
        India: {
          Total: 320791,
          Male: 76,
          Female: 24,
        },
        China: {
          Total: 55038,
          Male: 53,
          Female: 47,
        },
        Canada: {
          Total: 4235,
          Male: 61,
          Female: 39,
        },
        "South Korea": {
          Total: 4097,
          Male: 55,
          Female: 45,
        },
        Philippines: {
          Total: 3501,
          Male: 41,
          Female: 59,
        },
      },
    },
    2023: {
      Country: {
        India: {
          Total: 279386,
          Male: 212333,
          Female: 67053,
        },
        China: {
          Total: 45344,
          Male: 24032,
          Female: 21312,
        },
        Canada: {
          Total: 3852,
          Male: 2388,
          Female: 1464,
        },
        Philippines: {
          Total: 4619,
          Male: 1663,
          Female: 2956,
        },
      },
    },
  },
  PetitionsApprovedByAgeAndGender: {
    context:
      "Find out which age groups and genders are most actively seeking H-1B sponsorship. This helps you understand where you fit in among applicants and see the demographics of the H-1B workforce.",
    2019: {
      Age: {
        "Under 25": {
          Total: 12164,
          Male: 0,
          Female: 0,
        },
        "25-29": {
          Total: 111712,
          Male: 0,
          Female: 0,
        },
        "30-34": {
          Total: 130692,
          Male: 0,
          Female: 0,
        },
        "35-39": {
          Total: 90882,
          Male: 0,
          Female: 0,
        },
        "40-44": {
          Total: 31354,
          Male: 0,
          Female: 0,
        },
        "45 and over": {
          Total: 13634,
          Male: 0,
          Female: 0,
        },
      },
    },
    2020: {
      Age: {},
    },
    2021: {
      Age: {
        "Under 25": {
          Total: 4071,
          Male: 2044,
          Female: 2027,
        },
        "25-29": {
          Total: 105839,
          Male: 65119,
          Female: 40720,
        },
        "30-34": {
          Total: 135843,
          Male: 93535,
          Female: 42308,
        },
        "35-39": {
          Total: 101768,
          Male: 81414,
          Female: 20354,
        },
        "40-44": {
          Total: 40707,
          Male: 36636,
          Female: 4071,
        },
        "45 and over": {
          Total: 12243,
          Male: 12243,
          Female: 0,
        },
      },
    },
    2022: {
      Age: {
        "Under 25": {
          Total: 8840,
          Male: 4420,
          Female: 4420,
        },
        "25-29": {
          Total: 110510,
          Male: 66306,
          Female: 44204,
        },
        "30-34": {
          Total: 141453,
          Male: 97249,
          Female: 44204,
        },
        "35-39": {
          Total: 110511,
          Male: 83988,
          Female: 26523,
        },
        "40-44": {
          Total: 48625,
          Male: 39784,
          Female: 8841,
        },
        "45 and over": {
          Total: 15471,
          Male: 13261,
          Female: 2210,
        },
      },
    },
    2023: {
      Age: {
        "Under 25": {
          Total: 34769,
          Male: 23179,
          Female: 11590,
        },
        "25-29": {
          Total: 84990,
          Male: 57948,
          Female: 27042,
        },
        "30-34": {
          Total: 181570,
          Male: 104306,
          Female: 77264,
        },
        "35-39": {
          Total: 262696,
          Male: 123622,
          Female: 139074,
        },
        "40-44": {
          Total: 181569,
          Male: 69537,
          Female: 112032,
        },
        "45 and over": {
          Total: 23179,
          Male: 7726,
          Female: 15453,
        },
      },
    },
  },
  PetitionsApprovedByEducationAndGender: {
    context:
      "Learn how education levels and gender impact H-1B applications. This reveals whether certain degrees or qualifications improve your chances and highlights any gender-based trends.",
    2019: {
      EducationLevel: {
        "No High School Diploma": {
          Total: 23,
          Male: 0,
          Female: 0,
        },
        "High School Graduate": {
          Total: 80,
          Male: 0,
          Female: 0,
        },
        "Less Than 1 Year of College Credit": {
          Number: 10,
          Male: 0,
          Female: 0,
        },
        "1 or More Years of College Credit, No Diploma": {
          Number: 132,
          Male: 0,
          Female: 0,
        },
        "Associate's Degree": {
          Number: 113,
          Male: 0,
          Female: 0,
        },
        "Bachelor's Degree": {
          Number: 137869,
          Male: 0,
          Female: 0,
        },
        "Master's Degree": {
          Number: 207970,
          Male: 0,
          Female: 0,
        },
        "Doctorate Degree": {
          Number: 29444,
          Male: 0,
          Female: 0,
        },
        "Professional Degree": {
          Number: 12718,
          Male: 0,
          Female: 0,
        },
        "Education Unknown": {
          Number: 44,
          Male: 0,
          Female: 0,
        },
        Total: {
          Number: 387403,
          Male: 0,
          Female: 0,
        },
      },
    },
    2020: {
      context:
        "Discover which job roles have the highest demand for H-1B sponsorship. This information is helpful for job seekers exploring in-demand careers and for companies understanding competition for talent.",
      EducationLevel: {
        "No High School Diploma": {
          Number: 0,
          Male: 0,
          Female: 0,
        },
        "High School Graduate": {
          Number: 0,
          Male: 0,
          Female: 0,
        },
        "Less Than 1 Year of College Credit": {
          Number: 0,
          Male: 0,
          Female: 0,
        },
        "1 or More Years of College Credit, No Diploma": {
          Number: 0,
          Male: 0,
          Female: 0,
        },
        "Associate's Degree": {
          Number: 0,
          Male: 0,
          Female: 0,
        },
        "Bachelor's Degree": {
          Number: 109378,
          Male: 42657,
          Female: 29531,
        },
        "Master's Degree": {
          Number: 163303,
          Male: 86551,
          Female: 94752,
        },
        "Doctorate Degree": {
          Number: 21267,
          Male: 1276,
          Female: 1914,
        },
        "Professional Degree": {
          Number: 8876,
          Male: 178,
          Female: 444,
        },
        "Education Unknown": {
          Number: 0,
          Male: 0,
          Female: 0,
        },
        Total: {
          Number: 303824,
          Male: 160027,
          Female: 143797,
        },
      },
    },
    2021: {
      EducationLevel: {
        "No High School Diploma": {
          Number: 0,
          Male: 0,
          Female: 0,
        },
        "High School Graduate": {
          Number: 0,
          Male: 0,
          Female: 0,
        },
        "Less Than 1 Year of College Credit": {
          Number: 0,
          Male: 0,
          Female: 0,
        },
        "1 or More Years of College Credit, No Diploma": {
          Number: 0,
          Male: 0,
          Female: 0,
        },
        "Associate's Degree": {
          Number: 0,
          Male: 0,
          Female: 0,
        },
        "Bachelor's Degree": {
          Number: 137957,
          Male: 96696,
          Female: 41261,
        },
        "Master's Degree": {
          Number: 230813,
          Male: 131563,
          Female: 99250,
        },
        "Doctorate Degree": {
          Number: 28495,
          Male: 19477,
          Female: 9018,
        },
        "Professional Degree": {
          Number: 11806,
          Male: 4974,
          Female: 6832,
        },
        "Education Unknown": {
          Number: 0,
          Male: 0,
          Female: 0,
        },
        Total: {
          Number: 407071,
          Male: 270710,
          Female: 136361,
        },
      },
    },
    2022: {
      EducationLevel: {
        "No High School Diploma": {
          Number: 0,
          Male: 0,
          Female: 0,
        },
        "High School Graduate": {
          Number: 0,
          Male: 0,
          Female: 0,
        },
        "Less Than 1 Year of College Credit": {
          Number: 0,
          Male: 0,
          Female: 0,
        },
        "1 or More Years of College Credit, No Diploma": {
          Number: 0,
          Male: 0,
          Female: 0,
        },
        "Associate's Degree": {
          Number: 0,
          Male: 0,
          Female: 0,
        },
        "Bachelor's Degree": {
          Number: 278487,
          Male: 103040,
          Female: 66837,
        },
        "Master's Degree": {
          Number: 260805,
          Male: 86066,
          Female: 86066,
        },
        "Doctorate Degree": {
          Number: 79568,
          Male: 5570,
          Female: 7161,
        },
        "Professional Degree": {
          Number: 30943,
          Male: 928,
          Female: 1547,
        },
        "Education Unknown": {
          Number: 234283,
          Male: 63256,
          Female: 67942,
        },
        Total: {
          Number: 442043,
          Male: 258860,
          Female: 229553,
        },
      },
    },
    2023: {
      EducationLevel: {
        "No High School Diploma": {
          Number: 0,
          Male: 0,
          Female: 0,
        },
        "High School Graduate": {
          Number: 0,
          Male: 0,
          Female: 0,
        },
        "Less Than 1 Year of College Credit": {
          Number: 0,
          Male: 0,
          Female: 0,
        },
        "1 or More Years of College Credit, No Diploma": {
          Number: 0,
          Male: 0,
          Female: 0,
        },
        "Associate's Degree": {
          Number: 0,
          Male: 0,
          Female: 0,
        },
        "Bachelor's Degree": {
          Number: 270423,
          Male: 101485,
          Female: 31369,
        },
        "Master's Degree": {
          Number: 158390,
          Male: 60342,
          Female: 25768,
        },
        "Doctorate Degree": {
          Number: 73401,
          Male: 19200,
          Female: 11203,
        },
        "Professional Degree": {
          Number: 30906,
          Male: 8229,
          Female: 5602,
        },
        "Education Unknown": {
          Number: 254970,
          Male: 87955,
          Female: 38165,
        },
        Total: {
          Number: 386318,
          Male: 274211,
          Female: 112107,
        },
      },
    },
  },
  PetitionsApprovedByOccupation: {
    context:
      "See the average salaries for H-1B workers across different jobs. This data helps you set realistic salary expectations and understand how different roles are valued in the market.",
    2019: {
      OccupationCategory: {
        "All Occupations": 426710,
        "Computer-Related": 256226,
        "Architecture, Engineering, and Surveying": 39070,
        "Administrative Specializations": 22125,
        Education: 19850,
        "Medicine and Health": 15233,
        "Mathematics and Physical Sciences": 11642,
        "Life Sciences": 6334,
        "Managers and Officials": 4618,
        "Miscellaneous Professional, Technical, and Managerial": 4162,
        "Social Sciences": 3665,
        Art: 2209,
        "Law and Jurisprudence": 1344,
        Writing: 460,
        Miscellaneous: 226,
        "Museum, Library, and Archival Sciences": 144,
        "Entertainment and Recreation": 128,
        "Religion and Theology": 54,
        "Sale Promotion": 2,
        "Occupation Unknown": 911,
      },
    },
    2020: {
      OccupationCategory: {
        "All Occupations": 426710,
        "Computer-Related": 296572,
        "Architecture, Engineering, and Surveying": 38839,
        "Administrative Specializations": 20917,
        Education: 18980,
        "Medicine and Health": 15192,
        "Mathematics and Physical Sciences": 11545,
        "Life Sciences": 6756,
        "Managers and Officials": 5049,
        "Miscellaneous Professional, Technical, and Managerial": 3993,
        "Social Sciences": 3486,
        Art: 1974,
        "Law and Jurisprudence": 1453,
        Writing: 0,
        Miscellaneous: 0,
        "Museum, Library, and Archival Sciences": 0,
        "Entertainment and Recreation": 0,
        "Religion and Theology": 0,
        "Sale Promotion": 0,
        "Occupation Unknown": 0,
      },
    },
    2021: {
      OccupationCategory: {
        "All Occupations": 407071,
        "Computer-Related": 280032,
        "Architecture, Engineering, and Surveying": 38863,
        "Administrative Specializations": 20730,
        Education: 16226,
        "Medicine and Health": 14070,
        "Mathematics and Physical Sciences": 11849,
        "Life Sciences": 6759,
        "Managers and Officials": 5098,
        "Miscellaneous Professional, Technical, and Managerial": 4140,
        "Social Sciences": 3665,
        Art: 1887,
        "Law and Jurisprudence": 1585,
        Writing: 0,
        Miscellaneous: 0,
        "Museum, Library, and Archival Sciences": 0,
        "Entertainment and Recreation": 0,
        "Religion and Theology": 0,
        "Sale Promotion": 0,
        "Occupation Unknown": 0,
      },
    },
    2022: {
      OccupationCategory: {
        "All Occupations": 442043,
        "Computer-Related": 291780,
        "Architecture, Engineering, and Surveying": 43351,
        "Administrative Specializations": 23022,
        Education: 10980,
        "Medicine and Health": 10229,
        "Mathematics and Physical Sciences": 7904,
        "Life Sciences": 7918,
        "Managers and Officials": 5944,
        "Miscellaneous Professional, Technical, and Managerial": 5189,
        "Social Sciences": 1884,
        Art: 2160,
        "Law and Jurisprudence": 799,
        Writing: 0,
        Miscellaneous: 0,
        "Museum, Library, and Archival Sciences": 0,
        "Entertainment and Recreation": 0,
        "Religion and Theology": 0,
        "Sale Promotion": 0,
        "Occupation Unknown": 0,
      },
    },
    2023: {
      OccupationCategory: {
        "All Occupations": 386318,
        "Computer-Related": 251084,
        "Architecture, Engineering, and Surveying": 36773,
        "Administrative Specializations": 19503,
        Education: 23365,
        "Medicine and Health": 16684,
        "Mathematics and Physical Sciences": 10586,
        "Life Sciences": 7250,
        "Managers and Officials": 5570,
        "Miscellaneous Professional, Technical, and Managerial": 4648,
        "Social Sciences": 3052,
        Art: 1694,
        "Law and Jurisprudence": 1468,
        Writing: 0,
        Miscellaneous: 0,
        "Museum, Library, and Archival Sciences": 0,
        "Entertainment and Recreation": 0,
        "Religion and Theology": 0,
        "Sale Promotion": 0,
        "Occupation Unknown": 0,
      },
    },
  },
  CompensationByOccupationsApproved: {
    context:
      "See the average salaries for H-1B workers across different jobs. This data helps you set realistic salary expectations and understand how different roles are valued in the market.",
    2019: {
      Occupation: {
        "Computer-Related": 102000,
        "Architecture, Engineering, and Surveying": 92000,
        "Administrative Specializations": 88000,
        Education: 63000,
        "Medicine and Health": 96000,
        "Mathematics and Physical Sciences": 96000,
        "Life Sciences": 67000,
        "Managers and Officials": 123000,
        "Miscellaneous Professional, Technical, and Managerial": 118000,
        "Social Sciences": 90000,
        Art: 73000,
        "Law and Jurisprudence": 180000,
      },
    },
    2020: {
      Occupation: {
        "Computer-Related": 120000,
        "Architecture, Engineering, and Surveying": 102000,
        "Administrative Specializations": 95000,
        Education: 70000,
        "Medicine and Health": 110000,
        "Mathematics and Physical Sciences": 115000,
        "Life Sciences": 90000,
        "Managers and Officials": 120000,
        "Miscellaneous Professional, Technical, and Managerial": 113000,
        "Social Sciences": 98000,
        Art: 75000,
        "Law and Jurisprudence": 160000,
      },
    },
    2021: {
      Occupation: {
        "Computer-Related": 125000,
        "Architecture, Engineering, and Surveying": 105000,
        "Administrative Specializations": 97000,
        Education: 72000,
        "Medicine and Health": 115000,
        "Mathematics and Physical Sciences": 118000,
        "Life Sciences": 92000,
        "Managers and Officials": 122000,
        "Miscellaneous Professional, Technical, and Managerial": 116000,
        "Social Sciences": 99000,
        Art: 76000,
        "Law and Jurisprudence": 165000,
      },
    },
    2022: {
      Occupation: {
        "Computer-Related": 130000,
        "Architecture, Engineering, and Surveying": 110000,
        "Administrative Specializations": 95000,
        Education: 70000,
        "Medicine and Health": 120000,
        "Mathematics and Physical Sciences": 115000,
        "Life Sciences": 90000,
        "Managers and Officials": 125000,
        "Miscellaneous Professional, Technical, and Managerial": 118000,
        "Social Sciences": 98000,
        Art: 78000,
        "Law and Jurisprudence": 170000,
      },
    },
    2023: {
      Occupation: {
        "Computer-Related": 251084,
        "Architecture, Engineering, and Surveying": 36773,
        "Administrative Specializations": 19503,
        Education: 23365,
        "Medicine and Health": 16684,
        "Mathematics and Physical Sciences": 10586,
        "Life Sciences": 7250,
        "Managers and Officials": 5570,
        "Miscellaneous Professional, Technical, and Managerial": 4648,
        "Social Sciences": 3052,
        Art: 1694,
        "Law and Jurisprudence": 1468,
      },
    },
  },
  CompensationOccupationAndGender: {
    context:
      "Compare salaries between men and women in the same job roles. This reveals any pay gaps and helps you advocate for fair compensation based on your role and qualifications.",
    2019: null,
    2020: {
      Occupation: {
        "All Occupations": {
          Male: 104000,
          Female: 94000,
          AllMedian: 99000,
        },
        "Computer-Related": {
          Male: 122000,
          Female: 108000,
          AllMedian: 115000,
        },
        "Architecture, Engineering, and Surveying": {
          Male: 105000,
          Female: 94000,
          AllMedian: 100000,
        },
        "Administrative Specializations": {
          Male: 98000,
          Female: 88000,
          AllMedian: 93000,
        },
        Education: {
          Male: 75000,
          Female: 62000,
          AllMedian: 68500,
        },
        "Medicine and Health": {
          Male: 115000,
          Female: 102000,
          AllMedian: 109000,
        },
        "Mathematics and Physical Sciences": {
          Male: 118000,
          Female: 105000,
          AllMedian: 111500,
        },
        "Life Sciences": {
          Male: 95000,
          Female: 85000,
          AllMedian: 90000,
        },
        "Managers and Officials": {
          Male: 125000,
          Female: 110000,
          AllMedian: 117500,
        },
        "Miscellaneous Professional, Technical, and Managerial": {
          Male: 115000,
          Female: 102000,
          AllMedian: 108500,
        },
        "Social Sciences": {
          Male: 103000,
          Female: 92000,
          AllMedian: 97500,
        },
        Art: {
          Male: 80000,
          Female: 72000,
          AllMedian: 76000,
        },
        "Law and Jurisprudence": {
          Male: 180000,
          Female: 160000,
          AllMedian: 170000,
        },
      },
    },
    2021: {
      Occupation: {
        "All Occupations": {
          Male: 110000,
          Female: 97000,
          AllMedian: 103500,
        },
        "Computer-Related": {
          Male: 130000,
          Female: 115000,
          AllMedian: 122500,
        },
        "Architecture, Engineering, and Surveying": {
          Male: 108000,
          Female: 98000,
          AllMedian: 103000,
        },
        "Administrative Specializations": {
          Male: 101000,
          Female: 90000,
          AllMedian: 95500,
        },
        Education: {
          Male: 78000,
          Female: 66000,
          AllMedian: 72000,
        },
        "Medicine and Health": {
          Male: 118000,
          Female: 105000,
          AllMedian: 111500,
        },
        "Mathematics and Physical Sciences": {
          Male: 120000,
          Female: 107000,
          AllMedian: 113500,
        },
        "Life Sciences": {
          Male: 97000,
          Female: 88000,
          AllMedian: 92500,
        },
        "Managers and Officials": {
          Male: 127000,
          Female: 112000,
          AllMedian: 119500,
        },
        "Miscellaneous Professional, Technical, and Managerial": {
          Male: 117000,
          Female: 104000,
          AllMedian: 110500,
        },
        "Social Sciences": {
          Male: 105000,
          Female: 95000,
          AllMedian: 100000,
        },
        Art: {
          Male: 82000,
          Female: 74000,
          AllMedian: 78000,
        },
        "Law and Jurisprudence": {
          Male: 185000,
          Female: 165000,
          AllMedian: 175000,
        },
      },
    },
    2022: {
      Occupation: {
        "All Occupations": {
          Male: 121000,
          Female: 108000,
          AllMedian: 114500,
        },
        "Computer-Related": {
          Male: 140000,
          Female: 125000,
          AllMedian: 132500,
        },
        "Architecture, Engineering, and Surveying": {
          Male: 115000,
          Female: 100000,
          AllMedian: 107500,
        },
        "Administrative Specializations": {
          Male: 102000,
          Female: 92000,
          AllMedian: 97000,
        },
        Education: {
          Male: 75000,
          Female: 65000,
          AllMedian: 70000,
        },
        "Medicine and Health": {
          Male: 130000,
          Female: 110000,
          AllMedian: 120000,
        },
        "Mathematics and Physical Sciences": {
          Male: 125000,
          Female: 110000,
          AllMedian: 117500,
        },
        "Life Sciences": {
          Male: 95000,
          Female: 85000,
          AllMedian: 90000,
        },
        "Managers and Officials": {
          Male: 135000,
          Female: 120000,
          AllMedian: 127500,
        },
        "Miscellaneous Professional, Technical, and Managerial": {
          Male: 120000,
          Female: 105000,
          AllMedian: 112500,
        },
        "Social Sciences": {
          Male: 103000,
          Female: 97000,
          AllMedian: 100000,
        },
        Art: {
          Male: 80000,
          Female: 75000,
          AllMedian: 77500,
        },
        "Law and Jurisprudence": {
          Male: 190000,
          Female: 170000,
          AllMedian: 180000,
        },
      },
    },
    2023: {
      Occupation: {
        "All Occupations": {
          Male: 90000,
          Female: 118000,
          AllMedian: 153000,
        },
        "Computer-Related": {
          Male: 90000,
          Female: 118000,
          AllMedian: 153000,
        },
        "Architecture, Engineering, and Surveying": {
          Male: 90000,
          Female: 118000,
          AllMedian: 153000,
        },
        Education: {
          Male: 90000,
          Female: 118000,
          AllMedian: 153000,
        },
        "Administrative Specializations": {
          Male: 90000,
          Female: 118000,
          AllMedian: 153000,
        },
        "Medicine and Health": {
          Male: 90000,
          Female: 118000,
          AllMedian: 153000,
        },
        "Mathematics and Physical Sciences": {
          Male: 90000,
          Female: 118000,
          AllMedian: 153000,
        },
        "Life Sciences": {
          Male: 90000,
          Female: 118000,
          AllMedian: 153000,
        },
        "Managers and Officials": {
          Male: 90000,
          Female: 118000,
          AllMedian: 153000,
        },
        "Miscellaneous Professional, Technical, and Managerial": {
          Male: 90000,
          Female: 118000,
          AllMedian: 153000,
        },
        "Social Sciences": {
          Male: 90000,
          Female: 118000,
          AllMedian: 153000,
        },
        Art: {
          Male: 90000,
          Female: 118000,
          AllMedian: 153000,
        },
        "Law and Jurisprudence": {
          Male: 90000,
          Female: 118000,
          AllMedian: 153000,
        },
      },
    },
  },
  CompensationByEducation: {
    context:
      "Understand how your level of education affects your earning potential as an H-1B worker. This can help you make informed decisions about whether pursuing further education is worthwhile.",
    2019: null,
    2020: {
      EducationLevel: {
        "Master's Degree": {
          MedianSalary: 95000,
        },
        "Bachelor's Degree": {
          MedianSalary: 70000,
        },
        "Doctorate Degree": {
          MedianSalary: 120000,
        },
        "Professional Degree": {
          MedianSalary: 130000,
        },
        "Education Unknown": {
          MedianSalary: null,
        },
      },
    },
    2021: {
      EducationLevel: {
        "Master's Degree": {
          MedianSalary: 108000,
        },
        "Bachelor's Degree": {
          MedianSalary: 85000,
        },
        "Doctorate Degree": {
          MedianSalary: 140000,
        },
        "Professional Degree": {
          MedianSalary: 150000,
        },
        "Education Unknown": {
          MedianSalary: null,
        },
      },
    },
    2022: {
      EducationLevel: {
        "Bachelor's Degree": {
          MedianSalary: 108000,
        },
        "Master's Degree": {
          MedianSalary: 120000,
        },
        "Doctorate Degree": {
          MedianSalary: 150000,
        },
        "Professional Degree": {
          MedianSalary: 115000,
        },
        "Education Unknown": {
          MedianSalary: null,
        },
      },
    },
    2023: {
      EducationLevel: {
        "Bachelor's Degree": {
          MedianSalary: 90000,
        },
        "Master's Degree": {
          MedianSalary: 118000,
        },
        Doctorate: {
          MedianSalary: 153000,
        },
        "Professional Degree": {
          MedianSalary: 118000,
        },
        "Education Unknown": {
          MedianSalary: null,
        },
      },
    },
  },
  IndustrySector: {
    context:
      "Find out which industries sponsor the most H-1B visas. This data shows where opportunities are growing and which sectors have a high demand for global talent.",
    2019: {
      Sector: {
        "Industry known": 361957,
        "Custom Computer Programming Services": 99015,
        "Computer Systems Design Services": 35356,
        "Colleges, Universities, and Professional Schools": 21225,
        "Software Publishers": 13146,
        "Engineering Services": 9551,
        "Computer Systems Design and Related Services": 9506,
        "Management, Scientific, and Technical Consulting Services": 7681,
        "Other Computer Related Services": 7434,
        "Electronic Computer Manufacturing": 6970,
        "General Medical and Surgical Hospitals": 6412,
        "Management Consulting Services": 6346,
        "Investment Banking and Securities Dealing": 5897,
        "Administrative Management and General Management Consulting Services": 4414,
        "Offices of Certified Public Accountants": 4002,
        "Semiconductor and Other Electronic Component Manufacturing": 3920,
        "Data Processing, Hosting, and Related Services": 3503,
        "Research and Development in the Physical, Engineering, and Life Sciences": 3495,
        "Semiconductor and Related Device Manufacturing": 3241,
        "Commercial Banking": 3083,
        "Offices of Physicians (except Mental Health Specialists)": 2789,
        "Other Industries": 104971,
        "Industry unknown": 26446,
      },
    },
    2020: {
      Sector: {
        "Professional, Scientific, and Technical Services": 107,
        Manufacturing: 19,
        "Finance and Insurance": 12,
        Information: 12,
        "Educational Services": 13,
        "Health Care and Social Assistance": 8,
        Other: 28,
      },
    },
    2021: {
      Sector: {
        "Professional, Scientific, and Technical Services": 50,
        Manufacturing: 11,
        "Finance and Insurance": 8,
        Information: 7,
        "Educational Services": 5,
        "Retail Trade": 5,
        "Health Care and Social Assistance": 4,
        "Other or Unknown": 10,
      },
    },
    2022: {
      Sector: {
        "Professional, Scientific, and Technical Services": 46,
        Manufacturing: 11,
        Information: 10,
        "Finance and Insurance": 9,
        "Educational Services": 6,
        "Retail Trade": 6,
        "Health Care and Social Assistance": 4,
        "Other or Unknown": 8,
      },
    },
    2023: {
      Sector: {
        "Professional, Scientific, and Technical Services": 48,
        Manufacturing: 10,
        Information: 9,
        "Finance and Insurance": 8,
        "Educational Services": 8,
        "Retail Trade": 6,
        "Health Care and Social Assistance": 5,
        "Other or Unknown": 6,
      },
    },
  },
  LocationAndPriorStatus: {
    context:
      "See where H-1B workers are most commonly based and learn about their previous visa status. This information can guide your job search and help you plan relocations effectively.",
    2019: null,
    2020: {
      Location: {
        Students: 234944,
        "Exchange Visitors": 21268,
        "Spouse or Child of H-Visa Holder": 18229,
        "Other Status or Unknown": 15191,
        "Intracompany Transferees": 15191,
      },
    },
    2021: {
      Location: {
        Students: 305330,
        "Exchange Visitors": 4665,
        "Spouse or Child of H-Visa Holder": 5442,
        "Other Status or Unknown": 4665,
        "Intracompany Transferees": 3886,
      },
    },
    2022: {
      Location: {
        Students: 340373,
        "Spouse or Child of H-Visa Holder": 30943,
        "Other Status or Unknown": 26523,
        "Exchange Visitors": 26523,
        "Intracompany Transferees": 17682,
      },
    },
    2023: {
      Location: {
        Students: 278150,
        "Exchange Visitors": 42495,
        "Spouse or Child of H-Visa Holder": 30905,
        "Other Status or Unknown": 19316,
        "Intracompany Transferees": 15452,
      },
    },
  },
  ValidityPeriod: {
    context:
      "Learn how long H-1B visas are typically valid before needing renewal. This helps you plan your career timeline and understand the duration of your work authorization in the U.S.",
    2019: null,
    2020: {
      ValidityPeriod: {
        "Less than 12 months": 24306,
        "12 to 23 months": 33620,
        "24 to 36 months": 240898,
      },
    },
    2021: {
      ValidityPeriod: {
        "Less than 12 months": 12619,
        "12 to 23 months": 30937,
        "24 to 36 months": 356515,
      },
    },
    2022: {
      ValidityPeriod: {
        "Less than 12 months": 13261,
        "12 to 23 months": 35363,
        "24 to 36 months": 388998,
        Unknown: 4420,
      },
    },
    2023: {
      ValidityPeriod: {
        "Less than 12 months": 11589,
        "12 to 23 months": 30905,
        "24 to 36 months": 339961,
        Unknown: 7726,
      },
    },
  },
  PremiumProcessing: {
    context:
      "See how often people use premium processing to speed up their H-1B applications. Understanding this option can help you decide if it’s necessary for your situation.",
    2019: null,
    2020: {
      Requested: 184772,
      NotRequested: 168402,
    },
    2021: {
      Requested: 242804,
      NotRequested: 164267,
    },
    2022: {
      Requested: 172396,
      NotRequested: 269647,
    },
    2023: {
      Requested: 177706,
      NotRequested: 208612,
    },
  },
  RequestsForEvidence: {
    context:
      "Discover how frequently H-1B applications receive requests for more information. Knowing this helps you prepare a stronger application and avoid delays caused by RFEs.",
    2019: null,
    2020: {
      ApprovedWithoutRFE: 289307,
      DeniedWithoutRFE: 4666,
      ApprovedWithRFE: 104658,
      DeniedWithRFE: 32694,
    },
    2021: {
      ApprovedWithoutRFE: 313725,
      DeniedWithoutRFE: 8366,
      ApprovedWithRFE: 53301,
      DeniedWithRFE: 17179,
    },
    2022: {
      ApprovedWithoutRFE: 388998,
      DeniedWithoutRFE: 0,
      ApprovedWithRFE: 46127,
      DeniedWithRFE: 6918,
    },
    2023: {
      ApprovedWithoutRFE: 318057,
      DeniedWithoutRFE: 3863,
      ApprovedWithRFE: 57948,
      DeniedWithRFE: 11590,
    },
  },
};

export const eventData = {
  2019: {
    events: {
      event1: {
        summary: "H-1B Electronic Registration Implementation",
        detail:
          "The U.S. Citizenship and Immigration Services (USCIS) introduced an electronic registration process for H-1B cap-subject petitions. This system required employers to submit basic information about their prospective H-1B employees online before filing full petitions. The change aimed to streamline the application process and reduce paperwork, making it more efficient for both employers and the agency.",
        sentiment: "Positive",
        source:
          "https://www.uscis.gov/working-in-the-united-states/h-1b-specialty-occupations",
      },
    },
  },
  2020: {
    events: {
      event1: {
        summary: "COVID-19 Travel Restrictions",
        detail:
          "In response to the COVID-19 pandemic, the U.S. government implemented travel bans and suspended visa issuance for certain countries. These measures led to significant delays and obstacles for visa seekers, preventing many from entering the U.S. for employment or other purposes.",
        sentiment: "Negative",
        source:
          "https://www.cdc.gov/coronavirus/2019-ncov/travelers/from-other-countries.html",
      },
      event2: {
        summary: "H-1B Wage Rule Proposal",
        detail:
          "The Department of Labor proposed a rule to increase prevailing wage levels for H-1B workers. The intention was to protect U.S. workers by ensuring that foreign workers were not hired at lower wages. However, this proposal raised concerns among employers and visa seekers about increased labor costs and potential challenges in hiring foreign talent.",
        sentiment: "Negative",
        source: "https://www.dol.gov/newsroom/releases/eta/eta20201006",
      },
    },
  },
  2021: {
    events: {
      event1: {
        summary: "Rescission of H-1B Lottery Rule Change",
        detail:
          "The Biden administration withdrew a Trump-era rule that aimed to prioritize H-1B cap selection based on wage levels rather than the existing random lottery system. This decision maintained the status quo, ensuring that all applicants had an equal chance of selection, regardless of wage levels.",
        sentiment: "Positive",
        source:
          "https://www.uscis.gov/news/news-releases/uscis-announces-h-1b-lottery-rule-finalization",
      },
      event2: {
        summary: "EB-1 Visa Availability",
        detail:
          "Due to a decrease in family-based immigration during the pandemic, unused visa numbers were reallocated to employment-based categories, including EB-1. This reallocation increased the number of available EB-1 visas, reducing wait times for applicants and benefiting those seeking employment-based permanent residency.",
        sentiment: "Positive",
        source:
          "https://www.uscis.gov/green-card/green-card-eligibility/green-card-for-employment-based-immigrants",
      },
    },
  },
  2022: {
    events: {
      event1: {
        summary: "H-1B Cap Registration Fee Increase Proposal",
        detail:
          "USCIS proposed increasing the H-1B registration fee from $10 to $215. The agency cited the need to recover operational costs and improve services. However, this significant fee hike raised concerns among employers and visa seekers about the increased financial burden associated with the H-1B application process.",
        sentiment: "Negative",
        source:
          "https://www.uscis.gov/news/news-releases/uscis-proposes-new-fee-rule-to-help-recover-operating-costs",
      },
      event2: {
        summary: "EB-1 Retrogression",
        detail:
          "High demand for EB-1 visas, particularly from applicants from certain countries, led to retrogression in the visa bulletin. This meant that applicants faced longer wait times for visa availability, delaying their ability to obtain permanent residency.",
        sentiment: "Negative",
        source:
          "https://travel.state.gov/content/travel/en/legal/visa-law0/visa-bulletin.html",
      },
    },
  },
  2023: {
    events: {
      event1: {
        summary: "H-1B Program Modernization Proposal",
        detail:
          "In October, the Department of Homeland Security proposed changes to modernize the H-1B program. The proposed rule aimed to revise the definition of 'specialty occupation' to provide clearer guidelines, improve program efficiency, and strengthen integrity measures to prevent fraud and misuse.",
        sentiment: "Mixed",
        source:
          "https://www.uscis.gov/newsroom/news-releases/dhs-issues-proposed-rule-to-modernize-the-h-1b-specialty-occupation-worker-program",
      },
      event2: {
        summary: "H-1B Fee Increases",
        detail:
          "USCIS announced significant fee increases for H-1B petitions, raising the base filing fee from $460 to $780, effective April 2024. The agency stated that the fee hike was necessary to cover operational costs. However, this increase was viewed negatively by employers and visa seekers due to the higher costs associated with the application process.",
        sentiment: "Negative",
        source:
          "https://www.natlawreview.com/article/employers-take-note-big-changes-ahead-h-1b-lottery-h-1b-filing-process-and-uscis",
      },
    },
  },
  2024: {
    events: {
      event1: {
        summary: "H-1B Lottery System Changes",
        detail:
          "USCIS implemented a beneficiary-centric selection process to combat fraud in the H-1B lottery system. This change allowed only one registration per beneficiary, regardless of the number of job offers received. The measure aimed to ensure a fairer selection process and prevent individuals from submitting multiple registrations to increase their chances.",
        sentiment: "Positive",
        source: "https://apnews.com/article/96717f789e7e4827a94dea6d054a0aee",
      },
      event2: {
        summary: "EB-1 Visa Demand",
        detail:
          "Continued high demand in the EB-1 category, especially from applicants from certain countries, led to longer wait times for visa availability. This increased demand resulted in delays for individuals seeking employment-based permanent residency under the EB-1 category.",
        sentiment: "Negative",
        source:
          "https://www.uscis.gov/green-card/green-card-eligibility/green-card-for-employment-based-immigrants",
      },
    },
  },
};
