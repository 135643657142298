import React from "react";
import { Option, Select } from "@material-tailwind/react";
import {
  jobCommitments,
  jobIndustries,
  salaryLimit,
} from "../../../../Utils/constants";
import Autocomplete from "../../../BannerHome/Location2";

const JobPreferences = ({ formData, handleChange }) => {
  return (
    <div>
      <h2 className="text-xl font-bold mb-4">Job Preferences</h2>
      <div className="flex flex-col md:flex-row gap-4">
        <div className="mb-4 w-full space-y-2">
          <label className="text-base font-normal text-gray-800">
            Preferred Job Titles
          </label>
          <input
            type="text"
            name="preferred_job_titles"
            value={formData.preferred_job_titles}
            onChange={(e) =>
              handleChange(e.target.value, "preferred_job_titles")
            }
            required
            placeholder="Enter your preferred job titles"
            className="w-full border  border-gray-600 rounded p-2 focus:outline-none focus:border-blue-500 focus:ring-blue-600 "
          />
        </div>
        <div className="relative mb-4 w-full">
          <label className="text-base font-normal text-gray-800">
            Preferred Industry
          </label>
          <Select
            variant="static"
            value={formData.preferred_industries}
            onChange={(value) => handleChange(value, "preferred_industries")}
            placeholder="Select Industry"
            className="jobDurationList pt-1"
            css={{
              "& .MuiSelect-outlined.MuiSelect-outlined": {
                borderBottom: "none",
              },
            }}
          >
            {jobIndustries?.map((field, index) => (
              <Option value={field?.value} key={index}>
                {field?.label}
              </Option>
            ))}
          </Select>
        </div>
      </div>
      <div className="flex flex-col md:flex-row gap-4">
        <div className="mb-4 w-full space-y-2">
          <label className="text-base font-normal text-gray-800">
            Preferred Locations
          </label>
          <Autocomplete
            isAutoApply
            onLocationChange={(value) => {
              handleChange(value, "preffered_locations");
            }}
            fetchResult={() => {}}
            defaultValue={""}
          />
        </div>
        <div className="mb-4 w-full space-y-2">
          <label className="text-base font-normal text-gray-800">
            Preferred Job Types
          </label>
          <Select
            variant="static"
            value={formData.preferred_job_types}
            onChange={(value) => handleChange(value, "preferred_job_types")}
            className="jobDurationList pt-1"
            placeholder="Select Job Type"
            css={{
              "& .MuiSelect-outlined.MuiSelect-outlined": {
                borderBottom: "none",
              },
            }}
          >
            {jobCommitments?.map((field, index) => (
              <Option value={field?.value} key={index}>
                {field?.label}
              </Option>
            ))}
          </Select>
        </div>
      </div>
      <div className="mb-4">
        <label className="text-base font-normal text-gray-800">
          Salary Expectations
        </label>
        <Select
          variant="static"
          value={formData.minimum_salary}
          onChange={(value) => handleChange(value, "minimum_salary")}
          className="jobDurationList pt-1"
          placeholder="Select Salary"
          css={{
            "& .MuiSelect-outlined.MuiSelect-outlined": {
              borderBottom: "none",
            },
          }}
        >
          {salaryLimit?.map((field, index) => (
            <Option value={field?.value} key={index}>
              {field?.label}
            </Option>
          ))}
        </Select>
      </div>
    </div>
  );
};

export default JobPreferences;
