import React from "react";
import { Box, Typography, SvgIcon } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import InfoIcon from "@mui/icons-material/Info";

const GoldBanner = () => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        backgroundColor: "#4d94ff",
        color: "black",
        padding: "10px",
        margin: "16px 0",
        borderRadius: "10px",
      }}
    >
      <SvgIcon sx={{ color: "#00C853", marginRight: "8px" }}>
        <InfoIcon />
      </SvgIcon>
      <Typography variant="body1">
        You don't need to apply to any jobs yourself. We can handle everything
        from preparing your resume, finding the best jobs for you, and
        submitting applications, ensuring you stand out among applicants.{" "}
        <RouterLink
          to="/app/auto-apply-faq#auto-apply-section"
          style={{ color: "inherit", textDecoration: "underline" }}
        >
          Learn more.
        </RouterLink>
      </Typography>
    </Box>
  );
};

export default GoldBanner;
