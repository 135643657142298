import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import PaymentForm from "./checkoutForm";
import ProgressBar from "./progressbar";

const AutoApply = () => {
  const location = useLocation();
  const { state, search } = location;
  const { price, jobs, payment_duration, plan_category } = state || {
    price: 0,
    jobs: 0,
    payment_duration: "",
    plan_category: "",
  };

  const [currentStep, setCurrentStep] = useState(1); // State for current step

  useEffect(() => {
    if (search) toast.success("Form has been submitted!");
  }, [search]);

  const handleStepChange = (step) => {
    setCurrentStep(step);
  };

  return (
    <div className="container mx-auto p-4">
      {/* Render the progress bar */}
      <ProgressBar currentStep={currentStep} />

      {currentStep === 1 && (
        <div>
          {/* Payment Step */}
          <PaymentForm
            type={price == 60 || price == 100 ? "subscribe" : "one-time"}
            jobs={jobs}
            paymentDuration={payment_duration}
            amount={price}
            onPaymentSuccess={(paymentIntent, subscription_id) =>
              handleStepChange(2)
            }
            plan_category={plan_category}
            isHome={true}
          />
        </div>
      )}
    </div>
  );
};

export default AutoApply;
