import {
  removeAllJobByUserId,
  removeJobToSupabase,
  saveJobToSupabase,
} from "../../components/JobCard/utils";
import { clearCartData, updateJobsCart } from "../reducers/cart";

export const addJobInCartAction = async (payload, dispatch, authReducer) => {
  let data = JSON.parse(localStorage.getItem("cart")) || [];
  data.push(payload);
  localStorage.setItem("cart", JSON.stringify(data));
  if (authReducer?.user && authReducer?.user?.id) {
    await saveJobToSupabase(payload);
  }
  await dispatch(updateJobsCart(data));
};

export const removeJobInCartAction = async (jobId, dispatch, authReducer) => {
  let data = JSON.parse(localStorage.getItem("cart")) || [];
  const updatedData = data?.filter((job) => job?.job_id != jobId);
  localStorage.setItem("cart", JSON.stringify(updatedData));
  if (authReducer?.user && authReducer?.user?.id)
    await removeJobToSupabase(jobId, authReducer?.user?.id);

  await dispatch(updateJobsCart(updatedData));
};

export const onLoadJobs = async (dispatch) => {
  let data = JSON.parse(localStorage.getItem("cart")) || [];
  if (data?.length) await dispatch(updateJobsCart(data));
};

export const jobsFromSupabase = async (data, dispatch) => {
  if (data?.length) await dispatch(updateJobsCart(data));
};

export const clearCartAction = async (userId, dispatch) => {
  localStorage.setItem("cart", JSON.stringify([]));
  if (userId) await removeAllJobByUserId(userId);
  await dispatch(clearCartData());
};
