import React, { forwardRef } from "react";
import styled from "styled-components";
import Logo from "../../assets/icons/logo.png";

const ReceiptContainer = styled.div`
  width: 100%;
  padding: 20px;
  font-family: "Courier New", Courier, monospace; /* Use a receipt-like font */
  text-align: center; /* Center align all text */
`;

const CompanyLogo = styled.img`
  display: block;
  margin: 0 auto 20px;
  width: 40%; /* Make the image fit the container width */
  height: auto;
  border-radius: 10px;
`;

const Title = styled.h1`
  font-size: 24px;
  margin-bottom: 20px;
`;

const Info = styled.div`
  margin-bottom: 10px;
`;

const InstructionTitle = styled.h2`
  font-size: 20px;
  margin-top: 20px;
  margin-bottom: 10px;
`;

const InstructionText = styled.p`
  font-size: 16px;
  margin-bottom: 10px;
`;

const Receipt = forwardRef(({ formData, amount, paymentMethod }, ref) => (
  <ReceiptContainer ref={ref}>
    <CompanyLogo src={Logo} alt="Company Logo" />
    <Title>Receipt</Title>
    <Info>
      <strong>Amount Paid:</strong> ${amount}
    </Info>
    <Info>
      <strong>Email:</strong> {formData.email}
    </Info>
    <Info>
      <strong>Job Titles:</strong> {formData.jobTitles}
    </Info>
    <Info>
      <strong>Preferred Industries:</strong> {formData.preferred_industries}
    </Info>
    <Info>
      <strong>Preferred Job Titles:</strong> {formData.preferred_job_titles}
    </Info>
    <Info>
      <strong>Preferred Locations:</strong> {formData.preffered_locations}
    </Info>
    <Info>
      <strong>Preferred Job Types:</strong> {formData.preferred_job_types}
    </Info>
    <Info>
      <strong>Minimum Salary:</strong> {formData.minimum_salary}
    </Info>
    <Info>
      <strong>Additional Information:</strong> {formData.additionalInformation}
    </Info>
    <Info>
      <strong>Number of Jobs:</strong> {formData.number_of_jobs}
    </Info>
    <Info>
      <strong>Payment Method:</strong> {paymentMethod}
    </Info>

    <InstructionTitle>
      <strong>How to Monitor Your Application</strong>
    </InstructionTitle>
    <InstructionText>
      Go to your profile and click on "Auto-Applied Jobs".
    </InstructionText>
    <InstructionText>
      Please wait at least 24 hours before the jobs start showing up.
    </InstructionText>
  </ReceiptContainer>
));

export default Receipt;
