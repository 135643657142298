import React from "react";

const Search = ({
  query,
  onInputChange,
  results,
  isLoading,
  onSelectCompany,
  containerClassName,
  year,
  onYearChange,
}) => {
  const currentYear = new Date().getFullYear();
  const startYear = 2019;
  const colorStyle = {
    outline: "2px solid rgb(22, 78, 212)",
    color: "rgb(22, 78, 212)",
  };

  return (
    <div
      className={`relative flex items-center gap-4 border rounded-lg bg-white py-3 px-3 ${containerClassName}`}
    >
      <svg
        width="28"
        height="28"
        viewBox="0 0 28 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M25.1184 23.8817L20.6372 19.4005C22.1352 17.6237 23.0417 15.3335 23.0417 12.8333C23.0417 7.20417 18.4625 2.625 12.8333 2.625C7.20417 2.625 2.625 7.20417 2.625 12.8333C2.625 18.4625 7.20417 23.0417 12.8333 23.0417C15.3335 23.0417 17.6237 22.1352 19.4005 20.6372L23.8816 25.1183C24.052 25.2887 24.276 25.375 24.5 25.375C24.724 25.375 24.948 25.2898 25.1184 25.1183C25.4602 24.7777 25.4602 24.2235 25.1184 23.8817ZM4.375 12.8333C4.375 8.169 8.169 4.375 12.8333 4.375C17.4977 4.375 21.2917 8.169 21.2917 12.8333C21.2917 17.4977 17.4977 21.2917 12.8333 21.2917C8.169 21.2917 4.375 17.4977 4.375 12.8333Z"
          fill="#4A4C56"
        />
      </svg>
      <input
        type="text"
        placeholder="Start typing to search for a company"
        className="focus:outline-none w-full bg-transparent"
        value={query}
        onChange={onInputChange}
      />
      <select
        name="year"
        id="year"
        className="text-base font-semibold border rounded-full  p-2 focus:outline-none px-8 text-primary border-primary"
        // style={colorStyle}
        value={year}
        onChange={(e) => onYearChange(e.target.value)}
      >
        {Array.from({ length: currentYear - startYear + 1 }, (_, i) => (
          <option key={startYear + i} value={startYear + i}>
            {startYear + i}
          </option>
        ))}
      </select>
      {isLoading && (
        <div className="absolute top-full left-0 w-full bg-white border mt-1 p-2 z-50">
          Loading...
        </div>
      )}
      {results.length > 0 && (
        <ul className="absolute top-full left-0 w-full bg-white border mt-1 max-h-60 overflow-y-auto z-50">
          {results.map((company) => (
            <li
              key={company.id}
              className="p-2 hover:bg-gray-200 cursor-pointer"
              onClick={() => onSelectCompany(company)}
            >
              {company.name}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Search;
