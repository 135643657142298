import React, { useEffect, useRef, useState } from "react";
import { Button, Spinner, Typography } from "@material-tailwind/react";
import Bookmark from "../../assets/icons/bookmark.png";
import "./jobDetails.css";
import { getSelectedJob, saveJob } from "../../services/auth";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { fetchJobById, SaveJobOnBackend } from "../../services/jobs";
import GoldBanner from "../InfoCard/GoldBanner";

const JobDetails = ({
  open,
  handleClose,
  data,
  jobId,
  isInCart,
  isSaved = false,
}) => {
  const cleanJobDescription = (data?.job_description || "")
    .replace(/[`'()]/g, "")
    .trim();

  let alreadySaved = useRef(false);

  const authState = useSelector((state) => state.auth);

  const [jobData, setJobData] = useState({});

  useEffect(() => {
    if (open) {
      fetchSelectedJobFromSupabase();
    }
  }, [open]);

  useEffect(() => {
    if (open && jobId) {
      fetchJobDataById(jobId);
    } else if (open && data) {
      setJobData(formatJobData(data));
    }
  }, [open, jobId, data]);

  const fetchJobDataById = async (id) => {
    const response = await fetchJobById(id);
    console.log(response);
    if (response?.status === 200) {
      setJobData(formatJobData(response?.data));
    } else {
      toast.error("Failed to load job details");
    }
  };

  const fetchSelectedJobFromSupabase = async () => {
    if (!jobData?.id) return;
    const response = await getSelectedJob({
      job_id: jobData.job_id,
      user_id: authState.user?.id,
    });
    if (response?.length) alreadySaved.current = true;
  };

  const formatJobData = (job) => ({
    ...job,
    location: [job?.job_city, job?.job_state, job?.job_country ?? ""]
      .filter(Boolean)
      .join(", "),
  });

  const handleSaveJob = async (job) => {
    if (alreadySaved.current) {
      toast.info("Job has already been saved!");
      return;
    }
    const payload = {
      ...job,
      default_id: job?.id,
      user_id: authState.user?.id,
    };
    delete payload["id"];
    const response = await saveJob(payload);
    if (response?.code && authState.user?.id) toast.error(response?.message);
    else if (response?.code && !authState.user?.id) {
      toast.error("Log in to save this job");
    } else {
      SaveJobOnBackend(job?.job_id);
      toast.success("Job has been saved!");
      alreadySaved.current = true;
    }
  };

  return (
    <>
      {open && <div className="jobDetailsOverlay" onClick={handleClose} />}
      {open && (
        <div className="jobDetailsCard">
          <ToastContainer />
          <div className="jobDetailsCardHeader justify-end">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="h-5 w-5"
              onClick={handleClose}
            >
              <path
                fillRule="evenodd"
                d="M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z"
                clipRule="evenodd"
              />
            </svg>
          </div>
          {Object.keys(jobData)?.length > 0 ? (
            <div className="jobDetailsCardBody">
              <div className="h-[80vh] overflow-scroll px-4 md:px-10 job-detail-container pb-4 md:pb-10">
                <div className="flex items-center justify-between mb-4 md:mb-8">
                  <div className="w-full">
                    <div className="flex items-center justify-between">
                      <Typography variant="h5" className="jobDetail-title">
                        {jobData?.job_name}
                      </Typography>
                      {!isSaved && (
                        <img
                          src={Bookmark}
                          alt="bookmark"
                          width={35}
                          className="cursor-pointer"
                          onClick={() => handleSaveJob(jobData)}
                        />
                      )}
                    </div>
                  </div>
                </div>

                <div className="mb-4 md:mb-8 mt-8">
                  {jobData?.min_salary !== null &&
                  jobData?.max_salary !== null ? (
                    <Typography>
                      💵 Salary:{" "}
                      <span>
                        ${jobData.min_salary} - ${jobData.max_salary}
                      </span>
                    </Typography>
                  ) : (
                    <>
                      {jobData?.min_salary !== null && (
                        <Typography>
                          💵 Min Salary: <span>${jobData.min_salary}</span>
                        </Typography>
                      )}
                      {jobData?.max_salary !== null && (
                        <Typography>
                          💵 Max Salary: <span>${jobData.max_salary}</span>
                        </Typography>
                      )}
                    </>
                  )}
                </div>

                <div className="mb-4 md:mb-8 mt-8">
                  <Typography>
                    🏢 Office Location: {jobData?.location}
                  </Typography>
                </div>

                <div className="mb-4 md:mb-8 mt-8">
                  <Typography>🧳 Career Level: {jobData?.job_yoe}</Typography>
                </div>

                <div className="mb-4 md:mb-8 mt-8">
                  <Typography>
                    🖖 Job Category: {jobData?.job_general_category}
                  </Typography>
                </div>

                <div className="flex flex-col gap-4">
                  <div>
                    <div className="jobDetail-heading mb-4 md:mb-8 mt-8">
                      Job Description
                    </div>
                    <Typography>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: jobData?.job_description ?? "",
                        }}
                      ></div>
                    </Typography>
                  </div>
                </div>

                {!isInCart && (
                  <>
                    <div className="flex justify-center mt-6">
                      <button
                        className="relative flex justify-center items-center gap-[8px] select-none rounded-lg bg-[#164ED4] p-[16px] max-w-[222px] w-full font-sans text-xs font-bold uppercase text-white shadow-md shadow-blue-500/20 transition-all hover:shadow-lg hover:shadow-blue-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                        type="button"
                        onClick={() => {
                          if (jobData?.job_apply_link) {
                            window.open(jobData?.job_apply_link, "_blank");
                          }
                        }}
                      >
                        Apply
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="21"
                          height="20"
                          viewBox="0 0 21 20"
                          fill="none"
                        >
                          <path
                            d="M18.5882 10.59L12.7557 16.4226C12.5932 16.5851 12.3798 16.6667 12.1665 16.6667C11.9532 16.6667 11.7398 16.5851 11.5773 16.4226C11.2515 16.0967 11.2515 15.57 11.5773 15.2442L15.9881 10.8334H2.99984C2.539 10.8334 2.1665 10.46 2.1665 10C2.1665 9.54003 2.539 9.1667 2.99984 9.1667H15.9881L11.5773 4.75589C11.2515 4.43006 11.2515 3.90334 11.5773 3.5775C11.9031 3.25167 12.4299 3.25167 12.7557 3.5775L18.5882 9.41002C18.6657 9.48752 18.7266 9.57913 18.7691 9.68163C18.8532 9.88496 18.8532 10.1151 18.7691 10.3184C18.7266 10.4209 18.6657 10.5125 18.5882 10.59Z"
                            fill="white"
                          />
                        </svg>
                      </button>
                    </div>
                    <GoldBanner />
                  </>
                )}
              </div>
            </div>
          ) : (
            <div className="flex items-center justify-center h-full">
              <Spinner className="h-12 w-12" color="indigo" />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default JobDetails;
