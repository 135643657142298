// OneMonthSubscriptionPlan.js
import React from "react";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Typography,
} from "@material-tailwind/react";
import CheckIcon from "@mui/icons-material/CheckCircle";
import "./index.css";
import bannerImg from "../../../../assets/images/banner-background-image.png";

const OneMonthSubscriptionPlan = () => {
  return (
    <div
      className="bannerHome py-[40px] md:py-[80px]"
      style={{
        backgroundImage: `url(${bannerImg})`,
        backgroundSize: "cover",
      }}
    >
      <div className="mx-auto max-w-[1320px] space-y-[10px] md:py-[10px] w-full">
        <div className="heading text-center">
          <Typography
            variant="h1"
            className="text-2xl md:text-4xl lg:text-5xl leading-tight md:leading-tight lg:leading-tight text-[#1D1F2C]"
          >
            <span className="text-[#164ED4]">
              {" "}
              Our One Month Subscription Plan
            </span>
          </Typography>
        </div>
        <Typography
          variant="h5"
          className="text-center text-lg md:text-xl lg:text-2xl text-[#1D1F2C] mt-4"
        >
          Choose the plan that fits your needs and let us handle the job
          applications for you.
        </Typography>
        <div className="grid grid-cols-1 md:grid-cols-3 items-center gap-4 pt-10">
          <Card
            color="white"
            variant="gradient"
            className="w-full max-w-[20rem] p-8 "
          >
            <CardHeader
              floated={false}
              shadow={false}
              color="transparent"
              className="m-0 rounded-none border-b border-white/10 pb-8 text-center"
            >
              <Typography
                variant="small"
                color="black"
                className="font-normal uppercase"
              >
                One Time
              </Typography>
              <Typography
                variant="h1"
                color="black"
                className="mt-6 flex justify-center gap-1 text-5xl font-normal"
              >
                <span className="mt-2 text-3xl">$</span>29.99{" "}
                <span className="self-end text-base">/one time</span>
              </Typography>
            </CardHeader>
            <CardBody className="p-0">
              <ul className="flex flex-col gap-4">
                <li className="flex items-center gap-4">
                  <span className="rounded-full border border-black/20 bg-black/20 p-1">
                    <CheckIcon />
                  </span>
                  <Typography className="font-normal">
                    5 job applications
                  </Typography>
                </li>
                <li className="flex items-center gap-4">
                  <span className="rounded-full border border-black/20 bg-black/20 p-1">
                    <CheckIcon />
                  </span>
                  <Typography className="font-normal">
                    Basic application optimization
                  </Typography>
                </li>
                <li className="flex items-center gap-4">
                  <span className="rounded-full border border-black/20 bg-black/20 p-1">
                    <CheckIcon />
                  </span>
                  <Typography className="font-normal">
                    Standard support
                  </Typography>
                </li>
              </ul>
            </CardBody>
            <CardFooter className="mt-12 p-0">
              <Button
                size="lg"
                color="black"
                className="hover:scale-[1.02] focus:scale-[1.02] active:scale-100"
                ripple={false}
                fullWidth={true}
              >
                Buy Now
              </Button>
            </CardFooter>
          </Card>
          <Card
            color="white"
            variant="gradient"
            className="w-full max-w-[20rem] p-8 "
          >
            <CardHeader
              floated={false}
              shadow={false}
              color="transparent"
              className="m-0 rounded-none border-b border-white/10 pb-8 text-center"
            >
              <Typography
                variant="small"
                color="black"
                className="font-normal uppercase"
              >
                Monthly Subscription
              </Typography>
              <Typography
                variant="h1"
                color="black"
                className="mt-6 flex justify-center gap-1 text-5xl font-normal"
              >
                <span className="mt-2 text-3xl">$</span>59.99{" "}
                <span className="self-end text-base">/month</span>
              </Typography>
            </CardHeader>
            <CardBody className="p-0">
              <ul className="flex flex-col gap-4">
                <li className="flex items-center gap-4">
                  <span className="rounded-full border border-black/20 bg-black/20 p-1">
                    <CheckIcon />
                  </span>
                  <Typography className="font-normal">
                    10 job applications
                  </Typography>
                </li>
                <li className="flex items-center gap-4">
                  <span className="rounded-full border border-black/20 bg-black/20 p-1">
                    <CheckIcon />
                  </span>
                  <Typography className="font-normal">
                    Advanced application optimization
                  </Typography>
                </li>
                <li className="flex items-center gap-4">
                  <span className="rounded-full border border-black/20 bg-black/20 p-1">
                    <CheckIcon />
                  </span>
                  <Typography className="font-normal">
                    Priority support
                  </Typography>
                </li>
              </ul>
            </CardBody>
            <CardFooter className="mt-12 p-0">
              <Button
                size="lg"
                color="black"
                className="hover:scale-[1.02] focus:scale-[1.02] active:scale-100"
                ripple={false}
                fullWidth={true}
              >
                Buy Now
              </Button>
            </CardFooter>
          </Card>
          <Card
            color="white"
            variant="gradient"
            className="w-full max-w-[20rem] p-8 "
          >
            <CardHeader
              floated={false}
              shadow={false}
              color="transparent"
              className="m-0 rounded-none border-b border-white/10 pb-8 text-center"
            >
              <Typography
                variant="small"
                color="black"
                className="font-normal uppercase"
              >
                3 month Subscription
              </Typography>
              <Typography
                variant="h1"
                color="black"
                className="mt-6 flex justify-center gap-1 text-5xl font-normal"
              >
                <span className="mt-2 text-3xl">$</span>99.99{" "}
                <span className="self-end text-base">/one time</span>
              </Typography>
            </CardHeader>
            <CardBody className="p-0">
              <ul className="flex flex-col gap-4">
                <li className="flex items-center gap-4">
                  <span className="rounded-full border border-black/20 bg-black/20 p-1">
                    <CheckIcon />
                  </span>
                  <Typography className="font-normal">
                    20 job applications
                  </Typography>
                </li>
                <li className="flex items-center gap-4">
                  <span className="rounded-full border border-black/20 bg-black/20 p-1">
                    <CheckIcon />
                  </span>
                  <Typography className="font-normal">
                    Comprehensive application optimization
                  </Typography>
                </li>
                <li className="flex items-center gap-4">
                  <span className="rounded-full border border-black/20 bg-black/20 p-1">
                    <CheckIcon />
                  </span>
                  <Typography className="font-normal">
                    24/7 premium support
                  </Typography>
                </li>
                <li className="flex items-center gap-4">
                  <span className="rounded-full border border-black/20 bg-black/20 p-1">
                    <CheckIcon />
                  </span>
                  <Typography className="font-normal">
                    Job research included
                  </Typography>
                </li>
              </ul>
            </CardBody>
            <CardFooter className="mt-12 p-0">
              <Button
                size="lg"
                color="black"
                className="hover:scale-[1.02] focus:scale-[1.02] active:scale-100"
                ripple={false}
                fullWidth={true}
              >
                Buy Now
              </Button>
            </CardFooter>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default OneMonthSubscriptionPlan;
