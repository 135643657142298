import React, { useState, useEffect } from "react";
import { Typography, TextField, Box, IconButton } from "@mui/material";
import ReplyIcon from "@mui/icons-material/Reply";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import {
  fetchComments,
  postComment,
  postReply,
  generateUsername,
  buildCommentTree,
} from "../../supabaseServices/sponsorcomments";
import { Button, iconButton, Textarea } from "@material-tailwind/react";

const CommentSection = ({ companyName, companyId }) => {
  const [comments, setComments] = useState([]);
  const [commentText, setCommentText] = useState("");
  const authState = useSelector((state) => state.auth);
  const [isLoading, setIsLoading] = useState(false);

  const loadComments = async () => {
    const data = await fetchComments(companyId); // Fetch all comments and replies

    if (data.length > 0) {
      const structuredData = buildCommentTree(data); // Structure comments with replies
      setComments(structuredData); // Set the structured comments in state
    }
  };

  useEffect(() => {
    setIsLoading(true);
    loadComments().finally(() => setIsLoading(false));
  }, [companyId]);

  const handlePostComment = async () => {
    if (!authState.user?.id) {
      toast.error("Log in to post a comment");
      return;
    }

    try {
      setIsLoading(true);

      const response = await postComment(companyId, commentText);

      if (response && response.length > 0) {
        const newComment = {
          id: response[0].id,
          comment_text: commentText,
          username: response[0].username || generateUsername(),
          replies: [],
        };

        setCommentText("");
        setComments((prevComments) => [newComment, ...prevComments]);

        toast.success("Comment posted successfully!");
      } else {
        throw new Error("Failed to post comment");
      }
    } catch (error) {
      toast.error("Failed to post comment.");
    } finally {
      setIsLoading(false);
    }
  };

  const handlePostReply = async (parentId, replyText) => {
    if (!authState.user?.id) {
      toast.error("Log in to reply to a comment");
      return;
    }

    try {
      const response = await postReply(companyId, parentId, replyText);

      if (response && response.length > 0) {
        const postedReply = response[0];

        const addReplyToTree = (comments) => {
          return comments.map((comment) => {
            const replies = Array.isArray(comment.replies)
              ? comment.replies
              : [];

            if (comment.id === parentId) {
              return {
                ...comment,
                replies: [...replies, postedReply],
              };
            } else if (replies.length > 0) {
              return {
                ...comment,
                replies: addReplyToTree(replies),
              };
            }
            return comment;
          });
        };

        setComments((prevComments) => addReplyToTree(prevComments));
      } else {
        toast.error("Failed to post reply.");
      }
    } catch (error) {
      toast.error("Failed to post reply.");
    }
  };

  return (
    <div className="w-full lg:w-3/4 mx-auto p-4">
      <h2 className=" text-xl font-bold mb-4">
        Discussion on {companyName}
        <span className="bg-primary text-white ms-2 py-0.5 px-6 rounded-full">
          {comments.length}{" "}
        </span>
      </h2>
      <div class="bg-white shadow-md rounded-lg p-6 my-4">
        <h5 class="text-lg font-semibold mb-4">Community Guidelines</h5>
        <p class="text-gray-700">
          We value open discussions and welcome your thoughts and experiences.
          Please be respectful to others, and keep your comments constructive
          and courteous. Let's work together to foster a positive and supportive
          community.
        </p>
      </div>

      {isLoading ? (
        <div>Loading comments...</div>
      ) : (
        <div className="space-y-4">
          {comments.map((comment) => (
            <Box
              key={comment.id}
              sx={{
                border: "1px solid #ddd",
                borderRadius: "8px",
                padding: "10px",
              }}
            >
              <Comment comment={comment} onReply={handlePostReply} />
            </Box>
          ))}
        </div>
      )}

      <div className="mt-6">
        <Textarea
          variant="outlined"
          rows={4}
          value={commentText}
          label="Share your experience anonymously... "
          className="h-52 resize-none"
          onChange={(e) => setCommentText(e.target.value)}
        />
        <Button
          onClick={handlePostComment}
          variant="filled"
          className="text-white bg-primary"
          loading={isLoading}
        >
          Post
        </Button>
      </div>
    </div>
  );
};

const Comment = ({ comment, onReply }) => {
  const [replyText, setReplyText] = useState("");
  const [showReplyInput, setShowReplyInput] = useState(false);
  const [showReplies, setShowReplies] = useState(false);

  const replies = Array.isArray(comment.replies) ? comment.replies : [];

  return (
    <div
      className="flex flex-col"
      style={{
        marginLeft: comment.parent_comment_id ? "15px" : "0px",
        paddingLeft: comment.parent_comment_id ? "10px" : "0px",
        maxWidth: "100%",
      }}
    >
      <div style={{ flexGrow: 1 }}>
        <div className="flex items-start gap-4">
          <AccountCircleIcon style={{ color: "gray" }} />
          <div className="space-y-2">
            <Typography
              color="black"
              sx={{
                fontSize: "1rem",
                fontWeight: "700",
                wordBreak: "break-word",
              }}
            >
              {comment.username}
            </Typography>

            <Typography
              variant="body1"
              style={{ marginBottom: "4px", wordBreak: "break-word" }}
            >
              {comment.comment_text}
            </Typography>
            <div className="flex items-center mt-1 space-x-2">
              <IconButton
                onClick={() => setShowReplyInput(!showReplyInput)}
                size="small"
                sx={{ padding: "4px", fontSize: "1rem" }}
              >
                <ReplyIcon sx={{ fontSize: "1.2rem" }} />
                <Typography sx={{ ml: 1, fontSize: "0.9rem" }}>
                  Reply
                </Typography>
              </IconButton>
            </div>

            {replies.length > 0 && (
              <Typography
                sx={{
                  mt: 1,
                  fontSize: "0.8rem",
                  color: "#555",
                  cursor: "pointer",
                }}
                onClick={() => setShowReplies(!showReplies)}
              >
                {showReplies
                  ? "Hide Replies"
                  : `Show ${replies.length} ${
                      replies.length === 1 ? "Reply" : "Replies"
                    }`}
              </Typography>
            )}

            {showReplies && (
              <div className="mt-2 space-y-2">
                {replies.map((reply) => (
                  <Comment key={reply.id} comment={reply} onReply={onReply} />
                ))}
              </div>
            )}

            {showReplyInput && (
              <div className="mt-2">
                <TextField
                  label="Reply..."
                  multiline
                  fullWidth
                  rows={2}
                  variant="outlined"
                  value={replyText}
                  onChange={(e) => setReplyText(e.target.value)}
                  sx={{ borderRadius: "20px" }}
                />
                <Button
                  onClick={() => {
                    onReply(comment.id, replyText);
                    setReplyText("");
                    setShowReplyInput(false);
                  }}
                  variant="contained"
                  sx={{
                    mt: 1,
                    backgroundColor: "#164ed4",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "#133cb3",
                    },
                  }}
                >
                  Post Reply
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommentSection;
