import React, { useState, useCallback, useEffect } from "react";
import Autosuggest from "react-autosuggest";
import debounce from "lodash.debounce";

const Autocomplete = ({
  isHome,
  isAutoApply,
  onLocationChange,
  fetchResult,
  defaultValue = "",
}) => {
  const [query, setQuery] = useState(defaultValue);
  const [suggestions, setSuggestions] = useState([]);

  const apiKey = "qiinmJma7I6hSSYnGEhLeGwo0WiuqTGZ";

  useEffect(() => {
    debouncedFetchSuggestions(query);
  }, [query]);

  const fetchSuggestions = async (input) => {
    if (input.length > 2) {
      try {
        const response = await fetch(
          `https://api.tomtom.com/search/2/search/${input}.json?key=${apiKey}&typeahead=true&limit=5&countrySet=US`
        );
        const data = await response.json();
        const newSuggestions = data.results.map((result) => {
          const { address } = result;
          const city =
            address.municipality ||
            address.municipalitySubdivision ||
            address.localName;
          const state = address.countrySubdivisionName; // Use `countrySubdivisionName` for the full state name
          return { name: `${city}, ${state}` };
        });
        setSuggestions(newSuggestions);
      } catch (error) {}
    } else {
      setSuggestions([]);
    }
  };

  const debouncedFetchSuggestions = useCallback(
    debounce((input) => {
      fetchSuggestions(input);
    }, 300),
    []
  );

  const handleSuggestionsFetchRequested = ({ value }) => {};

  const handleSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const handleChange = (event, { newValue }) => {
    setQuery(newValue);
    if (suggestions.find((suggest) => suggest.name == newValue))
      onLocationChange(newValue);
    else onLocationChange("");
  };

  const getSuggestionValue = (suggestion) => suggestion.name;

  const renderSuggestion = (suggestion) => (
    <div className="p-2 cursor-pointer hover:bg-gray-200">
      {suggestion.name}
    </div>
  );

  return (
    <div className={`w-[100%] ${isAutoApply ? "" : "md:w-[45%]"} relative`}>
      <div className="absolute left-0 md:left-[0.25rem] top-[50%] pr-40 translate-y-[-50%]">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="27"
          height="28"
          viewBox="0 0 28 29"
          fill="none"
        >
          <path
            d="M13.9998 3.125C8.37067 3.125 3.7915 7.70417 3.7915 13.3333C3.7915 19.3043 9.27133 22.9233 12.8973 25.3184L13.5145 25.728C13.6615 25.826 13.8307 25.875 13.9998 25.875C14.169 25.875 14.3382 25.826 14.4852 25.728L15.1023 25.3184C18.7283 22.9233 24.2082 19.3043 24.2082 13.3333C24.2082 7.70417 19.629 3.125 13.9998 3.125ZM14.1387 23.8578L13.9998 23.9501L13.861 23.8578C10.3493 21.5385 5.5415 18.3628 5.5415 13.3333C5.5415 8.669 9.3355 4.875 13.9998 4.875C18.6642 4.875 22.4582 8.669 22.4582 13.3333C22.4582 18.3628 17.6492 21.5397 14.1387 23.8578ZM13.9998 9.54167C11.9092 9.54167 10.2082 11.2427 10.2082 13.3333C10.2082 15.424 11.9092 17.125 13.9998 17.125C16.0905 17.125 17.7915 15.424 17.7915 13.3333C17.7915 11.2427 16.0905 9.54167 13.9998 9.54167ZM13.9998 15.375C12.874 15.375 11.9582 14.4592 11.9582 13.3333C11.9582 12.2075 12.874 11.2917 13.9998 11.2917C15.1257 11.2917 16.0415 12.2075 16.0415 13.3333C16.0415 14.4592 15.1257 15.375 13.9998 15.375Z"
            fill="#4A4C56"
          />
        </svg>
      </div>
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={handleSuggestionsFetchRequested}
        onSuggestionsClearRequested={handleSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={{
          placeholder: "Start typing a location...",
          value: query,
          onChange: handleChange,
          onKeyDown: (e) => e.code == "Enter" && fetchResult(),
          className: `w-[calc(100% - 1px)] p-2 outline-none ${
            isAutoApply ? "w-[100%]" : ""
          }`, // Adjusted width and left margin
        }}
        theme={{
          container: isHome
            ? "relative banner-input-content"
            : "relative banner-input-content pl-8",
          input: "w-[calc(100% - 1px)] p-2 outline-none", // Adjusted width and padding-left
          suggestionsContainer: isAutoApply
            ? "absolute ml-[-2rem] w-[100%] bg-white rounded mt-4 z-10"
            : "absolute ml-8 w-64 bg-white rounded mt-4 z-10", // Adjusted max-width
          inputFocused: "w-[calc(100% - 60px)] p-2 outline-none", // Ensured focus state also has no border
          suggestion: "p-2 text-sm cursor-pointer border-b border-gray-100", // Adjusted size and hover color, reduced border thickness
          suggestionsList: "m-0 p-0 list-none",
        }}
      />
    </div>
  );
};

export default Autocomplete;
