import React, { useEffect, useState } from "react";
import ProfileImg from "../../assets/images/profile.png";
import { Chip } from "@material-tailwind/react";
import { useSelector } from "react-redux";
import Avatar from "react-avatar";
import { fetchUserByEmail } from "../../services/auth";

const UserInfo = ({ icons, chips }) => {
  const authState = useSelector((state) => state.auth);
  const [userData, setUserData] = useState({});

  useEffect(() => {
    if (Object.keys(authState?.user)?.length) fetchUser();
  }, [authState.user]);

  const fetchUser = async () => {
    const response = await fetchUserByEmail(authState?.user?.email);
    if (response) setUserData({ ...response });
  };

  return (
    <div className="flex flex-col gap-10 md:flex-row md:gap-0 md:justify-between">
      <div>
        <div className="flex items-start flex-col sm:flex-row sm:items-center gap-8">
          {userData?.profile_image ? (
            <img
              src={userData?.profile_image}
              width={116}
              className="rounded-full"
            />
          ) : (
            <Avatar
              round={true}
              size="116"
              name={authState?.user?.email}
              className="cursor-pointer"
            />
          )}
          <div>
            {userData?.first_name && (
              <div className="profile-name">{`${userData?.first_name} ${userData?.last_name}`}</div>
            )}
            <div className="profile-title">{authState?.user?.email}</div>
          </div>
        </div>
      </div>
      {/* <div className="flex flex-col max-w-[239px] gap-8">
        <div>
          <div className="professional-skills mb-3">Professional Skills</div>
          <div className="flex flex-wrap gap-3">
            {chips.map((val, index) => (
              <Chip
                key={index}
                variant="ghost"
                value={val}
                className="rounded-[20px] bg-[#F6F8FA] w-fit"
              />
            ))}
          </div>
        </div>
        <div className="flex flex-col gap-3">
          <div className="software-skills">Software Skills</div>
          <div className="flex items-center gap-4">
            {icons.map((icon, index) => (
              <img src={icon} alt="icon" width={32} />
            ))}
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default UserInfo;
