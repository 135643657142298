import Supabase from "../Utils/supabase";

// Utility to sanitize input and prevent unsafe scripts
const sanitizeInput = (input) => {
  const element = document.createElement("div");
  element.innerText = input;
  return element.innerHTML;
};

// Function to save data into the Supabase database
export const saveReferralData = async (data, type) => {
  try {
    // Validate input data
    if (!data || typeof data !== "object")
      throw new Error("Invalid data format.");
    if (!["seekers", "referrers"].includes(type))
      throw new Error("Invalid type.");

    // Sanitize input fields
    const sanitizedData = Object.fromEntries(
      Object.entries(data).map(([key, value]) => [
        key,
        typeof value === "string" ? sanitizeInput(value) : value,
      ])
    );

    // If it's a seeker, upload the resume to the storage bucket
    if (type === "seekers" && sanitizedData.resume) {
      const { data: resumeData, error: resumeError } = await Supabase.storage
        .from("resumes")
        .upload(
          `${Math.floor(Math.random() * 1000000)}_${sanitizedData.resume.name}`,
          sanitizedData.resume
        );

      if (resumeError)
        throw new Error(`Resume upload failed: ${resumeError.message}`);
      sanitizedData.resume_url = resumeData?.path; // Save the resume URL for the database
      delete sanitizedData.resume; // Remove the raw file object
    }

    // Choose the table based on the type
    const table = type === "seekers" ? "referral_seekers" : "referrers";

    // Insert sanitized data into the database
    const { data: insertedData, error } = await Supabase.from(table).insert([
      sanitizedData,
    ]);

    if (error) throw new Error(`Database insert failed: ${error.message}`);

    return { success: true, data: insertedData };
  } catch (error) {
    console.error("Error saving data:", error.message);
    return { success: false, error: error.message };
  }
};
