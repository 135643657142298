import React, { useState } from "react";
import {
  Button,
  Box,
  Grid,
  Typography,
  DialogContent,
  Divider,
} from "@mui/material";

const InitialDialog = ({ type, handleType }) => {
  return (
    <div className="flex items-center justify-between gap-4 my-8">
      <div
        className="w-[calc(50%-16px)] relative border border-[#e0e0e0] rounded-[8px] p-8 text-center h-[300px] flex items-center justify-center flex-col cursor-pointer"
        onClick={() => handleType("one-time")}
      >
        <Typography variant="h6">One Time Payment</Typography>
        <Typography variant="body1">
          Make a single upfront payment without any commitment
        </Typography>

        <div className="absolute top-10 right-10">
          <label
            className="relative flex items-center cursor-pointer"
            htmlFor="blue-600"
          >
            <input
              name="color"
              type="radio"
              checked={type == "one-time"}
              className="peer h-6 w-6 cursor-pointer appearance-none rounded-full border border-[#c0c0c0] checked:border-blue-400 transition-all"
              id="blue-600"
            />
            <span className="absolute bg-blue-600 w-4 h-4 rounded-full opacity-0 peer-checked:opacity-100 transition-opacity duration-200 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"></span>
          </label>
        </div>
      </div>

      <div
        className="w-[calc(50%-16px)] relative border border-[#e0e0e0] rounded-[8px] p-8 text-center h-[300px] flex items-center justify-center flex-col cursor-pointer"
        onClick={() => handleType("subscribe")}
      >
        <Typography variant="h6">Subscribe</Typography>
        <Typography variant="body1">
          Choose a monthly payment plan or a 3-month payment plan for even
          greater savings.
        </Typography>
        <div className="absolute top-10 right-10">
          <label
            className="relative flex items-center cursor-pointer"
            htmlFor="blue-600"
          >
            <input
              name="color"
              type="radio"
              checked={type == "subscribe"}
              className="peer h-6 w-6 cursor-pointer appearance-none rounded-full border border-[#c0c0c0] checked:border-blue-400 transition-all"
              id="blue-600"
            />
            <span className="absolute bg-blue-600 w-4 h-4 rounded-full opacity-0 peer-checked:opacity-100 transition-opacity duration-200 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"></span>
          </label>
        </div>
      </div>
    </div>
  );
};

export default InitialDialog;
