import { createClient } from "@supabase/supabase-js";
import Supabase from "../../Utils/supabase";

// Save job to Supabase
export const saveJobToSupabase = async (job) => {
  const { data, error } = await Supabase.from("cart").insert([{ ...job }]);
};

export const saveAllJobsToSupabase = async (jobs) => {
  const { data, error } = await Supabase.from("cart").insert(jobs);
};

export const removeJobToSupabase = async (jobId, userId) => {
  const { data } = await Supabase.from("cart")
    .delete()
    .eq("user_id", userId)
    .eq("job_id", jobId);
  return data;
};

export const removeAllJobByUserId = async (userId) => {
  const { data } = await Supabase.from("cart").delete().eq("user_id", userId);
  return data;
};

// Get user from Supabase
export const getUserFromSupabase = async (userId) => {
  const { data, error } = await Supabase.from("users")
    .select("*")
    .eq("id", userId)
    .single();
  return data;
};

// Transfer jobs from local storage to Supabase
export const transferJobsToSupabase = async (userId) => {
  const jobs = JSON.parse(localStorage.getItem("cart")) || [];
  for (const job of jobs) {
    await saveJobToSupabase(job, userId);
  }
  localStorage.removeItem("cart");
};

// Save job to local storage
export const saveJobToLocal = (job) => {
  const cart = JSON.parse(localStorage.getItem("cart")) || [];
  cart.push(job);
  localStorage.setItem("cart", JSON.stringify(cart));
};

// Check if job is already in local storage
export const isJobInLocal = (jobId) => {
  const cart = JSON.parse(localStorage.getItem("cart")) || [];
  return cart.some((job) => job.id === jobId);
};

export const removeJobFromLocal = (jobId) => {
  let cart = JSON.parse(localStorage.getItem("cart")) || [];
  cart = cart.filter((job) => job.id !== jobId);
  localStorage.setItem("cart", JSON.stringify(cart));
};
