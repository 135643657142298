import React from "react";
import { ReactComponent as Collaboration } from "../../../../../src/assets/images/collaboration.svg";
import { Typography } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import Heading from "../../../CustomComponents/Heading";
const HomePage = () => {
  return (
    <div className="h-auto lg:min-h-[50vh] bg-gray-100 flex flex-col lg:flex-row items-center p-4 lg:px-16 lg:py-8 mt-24 sm:mt-20">
      {/* Left Section */}
      <div className="lg:w-1/2 flex-shrink-0 text-center lg:text-left relative">
        <div className="max-w-md mx-auto p-4 text-left">
          <Heading
            text="Stop Worrying About Job Applications — "
            highlightedText="We’ve Got You Covered"
            highlightClass="text-[#164ED4]"
            containerClass="my-4"
          />
        </div>

        {/* Image displayed only on smaller screens */}
        <div className="my-4 flex justify-center lg:hidden">
          <Collaboration />
        </div>

        <div className="max-w-md mx-auto p-4 text-left">
          <Typography variant="body1" className="mb-5 mt-6 ">
            VisJobs will apply to jobs on your behalf, tailored to your
            preferences, so you don't have to lift a finger. Our service
            guarantees:
          </Typography>

          <div className="max-w-md mx-auto p-4">
            <ul className="list-none">
              <li className="mb-2 flex items-start text-lg font-medium text-gray-900">
                <CheckCircleOutlineIcon className="text-[#164ed4] mr-2 mt-1" />
                <Typography variant="body1" className="mb-0">
                  Higher Chances of Interviews
                </Typography>
              </li>
              <li className="mb-2 flex items-start text-lg font-medium text-gray-900">
                <CheckCircleOutlineIcon className="text-[#164ed4] mr-2 mt-1" />
                <Typography variant="body1" className="mb-0">
                  Optimized Applications to Beat ATS Filters
                </Typography>
              </li>
              <li className="mb-2 flex items-start text-lg font-medium text-gray-900">
                <CheckCircleOutlineIcon className="text-[#164ed4] mr-2 mt-1" />
                <Typography variant="body1" className="mb-0">
                  Significant Effort and Time Savings
                </Typography>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* Right Section */}
      <div className="lg:w-1/2 flex-shrink-0 flex justify-center items-center h-auto max-h-[400px]">
        <Collaboration />
      </div>
    </div>
  );
};

export default HomePage;
