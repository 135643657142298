import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Typography,
} from "@material-tailwind/react";

const DescriptionCard = ({ companyInfo }) => {
  return (
    <Card className="p-6 border border-[#E9E9EA] space-y-4 rounded-3xl lg:w-1/3 h-96 overflow-hidden flex flex-col">
      <CardHeader className="shadow-none m-0 flex justify-between items-center">
        <p className="text-lg font-bold capitalize text-black">
          {`About ${companyInfo ? companyInfo.name : "Google LLC"}`}
        </p>
      </CardHeader>
      <CardBody className="p-0 space-y-4 py-4 overflow-auto flex-grow">
        <p className="text-lg font-bold capitalize text-black">{` `}</p>
        <Typography variant="h6">
          {companyInfo
            ? companyInfo.name === "Google LLC"
              ? "Google LLC is an American multinational corporation and technology company focusing on online advertising, search engine technology, cloud computing, computer software, quantum computing, e-commerce, consumer electronics, and artificial intelligence (AI)."
              : companyInfo.company_description ||
                "Company description not found"
            : "Company description not found"}
        </Typography>
      </CardBody>
    </Card>
  );
};

export default DescriptionCard;
