import { baseUrl, Token } from "../Utils/constants";
import CallApi from "../Utils/callApi";

import axios from "axios";

export const sendMessage = async (body) => {
  try {
    const url = `${baseUrl}/contact`;
    const response = await axios.post(url, body, {
      headers: {
        Authorization: `Token ${Token}`,
        "Content-Type": "application/json",
      },
    });

    return response;
  } catch (error) {
    console.error("Error in sendMessage:", error.response?.data || error);
    throw error;
  }
};
