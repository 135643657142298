import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import ReactMarkdown from "react-markdown";
import { Helmet } from "react-helmet"; // Import React Helmet
import SocialSidebar from "./SocialSidebar"; // Import the SocialSidebar component
import { Skeleton } from "@mui/material";
import "./BlogView.css"; // Custom CSS for the blog view
import { Typography, Box } from "@mui/material";

const BlogView = () => {
  const { id } = useParams();
  const [blog, setBlog] = useState(null);

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const response = await axios.get(
          `https://kazisai.us/api/blogs/${id}?populate=*`
        );
        setBlog(response.data.data);
      } catch (error) {
        console.error("Error fetching blog", error);
      }
    };

    fetchBlog();
  }, [id]);

  if (!blog) {
    return (
      <div className="blog-view-wrapper">
        {/* Skeleton for loading state */}
        <div className="blog-header">
          <Skeleton variant="text" width="60%" height={40} />
          <Skeleton variant="text" width="40%" height={20} />
        </div>
        <div className="image-sidebar-container">
          <Skeleton variant="rectangular" width="100%" height={300} />
        </div>
        <div className="blog-content">
          <Skeleton variant="text" width="100%" height={30} />
        </div>
      </div>
    );
  }

  const coverImage =
    blog && blog.CoverImage && blog.CoverImage[0]?.url
      ? `https://kazisai.us${blog.CoverImage[0].url}`
      : blog.MetaTitle
      ? blog.MetaTitle
      : "/path/to/default-image.jpg";

  const shareUrl = `https://www.visjobs.com/app/news/${id}`;
  return (
    <div className="blog-view-wrapper">
      {/* Open Graph Metadata */}
      <Helmet>
        {/* Open Graph Tags */}
        <meta property="og:title" content={blog.Title} />
        <meta property="og:description" content={blog.MetaDescription} />
        <meta property="og:image" content={coverImage} />
        <meta property="og:url" content={shareUrl} />
        <meta property="og:type" content="article" />

        {/* Twitter Card Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={blog.Title} />
        <meta name="twitter:description" content={blog.MetaDescription} />
        <meta name="twitter:image" content={coverImage} />
      </Helmet>

      {/* Blog Content */}
      <div className="blog-header mb-8">
        <h1 className="blog-title font-nytimes text-4xl font-bold leading-tight">
          {blog.Title}
        </h1>
        <p className="blog-author text-lg italic text-gray-600 mt-2">
          <span className="highlight font-semibold">
            {blog.Author || "Unknown Author"}
          </span>{" "}
          / {new Date(blog.publishedAt).toLocaleTimeString()} •{" "}
          {new Date(blog.publishedAt).toLocaleDateString()}
        </p>
      </div>

      <div className="image-sidebar-container">
        {coverImage && (
          <div className="blog-image">
            <img src={coverImage} alt={blog.Title} />
            <p className="image-credits">
              <strong>Image Credits:</strong> {blog.imageCredits || "Unknown"}
            </p>
          </div>
        )}
        <SocialSidebar title={blog.Title} url={shareUrl} />
      </div>

      <div className="blog-content">
        <ReactMarkdown
          components={{
            a: ({ node, ...props }) => (
              <a
                href={props.href}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  color: "rgb(22, 78, 212)",
                  textDecoration: "none",
                  borderBottom: "1px solid rgb(22, 78, 212)",
                }}
              >
                {props.children}
              </a>
            ),
            p: ({ node, ...props }) => (
              <p
                style={{
                  margin: "1.5em 0",
                  lineHeight: "2",
                  whiteSpace: "pre-wrap",
                }}
              >
                {props.children}
              </p>
            ),
          }}
        >
          {blog.Content}
        </ReactMarkdown>
      </div>

      <div>
        <a
          href={blog.Tags}
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "#007BFF", textDecoration: "none" }}
        >
          Source Link
        </a>
      </div>

      {/* Disclaimer */}
      <Box
        sx={{
          backgroundColor: "#164ED4",
          color: "white",
          padding: "8px 16px",
          borderRadius: "8px",
          textAlign: "center",
          margin: "0 auto",
        }}
        role="alert"
      >
        <Typography
          variant="subtitle1"
          fontWeight="bold"
          sx={{ marginBottom: "8px" }}
        >
          Disclaimer:
        </Typography>
        <Typography
          variant="body1"
          fontWeight="normal"
          sx={{ marginBottom: "8px" }}
        >
          Some articles are sourced from publicly available sources to inform
          readers on topics relevant to H-1B visas, immigration policies, and
          related matters.
        </Typography>
        <Typography
          variant="body1"
          fontWeight="normal"
          sx={{ marginBottom: "8px" }}
        >
          Please note that the views and opinions expressed are solely those of
          the original authors (Except ones authored by VisJobs) and do NOT
          represent the official stance or endorsement of VisJobs.
        </Typography>
      </Box>
    </div>
  );
};

export default BlogView;
