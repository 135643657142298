import React from "react";

const AdditionalDetails = ({ formData, handleChange }) => {
  return (
    <div>
      <h2 className="text-xl font-bold mb-4">Additional Details</h2>
      <div className="mb-4 space-y-2">
        <label className="text-base font-normal text-gray-800">
          Additional Details that may be helpful (LinkedIn)
        </label>
        <textarea
          name="additionalInformation"
          value={formData.additionalInformation}
          onChange={handleChange}
          className="w-full border  border-gray-600 rounded p-2 focus:outline-none focus:border-blue-500 h-40 focus:ring-blue-600 "
          placeholder="Enter any additional details that may be helpful for your job application here."
        />
      </div>
    </div>
  );
};

export default AdditionalDetails;
