import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  margin: "0",
  "&:before": {
    display: "none",
  },
}));

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  minHeight: "70px",
  backgroundColor: "#f7f7f7",
  "& .MuiAccordionSummary-content": {
    margin: "0",
  },
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  color: "black",
}));

const FAQAccordion = () => {
  return (
    <div className="mx-auto max-w-screen-3xl px-6">
      <div className="mx-auto max-w-[1320px] space-y-[40px] md:py-[80px]">
        <StyledAccordion>
          <StyledAccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>What is the Job Cart option?</Typography>
          </StyledAccordionSummary>
          <AccordionDetails>
            <Typography>
              The Job Cart option is a one-time payment feature where we apply
              for a specific number of jobs on your behalf without requiring a
              subscription. It’s great if you don’t want to commit to a monthly
              plan.
            </Typography>
          </AccordionDetails>
        </StyledAccordion>
        <StyledAccordion>
          <StyledAccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography>What is the Subscription option?</Typography>
          </StyledAccordionSummary>
          <AccordionDetails>
            <Typography>
              The Subscription option is a monthly plan that auto-applies to a
              set number of jobs for you each month. You can choose between
              Basic, Premium, or Full Service, depending on your needs.
            </Typography>
          </AccordionDetails>
        </StyledAccordion>
        <StyledAccordion>
          <StyledAccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header"
          >
            <Typography>When should I pick the Job Cart option?</Typography>
          </StyledAccordionSummary>
          <AccordionDetails>
            <Typography>
              Choose the Job Cart option if you only need a few hand-picked job
              applications or prefer not to commit to a monthly plan. It's a
              flexible one-time service that lets you control the jobs we apply
              to on your behalf. Alternatively, you can subscribe for ongoing
              support where we apply to your selected jobs each month.
            </Typography>
          </AccordionDetails>
        </StyledAccordion>
        <StyledAccordion>
          <StyledAccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4a-content"
            id="panel4a-header"
          >
            <Typography>
              When should I choose the Subscription option?
            </Typography>
          </StyledAccordionSummary>
          <AccordionDetails>
            <Typography>
              Choose the Subscription option if you're looking for ongoing
              support with job applications. It’s perfect for those actively
              seeking new opportunities over an extended period.
            </Typography>
          </AccordionDetails>
        </StyledAccordion>
        <StyledAccordion>
          <StyledAccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel5a-content"
            id="panel5a-header"
          >
            <Typography>Can I cancel a subscription anytime?</Typography>
          </StyledAccordionSummary>
          <AccordionDetails>
            <Typography>
              Yes, if you choose a 1-month subscription, you can cancel anytime.
              However, the 3-month subscription requires a commitment for the
              full duration.
            </Typography>
          </AccordionDetails>
        </StyledAccordion>
      </div>
    </div>
  );
};

export default FAQAccordion;
