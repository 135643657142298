import React from "react";
import Typography from "@mui/material/Typography";

const InfoSection = () => {
  return (
    <div className="w-full text-center">
      <div className="heading text-center mb-6">
        <h4 className="text-2xl md:text-4xl lg:text-5xl font-bold leading-tight md:leading-tight lg:leading-tight text-[#1D1F2C]">
          <span className="text-[#164ED4] font-bold"> Comprehensive </span> H-1B
          Visa Insights & Trends
        </h4>
      </div>
      <div className="  flex justify-center items-center">
        <Typography
          variant="body1"
          className="text-black text-center w-full lg:w-2/3"
        >
          For <strong>job seekers</strong>, understanding which roles are in
          high demand and how education affects earning potential can help guide
          career planning. For <strong>employers</strong>, these insights are
          crucial for shaping hiring strategies and ensuring competitive
          compensation packages. Our analysis also covers the importance of{" "}
          <strong>premium processing</strong> and the frequency of{" "}
          <strong>Requests for Evidence (RFEs)</strong>, helping you prepare for
          a smoother application process.
        </Typography>
      </div>
    </div>
  );
};

export default InfoSection;
