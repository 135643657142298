import React, { useState } from "react";
import {
  Button,
  Box,
  Grid,
  Typography,
  DialogContent,
  Divider,
  useMediaQuery,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/CheckCircle";
import { Elements } from "@stripe/react-stripe-js";
import stripePromise from "../../Utils/stripe";
import { useTheme } from "@mui/material/styles";

const PaymentCard = ({ title, price, frequency, features, onSelect }) => (
  <Box
    border={1}
    borderColor="grey.300"
    borderRadius={2}
    p={4}
    textAlign="center"
  >
    <Typography variant="h6" gutterBottom>
      {title}
    </Typography>
    <Typography variant="h4">
      ${price}
      <Typography variant="body2" component="span">
        /{frequency}
      </Typography>
    </Typography>
    <Box mt={2}>
      <ul style={{ listStyleType: "none", padding: 0 }}>
        {features.map((feature, index) => (
          <li
            key={index}
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "8px",
            }}
          >
            <CheckIcon style={{ marginRight: "8px" }} />
            <Typography variant="body2">{feature}</Typography>
          </li>
        ))}
      </ul>
    </Box>
    <Box mt={4}>
      <Button
        variant="contained"
        color="primary"
        onClick={() => onSelect(price, frequency)}
      >
        Buy Now
      </Button>
    </Box>
  </Box>
);

const PaymentOptionsDialog = ({ onBack, planType, onClose }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm")); // Detect smaller screens
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const [options, setOptions] = useState({
    mode: "payment",
    amount: 100,
    currency: "usd",
    appearance: {
      automatic_payment_methods: { enabled: true },
    },
  });
  const paymentOptions = {
    "one-time": [
      {
        title: "Basic Pack",
        price: "39",
        frequency: "one time",
        points: 10,
        features: [
          "10 job applications",
          "Basic application optimization",
          "Standard support",
        ],
      },
      {
        title: "Advanced Pack",
        price: "75",
        frequency: "one time",
        points: 20,
        features: [
          "20 job applications",
          "Advanced application optimization",
          "Priority support",
        ],
      },
    ],
    subscribe: [
      {
        title: "Monthly",
        contents: [
          {
            title: "Basic",
            price: "90",
            frequency: "month",
            type: "monthly",
            points: 20,
            features: [
              "20 job applications",
              "Advanced application optimization",
              "Priority support",
            ],
          },
          {
            title: "Premium",
            price: "140",
            frequency: "month",
            type: "monthly",
            points: 40,
            features: [
              "40 job applications",
              "Advanced application optimization",
              "Priority support",
            ],
          },
        ],
      },
      {
        title: "3 Months",
        contents: [
          {
            title: "Basic",
            price: "80",
            frequency: "month",
            type: "3 months",
            points: 60,
            features: [
              "20 job applications",
              "Advanced application optimization",
              "Priority support",
            ],
          },
          {
            title: "Premium",
            price: "120",
            frequency: "month",
            type: "3 months",
            points: 120,
            features: [
              "40 job applications",
              "Advanced application optimization",
              "Priority support",
            ],
          },
        ],
      },
    ],
  };

  const onSelectSubscription = (subscriptionPrice, frequency, option) => {
    setOptions({ ...options, amount: +subscriptionPrice });
    setSelectedSubscription(+subscriptionPrice);
    onClose(
      option?.type === "3 months"
        ? { ...option, price: +option?.price * 3 }
        : option
    );
  };

  return (
    <Elements stripe={stripePromise} options={options}>
      <DialogContent>
        {planType === "subscribe" ? (
          <Box
            display="flex"
            flexDirection={isSmallScreen ? "column" : "row"}
            gap={4}
            alignItems="center"
            justifyContent="center" // Center horizontally on larger screens
          >
            {/* Monthly Plans Section */}
            <Box
              flex={1}
              width={isSmallScreen ? "100%" : "auto"}
              textAlign="center"
            >
              <Typography variant="h5" gutterBottom>
                {paymentOptions[planType][0].title}
              </Typography>
              <Grid container spacing={2} justifyContent="center">
                {paymentOptions[planType][0].contents.map(
                  (option, optionIndex) => (
                    <Grid item xs={12} md={6} key={optionIndex}>
                      <PaymentCard
                        {...option}
                        onSelect={(subscribe, price) =>
                          onSelectSubscription(subscribe, price, option)
                        }
                      />
                    </Grid>
                  )
                )}
              </Grid>
            </Box>

            {/* Divider based on screen size */}
            {isSmallScreen ? (
              <Divider
                orientation="horizontal"
                style={{
                  backgroundColor: "grey",
                  height: "2px",
                  width: "100%",
                  margin: "16px 0",
                }}
              />
            ) : (
              <Divider
                orientation="vertical"
                flexItem
                style={{
                  backgroundColor: "grey",
                  width: "2px",
                  margin: "0 16px",
                }}
              />
            )}

            {/* 3 Months Plans Section */}
            <Box
              flex={1}
              width={isSmallScreen ? "100%" : "auto"}
              textAlign="center"
            >
              <Typography variant="h5" gutterBottom>
                {paymentOptions[planType][1].title}
              </Typography>
              <Grid container spacing={2} justifyContent="center">
                {paymentOptions[planType][1].contents.map(
                  (option, optionIndex) => (
                    <Grid item xs={12} md={6} key={optionIndex}>
                      <PaymentCard
                        {...option}
                        onSelect={(subscribe, price) =>
                          onSelectSubscription(subscribe, price, option)
                        }
                      />
                    </Grid>
                  )
                )}
              </Grid>
            </Box>
          </Box>
        ) : (
          <Grid container spacing={2} justifyContent="center">
            {paymentOptions[planType].map((option, index) => (
              <Grid item xs={12} md={6} key={index}>
                <PaymentCard
                  {...option}
                  onSelect={(subscribe, price) =>
                    onSelectSubscription(subscribe, price, option)
                  }
                />
              </Grid>
            ))}
          </Grid>
        )}
      </DialogContent>
    </Elements>
  );
};

export default PaymentOptionsDialog;
