import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardFooter,
  Typography,
  Button,
} from "@material-tailwind/react";
import JobDetails from "../JobDetails";
import DOMPurify from "dompurify";
import "./JobCard.css";
import CustomTooltip from "./customTooltip";
import { Link, useNavigate } from "react-router-dom";
import LinkIcon from "@mui/icons-material/Link";
import Box from "@mui/material/Box";
import JobCardSkeleton from "./JobSkeleton";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { transferJobsToSupabase } from "./utils";
import { useDispatch, useSelector } from "react-redux";
import { addJobInCartAction } from "../../redux/actions/cart";

const JobCard = ({
  appliedJobs,
  job,
  loading,
  user,
  showButtons = true,
  showLink = true,
  updateCartCount,
  isInCart = false,
  isSaved = false,
  onRemoveFromCart,
  deleteJob = (id) => {},
  onDetailsClick,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authReducer = useSelector((reducers) => reducers.auth);
  const cartReducer = useSelector((reducers) => reducers.cart);
  const [open, setOpen] = useState(false);
  const [modalData, setModalData] = useState({});

  const handleClose = () => setOpen(!open);

  useEffect(() => {
    if (user && user.id) {
      transferJobsToSupabase(user.id);
    }
  }, [user]);

  let country = job?.job_country?.toUpperCase();
  if (country === "UNITED STATES" || country === "USA" || country === "US") {
    country = "US";
  }

  const handleModalOpen = async (data) => {
    setModalData(isInCart || isSaved ? data?.default_id : data?.id);
    setOpen(!open);
  };

  const jobLocation = [job?.job_city, job?.job_state, country]
    .filter(Boolean)
    .join(", ");
  const finalLocation =
    jobLocation.length > 25 ? jobLocation.slice(0, 25) + ".." : jobLocation;

  const handleAutoApply = async (job) => {
    const jobData = {
      job_name: job.job_name,
      job_id: job.job_id,
      company_name: job.company_name,
      finalLocation,
      job_city: job.job_city,
      job_state: job.job_state,
      min_salary: job.min_salary,
      max_salary: job.max_salary,
      job_description: job.job_description,
      job_hybrid: job?.job_hybrid,
      job_remote: job?.job_remote,
      job_link: job?.job_apply_link,
      default_id: job?.id,
    };

    if (
      cartReducer?.cartJobs?.some(
        (jobDetail) => jobDetail?.job_id === job.job_id
      )
    ) {
      toast.error("Job is already in the cart", {
        position: "top-right",
        autoClose: 200,
        hideProgressBar: true,
      });
    } else {
      const saveJob = {
        user_id: authReducer?.user?.user_id || "",
        job_id: jobData?.job_id,
        job_title: jobData?.job_name,
        company_name: jobData?.company_name,
        job_hybrid: jobData?.job_hybrid,
        job_remote: jobData?.job_remote,
        job_city: jobData?.job_city,
        job_state: jobData?.job_state,
        min_salary: jobData?.min_salary,
        max_salary: jobData?.max_salary,
        job_description: jobData?.job_description,
        job_link: jobData?.job_link,
        default_id: jobData?.default_id,
      };

      addJobInCartAction(saveJob, dispatch, authReducer);
      toast.success("Job added to cart", {
        position: "top-right",
        autoClose: 300,
        hideProgressBar: true,
      });
    }
  };

  const handleRemoveFromCart = async (jobId) => {
    onRemoveFromCart(jobId);
  };

  const createDescription = (description) => {
    const sanitizedDescription = DOMPurify.sanitize(description, {
      USE_PROFILES: { html: true },
    });
    const words = sanitizedDescription
      .replace(/<[^>]+>/g, "")
      .replace(/\('/g, "")
      .replace(/&amp;/g, "&")
      .replace(/\ban&nbsp;/g, "an ")
      .replace(/\ba&nbsp;/g, "a ")
      .replace(/&nbsp;/g, " ")
      .split(" ");

    return words.slice(0, 50).join(" ") + (words.length > 50 ? "..." : ""); // Limit to 100 words
  };

  return (
    <>
      {open && (
        <JobDetails
          open={open}
          handleClose={handleClose}
          data={modalData}
          isInCart={isInCart}
          isSaved={isSaved}
        />
      )}
      {loading ? (
        Array.from(new Array(3)).map((_, index) => (
          <div key={index} className="w-full md:w-1/2 lg:w-1/3 p-2">
            <JobCardSkeleton />
          </div>
        ))
      ) : (
        <Card className="mt-6 border border-[#E8EDFB] w-full h-fit">
          <CardBody className="flex flex-col gap-3">
            <Typography
              variant="h6"
              className="truncate sm:!text-[1.5rem] font-bold text-[#1d1f2c]"
            >
              {job?.job_name}
            </Typography>

            {showLink ? (
              <CustomTooltip
                title={`View ${job?.company_name} sponsorship data`}
                placement="bottom"
              >
                <Link
                  to={`/app/sponsor-information/${job?.full_company_name}`}
                  style={{
                    textDecoration: "none",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Box display="flex" alignItems="center">
                    <Typography
                      variant="h6"
                      className="card-tag capitalize sm:!text-[1.2rem]"
                      style={{
                        color: "#4864e1",
                        cursor: "pointer",
                        marginRight: 4,
                      }}
                    >
                      @{job?.company_name}
                    </Typography>
                    <LinkIcon fontSize="small" style={{ color: "#1976d2" }} />
                  </Box>
                </Link>
              </CustomTooltip>
            ) : (
              <Typography
                variant="h6"
                className="card-tag capitalize sm:!text-[1.2rem]"
                style={{ color: "#4864e1", marginRight: 4 }}
              >
                @{job?.company_name}
              </Typography>
            )}

            <Typography>
              {jobLocation && <div>🏢 Office Location: {jobLocation}</div>}
            </Typography>
            {job?.min_salary || job?.max_salary ? (
              <Typography>
                💵 Salary: ${job.min_salary ?? "N/A"} - $
                {job.max_salary ?? "N/A"}
              </Typography>
            ) : (
              <Typography>💵 Salary: Not Found</Typography>
            )}

            <div className="text-sm text-[#1D1F2C]">Description</div>
            <Typography className="text-[#1D1F2C]">
              {job?.job_description && createDescription(job?.job_description)}
            </Typography>
          </CardBody>
          <CardFooter className="flex gap-[17px] pt-0">
            <Button
              className="detailsButton capitalize w-full"
              onClick={() => onDetailsClick(job)}
            >
              Details
            </Button>
            {isInCart && (
              <Button
                className="bg-primary w-full capitalize"
                onClick={() => handleRemoveFromCart(job.id)}
              >
                Remove from Cart
              </Button>
            )}
            {showButtons && !isInCart && (
              <>
                <Button
                  className="detailsButton capitalize w-full"
                  onClick={() =>
                    job?.job_apply_link &&
                    window.open(job.job_apply_link, "_blank")
                  }
                >
                  Apply
                </Button>
                {cartReducer?.cartJobs?.some(
                  (jobDetail) => jobDetail?.job_id === job.job_id
                ) ? (
                  <Button
                    className="bg-green-500 w-full capitalize text-xs font-bold flex items-center justify-center text-center rounded-lg text-white cursor-pointer"
                    onClick={() => navigate("/app/cart")}
                  >
                    Job In Cart
                  </Button>
                ) : (
                  !isSaved && (
                    <Button
                      className="bg-primary w-full capitalize"
                      onClick={() => handleAutoApply(job)}
                    >
                      Auto-Apply
                    </Button>
                  )
                )}
              </>
            )}
            {isSaved && (
              <Button
                className="bg-primary w-full capitalize"
                onClick={() => deleteJob(job.id)}
              >
                Unsave Job
              </Button>
            )}
          </CardFooter>
        </Card>
      )}
    </>
  );
};

export default JobCard;
