import React from "react";
import { Button, Box, Grid, Typography, DialogContent } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const SubscriptionOptionsDialog = ({ onBack, onContinue }) => {
  return (
    <DialogContent>
      <Box mb={2}>
        <Button startIcon={<ArrowBackIcon />} onClick={onBack}>
          Back
        </Button>
      </Box>
      <Grid container spacing={2}>
        <Grid
          item
          xs={6}
          container
          direction="column"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box textAlign="center">
            <Typography variant="h6">Monthly Subscription</Typography>
            <Typography variant="body1">
              Benefits of monthly subscription...
            </Typography>
          </Box>
          <Box mt={2} textAlign="center">
            <Button
              variant="contained"
              color="primary"
              onClick={() => onContinue("monthly")}
            >
              Continue
            </Button>
          </Box>
        </Grid>
        <Grid
          item
          xs={6}
          container
          direction="column"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box textAlign="center">
            <Typography variant="h6">Three-month Subscription</Typography>
            <Typography variant="body1">
              Benefits of three-month subscription...
            </Typography>
          </Box>
          <Box mt={2} textAlign="center">
            <Button
              variant="contained"
              color="primary"
              onClick={() => onContinue("three-month")}
            >
              Continue
            </Button>
          </Box>
        </Grid>
      </Grid>
    </DialogContent>
  );
};

export default SubscriptionOptionsDialog;
