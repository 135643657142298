import { baseUrl, Token } from "../Utils/constants";
import CallApi from "../Utils/callApi";

//Get Company Summary
export const fetchcompanySummary = (
  companyId,
  start_year = 2022,
  end_year = 2023
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const url = `${baseUrl}/company/${companyId}/summary?start_year=${start_year}&end_year=${end_year}`;
      const apiOptions = {
        method: "GET",
        headers: {
          Authorization: `Token ${Token}`,
        },
      };
      const response = await CallApi(url, apiOptions);
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};
