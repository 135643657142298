import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cartJobs: [],
};

export const Cart = createSlice({
  name: "cart",
  initialState,
  reducers: {
    // addJobInCart: (state, response) => {
    //   state.cartJobs = response?.payload;
    // },
    // removeJobFromCart: (state, response) => {
    //   state.cartJobs = response?.payload;
    // },
    updateJobsCart: (state, response) => {
      state.cartJobs = response?.payload;
    },
    clearCartData: (state) => {
      state.cartJobs = [];
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateJobsCart, clearCartData } = Cart.actions;

export default Cart.reducer;
