export const baseUrl = process.env.REACT_APP_BASE_URL;
// export const baseUrl = "http://127.0.0.1:8000/api";
export const Token = process.env.REACT_APP_TOKEN;
export const Version = 1;

export const jobCategories = [
  { label: "Engineering", value: "Engineering" },
  { label: "Data Science", value: "Data Science" },
  { label: "Sales", value: "Sales" },
  { label: "Marketing", value: "Marketing" },
  { label: "Product", value: "Product" },
  { label: "Design", value: "Design" },
  { label: "Business Operations", value: "Business Operations" },
  { label: "Recruiting & HR", value: "Recruiting & HR" },
  { label: "Finance", value: "Finance" },
  { label: "Customer Support", value: "Customer Support" },
  { label: "Project Management", value: "Project Management" },
  { label: "Legal", value: "Legal" },
  { label: "Science", value: "Science" },
  { label: "Administrative", value: "Administrative" },
];

export const jobCommitments = [
  { label: "Part Time", value: "Part-Time" },
  { label: "Full Time", value: "Full-Time" },
  { label: "Internship", value: "Internship" },
];

export const jobLevels = [
  {
    label: "Internship",
    value: "Internship",
  },
  {
    label: "Entry level",
    value: "Entry level",
  },
  {
    label: "Associate",
    value: "Associate",
  },
  {
    label: "Mid-Senior",
    value: "Mid-Senior",
  },
  {
    label: "Director",
    value: "Director",
  },
  {
    label: "Executive",
    value: "Executive",
  },
];

export const salaryLimit = [
  { label: "$ 30,000", value: 30000 },
  { label: "$ 40,000", value: 40000 },
  { label: "$ 50,000", value: 50000 },
  { label: "$ 60,000", value: 60000 },
  { label: "$ 70,000", value: 70000 },
  { label: "$ 80,000", value: 80000 },
  { label: "$ 90,000", value: 90000 },
  { label: "$ 100,000", value: 100000 },
  { label: "$ 110,000", value: 110000 },
  { label: "$ 120,000", value: 120000 },
  { label: "$ 130,000", value: 130000 },
  { label: "$ 140,000", value: 140000 },
  { label: "$ 150,000", value: 150000 },
  { label: "$ 160,000", value: 160000 },
  { label: "$ 170,000", value: 170000 },
  { label: "$ 180,000", value: 180000 },
  { label: "$ 190,000", value: 190000 },
  { label: "$ 200,000", value: 200000 },
  { label: "$ 210,000", value: 210000 },
  { label: "$ 220,000", value: 220000 },
  { label: "$ 230,000", value: 230000 },
  { label: "$ 240,000", value: 240000 },
  { label: "$ 250,000", value: 250000 },
  { label: "$ 260,000", value: 260000 },
  { label: "$ 270,000", value: 270000 },
  { label: "$ 280,000", value: 280000 },
  { label: "$ 290,000", value: 290000 },
  { label: "$ 300,000", value: 300000 },
  { label: "$ 310,000", value: 310000 },
  { label: "$ 320,000", value: 320000 },
  { label: "$ 330,000", value: 330000 },
  { label: "$ 340,000", value: 340000 },
  { label: "$ 350,000", value: 350000 },
  { label: "$ 360,000", value: 360000 },
  { label: "$ 370,000", value: 370000 },
  { label: "$ 380,000", value: 380000 },
  { label: "$ 390,000", value: 390000 },
  { label: "$ 400,000", value: 400000 },
  { label: "$ 410,000", value: 410000 },
  { label: "$ 420,000", value: 420000 },
  { label: "$ 430,000", value: 430000 },
  { label: "$ 440,000", value: 440000 },
  { label: "$ 450,000", value: 450000 },
  { label: "$ 460,000", value: 460000 },
  { label: "$ 470,000", value: 470000 },
  { label: "$ 480,000", value: 480000 },
  { label: "$ 490,000", value: 490000 },
  { label: "$ 500,000", value: 500000 },
  { label: "$ 510,000", value: 510000 },
  { label: "$ 520,000", value: 520000 },
  { label: "$ 530,000", value: 530000 },
  { label: "$ 540,000", value: 540000 },
  { label: "$ 550,000", value: 550000 },
  { label: "$ 560,000", value: 560000 },
  { label: "$ 570,000", value: 570000 },
  { label: "$ 580,000", value: 580000 },
  { label: "$ 590,000", value: 590000 },
  { label: "$ 600,000", value: 600000 },
  { label: "$ 610,000", value: 610000 },
  { label: "$ 620,000", value: 620000 },
  { label: "$ 630,000", value: 630000 },
  { label: "$ 640,000", value: 640000 },
  { label: "$ 650,000", value: 650000 },
  { label: "$ 660,000", value: 660000 },
  { label: "$ 670,000", value: 670000 },
  { label: "$ 680,000", value: 680000 },
  { label: "$ 690,000", value: 690000 },
  { label: "$ 700,000", value: 700000 },
];

export const jobIndustries = [
  {
    label: "Computer Software",
    value: "Computer Software",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
      >
        <path
          d="M18.5 2.5H6.5C4.21 2.5 3 3.71 3 6V14C3 16.29 4.21 17.5 6.5 17.5H10V20.5H8C7.724 20.5 7.5 20.724 7.5 21C7.5 21.276 7.724 21.5 8 21.5H17C17.276 21.5 17.5 21.276 17.5 21C17.5 20.724 17.276 20.5 17 20.5H15V17.5H18.5C20.79 17.5 22 16.29 22 14V6C22 3.71 20.79 2.5 18.5 2.5ZM14 20.5H11V17.5H14V20.5ZM21 14C21 15.729 20.229 16.5 18.5 16.5H14.5H10.5H6.5C4.771 16.5 4 15.729 4 14V6C4 4.271 4.771 3.5 6.5 3.5H18.5C20.229 3.5 21 4.271 21 6V14ZM10.854 8.354L9.20801 10L10.854 11.646C11.049 11.841 11.049 12.158 10.854 12.353C10.756 12.451 10.628 12.499 10.5 12.499C10.372 12.499 10.244 12.45 10.146 12.353L8.146 10.353C7.951 10.158 7.951 9.841 8.146 9.646L10.146 7.646C10.341 7.451 10.658 7.451 10.853 7.646C11.048 7.841 11.049 8.158 10.854 8.354ZM16.854 9.646C17.049 9.841 17.049 10.158 16.854 10.353L14.854 12.353C14.756 12.451 14.628 12.499 14.5 12.499C14.372 12.499 14.244 12.45 14.146 12.353C13.951 12.158 13.951 11.841 14.146 11.646L15.792 10L14.146 8.354C13.951 8.159 13.951 7.84197 14.146 7.64697C14.341 7.45197 14.658 7.45197 14.853 7.64697L16.854 9.646Z"
          fill="currentColor"
        />
      </svg>
    ),
  },
  {
    label: "Financial Technology",
    value: "Financial Technology",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
      >
        <path
          d="M19 7.5C19 5.565 17.935 4.5 16 4.5H6C4.065 4.5 3 5.565 3 7.5V14.5C3 16.435 4.065 17.5 6 17.5C6 19.435 7.065 20.5 9 20.5H19C20.935 20.5 22 19.435 22 17.5V10.5C22 8.565 20.935 7.5 19 7.5ZM4 14.5V7.5C4 6.117 4.617 5.5 6 5.5H16C17.383 5.5 18 6.117 18 7.5V14.5C18 15.883 17.383 16.5 16 16.5H6C4.617 16.5 4 15.883 4 14.5ZM21 17.5C21 18.902 20.402 19.5 19 19.5H9C7.598 19.5 7 18.902 7 17.5H16C17.935 17.5 19 16.435 19 14.5V8.5C20.402 8.5 21 9.098 21 10.5V17.5ZM6 11.757C5.586 11.757 5.25 11.421 5.25 11.007C5.25 10.593 5.586 10.257 6 10.257C6.414 10.257 6.75 10.593 6.75 11.007C6.75 11.422 6.414 11.757 6 11.757ZM15.25 11.007C15.25 10.593 15.586 10.257 16 10.257C16.414 10.257 16.75 10.593 16.75 11.007C16.75 11.421 16.414 11.757 16 11.757C15.586 11.757 15.25 11.422 15.25 11.007ZM11 14C12.654 14 14 12.654 14 11C14 9.346 12.654 8 11 8C9.346 8 8 9.346 8 11C8 12.654 9.346 14 11 14ZM11 9C12.103 9 13 9.897 13 11C13 12.103 12.103 13 11 13C9.897 13 9 12.103 9 11C9 9.897 9.897 9 11 9Z"
          fill="currentColor"
        />
      </svg>
    ),
  },
  {
    label: "Health & Wellness",
    value: "Health %26 Wellness",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
      >
        <path
          d="M20.596 5.34998C19.61 4.14798 18.187 3.508 16.481 3.5C16.474 3.5 16.466 3.5 16.459 3.5C14.478 3.5 13.183 4.73703 12.501 5.84003C11.819 4.73703 10.524 3.5 8.54302 3.5C8.53502 3.5 8.52705 3.5 8.52105 3.5C6.81705 3.508 5.39501 4.14801 4.40801 5.35101C3.17701 6.85201 2.71701 9.17397 3.17901 11.561C4.38801 17.792 11.972 21.309 12.294 21.456C12.359 21.486 12.431 21.501 12.501 21.501C12.571 21.501 12.6431 21.486 12.7081 21.456C13.0301 21.309 20.6141 17.793 21.8221 11.561C22.2851 9.17197 21.827 6.85098 20.596 5.34998ZM20.839 11.37C19.836 16.548 13.7111 19.84 12.5001 20.444C11.2891 19.84 5.16408 16.548 4.16008 11.37C3.75308 9.272 4.1341 7.25899 5.1801 5.98499C5.9831 5.00599 7.1081 4.507 8.5241 4.5C8.5311 4.5 8.537 4.5 8.544 4.5C11.1 4.5 11.99 7.03897 12.027 7.14697C12.164 7.55297 12.838 7.55297 12.974 7.14697C13.011 7.03897 13.9021 4.5 16.4571 4.5C16.4641 4.5 16.4701 4.5 16.4771 4.5C17.8951 4.507 19.021 5.00599 19.823 5.98499C20.8671 7.25699 21.247 9.271 20.839 11.37Z"
          fill="currentColor"
        />
      </svg>
    ),
  },
  {
    label: "Media & Entertainment",
    value: "Media %26 Entertainment",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
      >
        <path
          d="M18.125 2.5H6.875C4.34 2.5 3 3.84 3 6.375V17.625C3 20.16 4.34 21.5 6.875 21.5H18.125C20.66 21.5 22 20.16 22 17.625V6.375C22 3.84 20.66 2.5 18.125 2.5ZM17 8.5H21V11.5H17V8.5ZM16 11.5H9V3.5H16V11.5ZM8 11.5H4V8.5H8V11.5ZM4 12.5H8V15.5H4V12.5ZM9 12.5H16V20.5H9V12.5ZM17 12.5H21V15.5H17V12.5ZM21 6.375V7.5H17V3.5H18.125C20.087 3.5 21 4.414 21 6.375ZM6.875 3.5H8V7.5H4V6.375C4 4.414 4.913 3.5 6.875 3.5ZM4 17.625V16.5H8V20.5H6.875C4.913 20.5 4 19.586 4 17.625ZM18.125 20.5H17V16.5H21V17.625C21 19.586 20.087 20.5 18.125 20.5Z"
          fill="currentColor"
        />
      </svg>
    ),
  },
  {
    label: "AI/ML",
    value: "AI/ML",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
      >
        <path
          d="M16.5 2.5H8.5C6.21 2.5 5 3.71 5 6V17C5 18.723 5.686 19.833 7 20.278V21C7 21.276 7.224 21.5 7.5 21.5C7.776 21.5 8 21.276 8 21V20.465C8.164 20.479 8.323 20.5 8.5 20.5H16.5C16.677 20.5 16.836 20.479 17 20.465V21C17 21.276 17.224 21.5 17.5 21.5C17.776 21.5 18 21.276 18 21V20.278C19.314 19.833 20 18.722 20 17V6C20 3.71 18.79 2.5 16.5 2.5ZM8.5 3.5H16.5C18.229 3.5 19 4.271 19 6V13.5H6V6C6 4.271 6.771 3.5 8.5 3.5ZM16.5 19.5H8.5C6.771 19.5 6 18.729 6 17V14.5H19V17C19 18.729 18.229 19.5 16.5 19.5ZM11 6C11 5.724 11.224 5.5 11.5 5.5H13.5C13.776 5.5 14 5.724 14 6C14 6.276 13.776 6.5 13.5 6.5H11.5C11.224 6.5 11 6.276 11 6ZM16.25 17C16.25 17.414 15.915 17.75 15.504 17.75C15.091 17.75 14.75 17.414 14.75 17C14.75 16.586 15.077 16.25 15.488 16.25H15.503C15.915 16.25 16.25 16.586 16.25 17ZM10.25 17C10.25 17.414 9.915 17.75 9.504 17.75C9.091 17.75 8.75 17.414 8.75 17C8.75 16.586 9.07701 16.25 9.48801 16.25H9.50299C9.91499 16.25 10.25 16.586 10.25 17ZM22 10V13C22 13.276 21.776 13.5 21.5 13.5C21.224 13.5 21 13.276 21 13V10C21 9.724 21.224 9.5 21.5 9.5C21.776 9.5 22 9.724 22 10ZM4 10V13C4 13.276 3.776 13.5 3.5 13.5C3.224 13.5 3 13.276 3 13V10C3 9.724 3.224 9.5 3.5 9.5C3.776 9.5 4 9.724 4 10Z"
          fill="currentColor"
        />
      </svg>
    ),
  },
  {
    label: "Blockchain",
    value: "Blockchain",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
      >
        <path
          d="M19 2.5H7.5C5.21 2.5 4 3.71 4 6V18C4 20.093 5.406 21.5 7.5 21.5H19C20.103 21.5 21 20.603 21 19.5V13.5V11V4.5C21 3.397 20.103 2.5 19 2.5ZM7.5 3.5H19C19.552 3.5 20 3.949 20 4.5V11V13.5C20 14.051 19.552 14.5 19 14.5H7.5C6.471 14.5 5.612 14.843 5 15.437V6C5 4.271 5.771 3.5 7.5 3.5ZM19 20.5H7.5C5.935 20.5 5 19.565 5 18C5 16.435 5.935 15.5 7.5 15.5H19C19.366 15.5 19.705 15.394 20 15.222V19.5C20 20.051 19.552 20.5 19 20.5ZM9 7C9 6.724 9.224 6.5 9.5 6.5H15.5C15.776 6.5 16 6.724 16 7C16 7.276 15.776 7.5 15.5 7.5H9.5C9.224 7.5 9 7.276 9 7ZM9 10C9 9.724 9.224 9.5 9.5 9.5H13.5C13.776 9.5 14 9.724 14 10C14 10.276 13.776 10.5 13.5 10.5H9.5C9.224 10.5 9 10.276 9 10Z"
          fill="currentColor"
        />
      </svg>
    ),
  },
];

export const storageRoute = process.env.REACT_APP_STORAGE_ROUTE;
