import React from "react";
import "./ProgressBar.css"; // Import the CSS file
import { Step, Stepper, Typography } from "@material-tailwind/react";

const ProgressBar = ({ currentStep, activeStep = 0 }) => {
  const steps = ["Payment", "Submit Job Requirements"];

  return (
    // <div className="progress-bar">
    //   {steps.map((step, index) => (
    //     <div
    //       key={index}
    //       className={`step ${currentStep === index + 1 ? "active" : ""}`}
    //     >
    //       <div className="step-number">{index + 1}</div>
    //       <div className="step-label">{step}</div>
    //     </div>
    //   ))}
    // </div>
    <div className="w-full py-4 px-8 mb-8">
      <Stepper activeStep={currentStep - 1} className="stepper-container">
        <Step className={currentStep - 1 == 0 && "!bg-[#164ED4] text-white"}>
          <div>1</div>
          <div className="absolute -bottom-[2rem] w-max text-center">
            <Typography
              color={currentStep - 1 === 0 ? "blue-gray" : "gray"}
              className="font-normal"
            >
              Payment
            </Typography>
          </div>
        </Step>
        <Step className={currentStep - 1 == 1 && "!bg-[#164ED4] text-white"}>
          <div>2</div>
          <div className="absolute -bottom-[2rem] w-max text-center">
            <Typography
              color={currentStep - 1 === 1 ? "blue-gray" : "gray"}
              className="font-normal"
            >
              Submit Job Requirements
            </Typography>
          </div>
        </Step>
      </Stepper>
    </div>
  );
};

export default ProgressBar;
