import React, { useMemo, useRef } from "react";
import ReactECharts from "echarts-for-react";
import { getCountryData } from "../dataReader";

const CountryChart = ({ startYear, endYear, country, metricContexts }) => {
  const context = "PetitionsApprovedByCountryOfBirthAndGender";
  const chartRef = useRef();
  const start = parseInt(startYear, 10);
  const end = endYear ? parseInt(endYear, 10) : start;

  const processedData = useMemo(() => getCountryData(start, end), [start, end]);

  const charts = useMemo(() => {
    const years = Object.keys(processedData);
    const subCategories = ["Total", "Male", "Female"];

    // Ensure we filter based on the selected country if provided
    const selectedCountries = country
      ? [country]
      : Array.from(
          new Set(
            years.flatMap((year) => Object.keys(processedData[year] || {}))
          )
        );

    return selectedCountries.map((countryName) => {
      const series = subCategories.map((subCategory) => ({
        name: subCategory,
        type: "bar",
        stack: "total",
        data: years.map(
          (year) => processedData[year]?.[countryName]?.[subCategory] || 0
        ),
        barWidth: "60%",
        itemStyle: {
          borderRadius: [10, 10, 0, 0],
          shadowColor: "rgba(0, 0, 0, 0.2)",
          shadowBlur: 10,
        },
      }));

      return {
        country: countryName,
        options: {
          backgroundColor: "#f4f6f8",
          title: {
            text: `${countryName} - ${metricContexts[context]} from ${startYear} to ${endYear}`,
            left: "center",
            textStyle: {
              color: "#333",
              fontWeight: "bold",
              fontSize: 16,
            },
          },
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow",
            },
          },
          legend: {
            data: subCategories,
            top: "5%",
          },
          grid: {
            left: "5%",
            right: "5%",
            bottom: "10%",
            top: "20%",
            containLabel: true,
          },
          xAxis: {
            type: "category",
            data: years,
            axisLabel: {
              rotate: 45,
              color: "#666",
            },
            axisLine: {
              lineStyle: {
                color: "#aaa",
              },
            },
          },
          yAxis: {
            type: "value",
            axisLabel: {
              color: "#666",
            },
            splitLine: {
              lineStyle: {
                color: "#eee",
              },
            },
            axisLine: {
              lineStyle: {
                color: "#aaa",
              },
            },
          },
          series: series,
        },
      };
    });
  }, [processedData, startYear, endYear, country, metricContexts]);

  return (
    <div className="flex flex-col items-center space-y-6">
      {charts.length > 0 ? (
        charts.map(({ country, options }, index) => (
          <div key={index} className="w-full lg:w-full h-[400px]">
            <ReactECharts
              ref={chartRef}
              option={options}
              style={{ height: "100%", width: "100%" }}
              className="shadow-lg rounded-md border mb-4"
            />
          </div>
        ))
      ) : (
        <div className="text-center mt-4 text-gray-600">
          No data available for the selected range.
        </div>
      )}
    </div>
  );
};

export default CountryChart;
