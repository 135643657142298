import React, { useRef } from "react";
import { useLocation } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import styled from "styled-components";
import Receipt from "./Receipt";
import VisIconRed from "../BannerHome/Icons/VisIconRed";
import CircleBackground from "../BannerHome/Icons/CircleIcon";
import GreenKeyIcon from "../BannerHome/Icons/GreenKeyIcon";
import YellowIcon from "../BannerHome/Icons/YellowIcon"; // Import the YellowIcon component

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

const CardContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  margin-bottom: 20px;
  position: relative; /* Make this container relative */
`;

const CardWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

const Card = styled.div`
  flex: 1;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: "Courier New", Courier, monospace;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  position: relative;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px; /* Adjust space between icon and card */
`;

const YellowIconWrapper = styled.div`
  position: absolute;
  right: -50px; /* Adjust this value to control the distance from the card */
  top: 50%;
  transform: translateY(-50%);
`;

const DownloadButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: -40px; /* Adjust this value as needed */
`;

const DownloadButton = styled.button`
  padding: 10px 20px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;

  &:hover {
    background-color: #45a049;
  }
`;

const ReceiptPage = () => {
  const location = useLocation();
  const { formData, amount, paymentMethod } = location.state;
  const receiptRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => receiptRef.current,
  });

  return (
    <Container>
      <VisIconRed />
      <CardContainer>
        <IconWrapper>
          <GreenKeyIcon />
        </IconWrapper>
        <CardWrapper>
          <Card ref={receiptRef}>
            <Receipt
              formData={formData}
              amount={amount}
              paymentMethod={paymentMethod}
            />
            <DownloadButtonContainer>
              <DownloadButton onClick={handlePrint}>
                Download Receipt
              </DownloadButton>
            </DownloadButtonContainer>
          </Card>
          <YellowIconWrapper>
            <YellowIcon />
          </YellowIconWrapper>
        </CardWrapper>
      </CardContainer>
      <CircleBackground />
    </Container>
  );
};

export default ReceiptPage;
