import React, { useEffect, useRef } from "react";
import * as echarts from "echarts";
import { Skeleton } from "@mui/material";

const WageInformationGraph = ({ data }) => {
  const chartRef = useRef(null);
  useEffect(() => {
    let myChart;

    const renderChart = () => {
      const uniqueJobsMap = new Map();
      data.forEach((item) => {
        const wage = parseFloat(item.wage_range.replace(/[^\d.-]/g, ""));
        if (
          !uniqueJobsMap.has(item.job_title) ||
          uniqueJobsMap.get(item.job_title).wage < wage
        ) {
          uniqueJobsMap.set(item.job_title, { ...item, wage });
        }
      });

      const uniqueJobs = Array.from(uniqueJobsMap.values());
      const sortedData = uniqueJobs
        .sort((a, b) => b.wage - a.wage)
        .slice(0, 10);
      const jobTitles = sortedData.map((item) => item.job_title);
      const wages = sortedData.map((item) => item.wage);

      const chartDom = chartRef.current;
      if (chartDom) {
        myChart = echarts.init(chartDom);

        const option = {
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow",
            },
          },
          xAxis: {
            type: "category",
            data: jobTitles,
            axisLabel: {
              rotate: 45,
              interval: 0,
            },
          },
          yAxis: {
            type: "value",
            name: "Salary ($)",
          },
          series: [
            {
              data: wages,
              type: "bar",
              label: {
                show: true,
                position: "top",
                formatter: (params) => {
                  return params.dataIndex < 3 ? params.value : "";
                },
              },
            },
          ],
        };

        myChart.setOption(option);

        const handleResize = () => {
          myChart.resize();
        };

        window.addEventListener("resize", handleResize);

        return () => {
          window.removeEventListener("resize", handleResize);
          if (myChart) {
            myChart.dispose();
          }
        };
      }
    };

    if (data && data.length > 0) {
      renderChart();
    }

    return () => {
      if (myChart) {
        myChart.dispose();
      }
    };
  }, [data]);

  return (
    <div>
      {data && data.length > 0 ? (
        <div ref={chartRef} style={{ width: "100%", height: "400px" }} />
      ) : (
        <Skeleton variant="rectangular" width="100%" height={400} />
      )}
    </div>
  );
};

export default WageInformationGraph;
