import React, { useEffect, useRef, useState } from "react";
import JobCard from "../../components/JobCard/index";
import { Spinner, Typography } from "@material-tailwind/react";
import BannerHome from "../../components/BannerHome";
import SponsoredBlock from "../../components/SponsoredBlock";
import UserImpression from "../../components/UserImpression";
import { fetchFeaturedJobs } from "../../services/jobs";
import JobCardSkeleton from "../../components/JobCard/JobSkeleton";
import SEO from "../../Utils/Seo";
import Logo from "../../assets/icons/logo.png";

const Home = (updateCartCount) => {
  const [featuredJobs, setFeaturedJobs] = useState([]);
  const [spinner, setSpinner] = useState(true);
  useEffect(() => {
    fetchFeaturedJob();
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  }, []);

  const fetchFeaturedJob = async () => {
    const response = await fetchFeaturedJobs();
    if (response?.status === 200) {
      setFeaturedJobs([...response.data.results]);
    }
    setSpinner(false);
  };

  return (
    <>
      <SEO
        title="Elevate Your H-1B/OPT Career Search"
        description="Are you a job seeker looking for visa-sponsored jobs in the US? VisJobs is here to help - your one stop-shop for H-1B/OPT jobs."
        canonical=""
        ogImage="/apple-touch-icon.png"
      />
      <BannerHome />
      <div className="jobCard-block  py-[40px] md:py-[80px] bg-[#F9F9F8]">
        <div className="container mx-auto px-3">
          <Typography
            variant="h2"
            className="text-center text-2xl md:text-4xl lg:text-5xl leading-tight md:leading-tight md:pb-16 lg:leading-tight text-[#1D1F2C]"
          >
            <span className="text-[#164ED4]">Featured</span> Jobs
          </Typography>
          {spinner ? (
            <div className="flex flex-wrap">
              {Array.from(new Array(3)).map((_, index) => (
                <div key={index} className="w-full md:w-1/2 lg:w-1/3 p-2">
                  <JobCardSkeleton />
                </div>
              ))}
            </div>
          ) : (
            <div className="row grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              {featuredJobs?.length > 0 &&
                featuredJobs?.map(
                  (job, index) =>
                    index <= 8 && (
                      <JobCard
                        job={job}
                        key={index}
                        updateCartCount={updateCartCount}
                      />
                    )
                )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Home;
