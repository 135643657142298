import React, { useEffect, useState } from "react";
import "./JobList.css";
import JobCard from "../../components/JobCard";
import { Input, Option, Select } from "@material-tailwind/react";
import CustomTooltip from "../../components/JobCard/customTooltip";
import { FormGroup, FormControlLabel, Switch } from "@mui/material";
import {
  fetchActiveJob,
  fetchTaggedJobs,
  fetchAllCompanyJobs,
} from "../../services/jobs";
import InfiniteScroll from "react-infinite-scroll-component";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Autocomplete from "../../components/BannerHome/Location2";
import GoldBanner from "../../components/InfoCard/GoldBanner";
import {
  jobCategories,
  jobCommitments,
  jobIndustries,
  jobLevels,
  salaryLimit,
} from "../../Utils/constants";
import JobCardSkeleton from "../../components/JobCard/JobSkeleton";
import { tags } from "./utilities";
import { fetchJobById } from "../../services/jobs";
import JobDetails from "../../components/JobDetails";
const JobList = ({ updateCartCount }) => {
  const { jobId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { jobParameters, source } = location.state || {};
  const authState = useSelector((state) => state.auth);
  const cartState = useSelector((state) => state.cart);

  const [jobs, setJobs] = useState([]);
  const [page, setPage] = useState(1);
  const [isRequest, setIsRequest] = useState(true);
  const [activeField, setActiveField] = useState("");
  const [scrollLoader, setScrollLoader] = useState(true);
  const [selectedJob, setSelectedJob] = useState(null); // To store the job details for the modal
  const [isModalOpen, setModalOpen] = useState(false); // To control the modal state

  const [parameters, setParameters] = useState(
    jobParameters ?? {
      title: "",
      location: "Seattle, Washington",
      duration: "Full-Time",
      department: "Engineering",
      level: "entry-level",
      experience: "Entry level",
      salary: 30000,
      industry: "Computer Software",
      sponsorship: false,
    }
  );

  const handleModalOpen = (job) => {
    setSelectedJob(job);
    setModalOpen(true);
    navigate(`/app/job-list/${job?.job_id}`);
  };

  const handleModalClose = () => {
    setSelectedJob(null);
    setModalOpen(false);
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  }, []);

  useEffect(() => {
    if (activeField?.length) {
      setJobs([]);
      fetchJobsByField(1, activeField);
    }
  }, [activeField]);

  useEffect(() => {
    if (isRequest) {
      if (source === "companiesStatistics") {
        fetchJobsFromCompaniesStatistics(1);
      } else {
        fetchJobs(1, true);
      }
    }
  }, [isRequest]);

  // Fetch all jobs on page load
  useEffect(() => {
    if (jobId) {
      // If jobId exists in the URL, fetch its details
      fetchJobDetails(jobId);
      console.log("qqqqqqqqqqqqqqqqq", jobId);
    }
  }, [jobId]);

  const fetchJobs = async (currentPage = page, isFiltered = false) => {
    if ((currentPage === 2 && !authState?.isLoggedIn) || currentPage == null) {
      setPage(null);
      return;
    }
    setScrollLoader(true);
    setActiveField("");
    const response = await fetchActiveJob(currentPage, parameters);
    if (response?.status === 200) {
      if (isFiltered) setJobs([...response.data.results]);
      else setJobs([...jobs, ...response.data.results]);
      setPage(response?.data?.next);
    } else {
      if (isFiltered) setJobs([]);
      setPage(1);
    }
    setScrollLoader(false);
    setIsRequest(false);
  };

  const fetchJobsFromCompaniesStatistics = async (currentPage = page) => {
    const response = await fetchAllCompanyJobs(
      currentPage,
      jobParameters["title"]
    );
    if (response?.status === 200) {
      setJobs([...response.data.results]);
      setPage(response?.data?.next);
    } else {
      setJobs([]);
      setPage(1);
    }
    setScrollLoader(false);
    setIsRequest(false);
    navigate(location.pathname, {
      replace: true,
      state: { jobParameters, source: null },
    });
  };

  const fetchJobsByField = async (page, tag) => {
    setScrollLoader(true);
    const response = await fetchTaggedJobs(page, tag);
    if (response?.status === 200) setJobs([...response.data.results]);
    setScrollLoader(false);
  };

  const fetchJobDetails = async (id) => {
    const response = await fetchJobById(id);
    if (response?.status === 200) {
      setSelectedJob(response.data);
      setModalOpen(true); // Open modal when job details are fetched
    }
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    navigate("/job-list"); // Reset the URL when the modal is closed
  };

  const handleJobClick = (job) => {
    navigate(`/job-list/${job.id || job.job_id}`);
  };

  const handleTitle = (value) => {
    setParameters({ ...parameters, title: value });
  };

  const handleLocation = (value) => {
    setParameters({ ...parameters, location: value });
  };
  const handleSponsorship = (value) => {
    setParameters({ ...parameters, sponsorship: value });
    setIsRequest(true);
  };

  const handleSalary = (value) => {
    setParameters({ ...parameters, salary: value });
    setIsRequest(true);
  };

  const handleIndustry = (value) => {
    setParameters({ ...parameters, industry: value });
    setIsRequest(true);
  };

  const handleDuration = (value) => {
    setParameters({ ...parameters, duration: value });
    setIsRequest(true);
  };

  const handleDepartmentType = (value) => {
    setParameters({ ...parameters, department: value });
    setIsRequest(true);
  };

  const handleExperienceLevel = (value) => {
    setParameters({ ...parameters, experience: value });
    setIsRequest(true);
  };

  return (
    <div className="jobs-container mx-2 max-w-[1324px] sm:m-auto">
      <div className="p-4">
        <div className="banner-input-wrapper flex justify-between p-3 bg-[#fff] rounded-xl mx-auto flex-wrap gap-y-3 md:gap-y-0">
          <div className="w-[100%] md:w-[50%] relative">
            <div className="absolute left-0 top-[50%] translate-y-[-50%]">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="29"
                viewBox="0 0 28 29"
                fill="none"
              >
                <path
                  d="M25.1184 24.3817L20.6372 19.9005C22.1352 18.1237 23.0417 15.8335 23.0417 13.3333C23.0417 7.70417 18.4625 3.125 12.8333 3.125C7.20417 3.125 2.625 7.70417 2.625 13.3333C2.625 18.9625 7.20417 23.5417 12.8333 23.5417C15.3335 23.5417 17.6237 22.6352 19.4005 21.1372L23.8816 25.6183C24.052 25.7887 24.276 25.875 24.5 25.875C24.724 25.875 24.948 25.7898 25.1184 25.6183C25.4602 25.2777 25.4602 24.7235 25.1184 24.3817ZM4.375 13.3333C4.375 8.669 8.169 4.875 12.8333 4.875C17.4977 4.875 21.2917 8.669 21.2917 13.3333C21.2917 17.9977 17.4977 21.7917 12.8333 21.7917C8.169 21.7917 4.375 17.9977 4.375 13.3333Z"
                  fill="#4A4C56"
                />
              </svg>
            </div>
            <Input
              placeholder="Search job description, job name or company name"
              className="border-none rounded-none pl-10 placeholder:opacity-100"
              labelProps={{
                className: "before:content-none after:content-none",
              }}
              value={parameters?.title}
              onKeyDown={(e) => e.key == "Enter" && setIsRequest(true)}
              onChange={(e) => handleTitle(e.currentTarget.value)}
            />
          </div>
          <Autocomplete
            onLocationChange={handleLocation}
            fetchResult={() => setIsRequest(true)}
            defaultValue={jobParameters?.location}
          />
        </div>
        <div className="toggle-btn-container flex-wrap">
          <div className="relative">
            <Select
              variant="static"
              placeholder="Select Duration"
              onChange={(e) => handleDuration(e)}
              value={parameters?.duration}
              className="jobDurationList"
            >
              {jobCommitments?.map((commitment, index) => (
                <Option value={commitment?.value} key={index}>
                  {commitment?.label}
                </Option>
              ))}
            </Select>
          </div>

          <div className="relative">
            <Select
              variant="static"
              value={parameters?.department}
              onChange={(value) => handleDepartmentType(value)}
              placeholder="Select Field"
              className="jobDurationList"
            >
              {jobCategories?.map((field, index) => (
                <Option value={field?.value} key={index}>
                  {field?.label}
                </Option>
              ))}
            </Select>
          </div>
          <div className="relative">
            <Select
              variant="static"
              value={parameters?.experience}
              onChange={(value) => handleExperienceLevel(value)}
              placeholder="Select Experience"
              className="jobDurationList"
            >
              {jobLevels?.map((field, index) => (
                <Option value={field?.value} key={index}>
                  {field?.label}
                </Option>
              ))}
            </Select>
          </div>
          <div className="relative">
            <CustomTooltip
              title="This is the minimum salary. By selecting a value, you'll see job postings with salaries above your chosen amount."
              placement="top"
            >
              <Select
                variant="static"
                value={parameters?.salary?.toString()}
                onChange={(value) => handleSalary(value)}
                placeholder="Mimimum Salary"
                className="jobDurationList"
              >
                {salaryLimit?.map((field, index) => (
                  <Option value={field?.value?.toString()} key={index}>
                    {">= "}
                    {field?.label}
                  </Option>
                ))}
              </Select>
            </CustomTooltip>
          </div>
          <div className="relative">
            <Select
              variant="static"
              value={parameters?.industry}
              onChange={(value) => handleIndustry(value)}
              placeholder="Select Industry"
              className="jobDurationList"
            >
              {jobIndustries?.map((field, index) => (
                <Option value={field?.value} key={index}>
                  {field?.label}
                </Option>
              ))}
            </Select>
          </div>
          <div className="jobs-container mx-2 max-w-[1324px] sm:m-auto"></div>
        </div>
        <div className="tags-list mt-2">
          {jobIndustries.map((tag, index) => (
            <div
              key={index}
              className={`tag-container ${
                tag?.value == parameters?.industry ? "selected-tag" : ""
              }`}
              onClick={() => {
                handleIndustry(tag?.value);
              }}
            >
              {tag?.icon}
              <div>{tag?.label}</div>
            </div>
          ))}
        </div>
        <div className="flex justify-center">
          <div className="flex flex-row items-center gap-6">
            {/* <div className="flex items-center justify-center p-4">
              <CustomTooltip
                title="This job board exclusively features companies that have actively sponsored work visas over the past 5 years."
                placement="bottom"
              >
                <FormControlLabel
                  control={
                    <Switch
                      checked
                      disabled
                      sx={{
                        "& .MuiSwitch-switchBase.Mui-checked": {
                          color: "#164ed4",
                        },
                        "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
                          {
                            backgroundColor: "#164ed4",
                          },
                      }}
                    />
                  }
                  label="Sponsorship Friendly Companies"
                />
              </CustomTooltip>
            </div> */}
            <div className="flex items-center justify-center p-4">
              {/* <FormControlLabel
                control={
                  <Switch
                    checked={parameters.sponsorship}
                    onChange={(e) => handleSponsorship(e.target.checked)}
                    sx={{
                      "& .MuiSwitch-switchBase.Mui-checked": {
                        color: "#164ed4",
                      },
                      "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
                        {
                          backgroundColor: "#164ed4",
                        },
                    }}
                  />
                }
                label="Guaranteed Visa Sponsorship Jobs"
              /> */}
            </div>
          </div>
        </div>
        <div className="flex gap-[8px] items-center pb-[8px] border-b border-[#E9E9EA] mb-[1.25%]">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
          >
            <path
              d="M13.5 3.9375H12.5625V3C12.5625 2.27625 11.9738 1.6875 11.25 1.6875H6.75C6.02625 1.6875 5.4375 2.27625 5.4375 3V3.9375H4.5C2.6865 3.9375 1.6875 4.9365 1.6875 6.75V6.83926V8.43979V13.5C1.6875 15.3135 2.6865 16.3125 4.5 16.3125H13.5C15.3135 16.3125 16.3125 15.3135 16.3125 13.5V8.43979V6.83926V6.75C16.3125 4.9365 15.3135 3.9375 13.5 3.9375ZM6.5625 3C6.5625 2.8965 6.6465 2.8125 6.75 2.8125H11.25C11.3535 2.8125 11.4375 2.8965 11.4375 3V3.9375H6.5625V3ZM2.8125 6.75C2.8125 5.56725 3.31725 5.0625 4.5 5.0625H13.5C14.6827 5.0625 15.1875 5.56725 15.1875 6.75V6.83926V8.43979C15.1875 8.66554 15.0593 8.86271 14.853 8.95496C14.2043 9.24446 13.0988 9.65702 11.5185 9.97952C11.4413 9.99527 11.358 9.93603 11.313 9.87003C10.7692 9.05778 9.90525 8.57327 9 8.57327C8.09475 8.57327 7.23075 9.05778 6.687 9.87003C6.64275 9.93678 6.5625 9.99677 6.4815 9.97952C4.902 9.65702 3.79575 9.24446 3.147 8.95496C2.94075 8.86271 2.8125 8.66554 2.8125 8.43979V6.83926V6.75ZM13.5 15.1875H4.5C3.31725 15.1875 2.8125 14.6827 2.8125 13.5V10.035C3.525 10.3433 4.66725 10.7565 6.25575 11.0813C6.77325 11.1863 7.31175 10.9575 7.6215 10.4948C7.956 9.99528 8.47125 9.69754 9 9.69754C9.52875 9.69754 10.044 9.99528 10.3778 10.4948C10.6373 10.8825 11.0558 11.1067 11.49 11.1067C11.574 11.1067 11.6587 11.0985 11.7427 11.0813C13.332 10.7565 14.4742 10.3433 15.1867 10.035V13.5C15.1875 14.6827 14.6827 15.1875 13.5 15.1875ZM9.765 11.25C9.765 11.664 9.429 12 9.015 12C8.601 12 8.26125 11.664 8.26125 11.25C8.26125 10.836 8.59275 10.5 9.0075 10.5H9.015C9.429 10.5 9.765 10.836 9.765 11.25Z"
              fill="#636366"
            />
          </svg>
          <p className="all-jobs-title">All Jobs</p>
        </div>

        <GoldBanner />

        <InfiniteScroll
          dataLength={jobs?.length} //This is important field to render the next data
          next={fetchJobs}
          hasMore={page != null}
          loader={
            scrollLoader && (
              <div className="flex flex-wrap">
                {Array.from(new Array(12)).map((_, index) => (
                  <div key={index} className="w-full md:w-1/2 lg:w-1/3 p-2">
                    <JobCardSkeleton />
                  </div>
                ))}
              </div>
            )
          }
          endMessage={
            <p style={{ textAlign: "center" }}>
              {jobs?.length > 0 ? (
                !authState?.isLoggedIn ? (
                  <b
                    className="cursor-pointer hover:text-[#164ED4]"
                    onClick={() => navigate("/app/home")}
                  >
                    To view more jobs, please login with google
                  </b>
                ) : (
                  <b>You have reached the end of list</b>
                )
              ) : (
                <b>No Data Found</b>
              )}
            </p>
          }
          className="!overflow-hidden"
        >
          <div className="grid grid-cols-1 gap-8 mb-4 lg:grid-cols-2 xl:grid-cols-3">
            {jobs?.length > 0 &&
              jobs?.map((job, index) => (
                <div key={index} className="flex flex-col h-full">
                  <div className="h-120 overflow-hidden">
                    {" "}
                    <JobCard
                      job={job}
                      updateCartCount={updateCartCount}
                      onDetailsClick={handleModalOpen}
                    />
                  </div>
                  <div className="flex-grow"></div>
                </div>
              ))}
          </div>
          {/* Modal */}
          {isModalOpen && selectedJob && (
            <JobDetails
              open={isModalOpen}
              handleClose={handleModalClose}
              data={selectedJob} // Pass selected job to modal
            />
          )}
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default JobList;
