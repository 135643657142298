import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { login } from "../../redux/reducers/auth";
import { Button } from "@material-tailwind/react";
import {
  handleGoogleLogin,
  loginUser,
  fetchUserByEmail,
  insertToUsers,
  getUserByEmail,
} from "../../services/auth";
import { toast } from "react-toastify";
import { GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const checkout = useLocation().state;

  const [userData, setUserData] = useState({
    email: "",
    password: "",
  });

  const handleLogin = async () => {
    try {
      const response = await loginUser(userData);
      const getUser = await getUserByEmail(response?.user?.email);
      if (response?.user) {
        dispatch(
          login({
            ...getUser[0],
            user_id: getUser[0]?.id,
            ...response?.user,
          })
        );
        toast.success("You're logged in!");
        if (checkout?.checkout_process) navigate("/app/checkout");
        else navigate("/app/home");
      } else {
        toast.error(response?.message);
      }
    } catch (e) {}
  };

  const handleLoginWithGoogle = async (response) => {
    const decoded = jwtDecode(response?.credential);
    const user = await fetchUserByEmail(decoded?.email);
    if (!user) {
      await insertToUsers({
        email: decoded?.email,
        firstName: decoded?.given_name,
        lastName: decoded?.family_name,
        picture: decoded?.picture,
      });
    }
    await handleGoogleLogin(response);
    if (checkout?.checkout_process) navigate("/app/checkout");
    else navigate("/app/home");
  };

  return (
    <div className="relative flex flex-col justify-center min-h-screen overflow-hidden">
      <div className="w-full p-6 m-auto bg-white rounded-md shadow-md lg:max-w-xl">
        <h1 className="text-3xl font-semibold text-center text-[#164ED4] underline">
          Sign in
        </h1>
        <form className="mt-6">
          <div className="mb-2">
            <label
              name="email"
              className="block text-sm font-semibold text-gray-800"
            >
              Email
            </label>
            <input
              type="email"
              className="block w-full px-4 py-2 mt-2 text-[#164ED4] bg-white border rounded-md focus:border-[#164ED4] focus:[#164ED4] focus:outline-none focus:ring focus:ring-opacity-40"
              onChange={(e) =>
                setUserData({ ...userData, email: e.target?.value })
              }
              value={userData?.email}
            />
          </div>
          <div className="mb-2">
            <label
              name="password"
              className="block text-sm font-semibold text-gray-800"
            >
              Password
            </label>
            <input
              type="password"
              className="block w-full px-4 py-2 mt-2 text-[#164ED4] bg-white border rounded-md focus:border-[#164ED4] focus:ring-[#164ED4] focus:outline-none focus:ring focus:ring-opacity-40"
              onChange={(e) =>
                setUserData({ ...userData, password: e.target?.value })
              }
              value={userData?.password}
            />
          </div>
          <a href="#" className="text-xs text-[#164ED4] hover:underline">
            Forget Password?
          </a>
          <div className="flex flex-col gap-2 justify-center">
            <div className="mt-6">
              <Button
                size="lg"
                className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-[#164ED4] rounded-md hover:bg-[#164ED4] focus:outline-none focus:bg-[#164ED4]"
                onClick={() => {
                  handleLogin();
                }}
              >
                Login
              </Button>
            </div>
            <div className="m-auto">or</div>
            <div className="m-auto">
              <GoogleLogin
                onSuccess={(credentialResponse) =>
                  handleLoginWithGoogle(credentialResponse)
                }
                onError={() => {}}
                useOneTap={false}
                width={400}
              />
            </div>
          </div>
        </form>

        <p className="mt-8 text-xs font-light text-center text-gray-700">
          {" "}
          Don't have an account?{" "}
          <a
            href="#"
            className="font-medium text-[#164ED4] hover:underline"
            onClick={() => navigate("/auth/signup")}
          >
            Sign up
          </a>
        </p>
      </div>
    </div>
  );
};

export default Login;
