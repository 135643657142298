import React from "react";
import { Box, Skeleton, Typography } from "@mui/material";

const SkeletonCard = () => {
  return (
    <Box display="flex" padding={10} gap={8}>
      <Box width="40%" padding={2} borderRadius="8px" boxShadow={1}>
        <Typography variant="h6" gutterBottom>
          <Skeleton width="60%" />
        </Typography>
        <Box display="flex" flexDirection="column" gap={2}>
          <Box display="flex" justifyContent="space-between">
            <Skeleton width="40%" height={40} />
            <Skeleton width="20%" height={40} />
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Skeleton width="40%" height={40} />
            <Skeleton width="20%" height={40} />
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Skeleton width="40%" height={40} />
            <Skeleton width="20%" height={40} />
          </Box>
        </Box>
      </Box>

      {/* Card 2 */}
      <Box width="60%" padding={2} borderRadius="8px" boxShadow={1}>
        <Typography variant="h6" gutterBottom>
          <Skeleton width="80%" />
        </Typography>
        <Box display="flex" justifyContent="center">
          <Skeleton variant="circular" width={100} height={100} />
        </Box>
        <Typography variant="body1" gutterBottom>
          <Skeleton width="60%" />
        </Typography>
        <Typography variant="body1">
          <Skeleton width="40%" />
        </Typography>
      </Box>

      {/* Card 3 */}
      {/* <Box width="30%" padding={2} borderRadius="8px" boxShadow={1}>
        <Typography variant="h6" gutterBottom>
          <Skeleton width="80%" />
        </Typography>
        <Box display="flex" justifyContent="center">
          <Skeleton variant="circular" width={100} height={100} />
        </Box>
        <Typography variant="body1" gutterBottom>
          <Skeleton width="60%" />
        </Typography>
        <Typography variant="body1">
          <Skeleton width="40%" />
        </Typography>
      </Box> */}
    </Box>
  );
};

export default SkeletonCard;
