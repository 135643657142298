import React, { useState, useEffect, useRef } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./ContactForm.css";
import { sendMessage } from "../../services/sendMessage";

const ContactForm = () => {
  const authState = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const hCaptchaRef = useRef(null); // Create a reference for hCaptcha
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const [isVerified, setIsVerified] = useState(false);

  useEffect(() => {
    if (authState?.user?.email) {
      setEmail(authState.user.email);
    }
  }, [authState]);

  useEffect(() => {
    if (!authState?.isLoggedIn) {
      toast.warn("Please login to send a message");
      setTimeout(() => {
        navigate("/app/home");
      }, 3000); // Redirect after 3 seconds
    }
  }, [authState, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!recaptchaToken) {
      toast.error("Please complete the hCaptcha");
      return;
    }

    const sanitizedEmail = email.replace(/[^a-zA-Z0-9@.]/g, "");
    const sanitizedSubject = subject.replace(/[^a-zA-Z0-9 ]/g, "");
    const sanitizedMessage = message.replace(/[^a-zA-Z0-9 .,!?]/g, "");

    const body = {
      email: sanitizedEmail,
      subject: sanitizedSubject,
      message: sanitizedMessage,
      recaptchaToken,
    };

    try {
      const response = await sendMessage(body);
      if (response.status >= 200 && response.status < 300) {
        toast.success("Message sent successfully");

        // Clear the form fields
        setEmail("");
        setSubject("");
        setMessage("");

        // Reset the hCaptcha
        if (hCaptchaRef.current) {
          hCaptchaRef.current.resetCaptcha(); // Call the reset method on hCaptcha
        }

        // Reset captcha token and verified state
        setRecaptchaToken(null);
        setIsVerified(false);
      } else if (response.status >= 400 && response.status < 500) {
        toast.error(
          `Failed to send message: ${
            response.data?.message || "Client error occurred."
          }`
        );
      } else if (response.status >= 500) {
        toast.error("Server error! Please try again later.");
      } else {
        toast.error("Unexpected error. Please try again.");
      }
    } catch (error) {
      toast.error("An error occurred. Please try again.");
    }
  };

  const handleCaptchaVerify = (token) => {
    setRecaptchaToken(token);
    setIsVerified(true);
  };

  const handleCaptchaExpire = () => {
    setRecaptchaToken(null);
    setIsVerified(false);
  };

  if (!authState?.isLoggedIn) {
    return (
      <>
        <ToastContainer />
      </>
    );
  }

  return (
    <section className="contact-form bg-white dark:bg-gray-900">
      <div className="container py-8 lg:py-16 px-4 mx-auto max-w-screen-md">
        <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-center text-gray-900 dark:text-white">
          Contact Us
        </h2>
        <p className="mb-8 lg:mb-16 font-light text-center text-gray-500 dark:text-gray-400 sm:text-xl">
          Got a technical issue? Need help with your job applications? Have
          questions about or our services? Looking to share feedback? Let us
          know.
        </p>
        <form onSubmit={handleSubmit} className="space-y-8">
          <div>
            <label
              htmlFor="email"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Your email
            </label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
              placeholder="name@flowbite.com"
              required
            />
          </div>
          <div>
            <label
              htmlFor="subject"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Subject
            </label>
            <input
              type="text"
              id="subject"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
              placeholder="Let us know how we can help you"
              required
            />
          </div>
          <div className="sm:col-span-2">
            <label
              htmlFor="message"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400"
            >
              Your message
            </label>
            <textarea
              id="message"
              rows="6"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Leave a comment..."
              required
            ></textarea>
          </div>
          <div className="flex justify-center">
            <HCaptcha
              sitekey="d2f61294-4192-4f7c-bd54-ae6890a6ada8"
              onVerify={handleCaptchaVerify}
              onExpire={handleCaptchaExpire}
              ref={hCaptchaRef} // Attach the reference to the HCaptcha component
            />
          </div>
          <button
            type="submit"
            className={`py-3 px-5 text-sm font-medium text-center text-white rounded-lg bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-500 dark:hover:bg-blue-600 dark:focus:ring-blue-300 ${
              !isVerified ? "opacity-50 cursor-not-allowed" : ""
            }`}
            disabled={!isVerified}
          >
            Send message
          </button>
        </form>
      </div>
      <ToastContainer />
    </section>
  );
};

export default ContactForm;
