import React, { useState } from "react";
import { BellIcon } from "@heroicons/react/solid";
import { Divider } from "@mui/material";
import { saveSubscription } from "../../supabaseServices/newsLetterSubscription";
const NewsletterSubscription = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic email validation regex
    return emailRegex.test(email);
  };

  const handleSubscribe = async () => {
    if (validateEmail(email)) {
      setError("");
      const response = await saveSubscription(email);

      if (response.success) {
        setSuccessMessage("Thank you for subscribing!");
        setEmail(""); // Clear the input field
      } else {
        setError("Failed to subscribe. Please try again.");
      }
    } else {
      setError("Please enter a valid email address.");
    }
  };

  return (
    <div className="col-span-12 lg:col-span-2 max-h-[600px] bg-gray-100 p-4 space-y-6 mt-6">
      {/* Top Divider */}
      <Divider />

      <div className="flex items-center space-x-3">
        <BellIcon className="h-6 w-6 text-[#164ED4]" />
        <h2 className="text-lg font-bold text-gray-800">
          Subscribe to our Newsletter
        </h2>
      </div>
      <p className="text-sm text-gray-600">
        Stay updated with the latest news and updates. Enter your email below.
      </p>
      <div className="flex flex-col space-y-3">
        <input
          type="email"
          placeholder="Enter your email"
          className={`border ${
            error ? "border-red-500" : "border-gray-300"
          } rounded-md p-2 text-sm focus:outline-none focus:ring-2 ${
            error ? "focus:ring-red-500" : "focus:ring-[#164ED4]"
          }`}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        {error && <p className="text-red-500 text-sm">{error}</p>}
        {successMessage && (
          <p className="text-green-500 text-sm">{successMessage}</p>
        )}
        <button
          onClick={handleSubscribe}
          className="bg-[#164ED4] text-white rounded-md py-2 px-4 text-sm hover:bg-[#1a5ae0] transition-all"
        >
          Subscribe
        </button>
      </div>

      {/* Bottom Divider */}
      <Divider />
    </div>
  );
};

export default NewsletterSubscription;
