import React, { Component } from "react";

class BlogCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timeAgo: "",
    };
  }

  componentDidMount() {
    const { blog } = this.props;
    if (blog && blog.PublishedDate) {
      this.calculateTimeAgo(blog.PublishedDate);
    }
  }

  calculateTimeAgo = (publishedDate) => {
    const published = new Date(publishedDate);
    const now = new Date();
    const diffInMilliseconds = now - published;
    const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));

    let timeAgo;
    if (diffInMinutes < 60) {
      timeAgo = `${diffInMinutes} minute(s) ago`;
    } else if (diffInMinutes < 1440) {
      const diffInHours = Math.floor(diffInMinutes / 60);
      timeAgo = `${diffInHours} hour(s) ago`;
    } else {
      const diffInDays = Math.floor(diffInMinutes / 1440);
      timeAgo = `${diffInDays} day(s) ago`;
    }

    this.setState({ timeAgo });
  };

  render() {
    const { blog } = this.props;
    const { timeAgo } = this.state;
    const BASE_URL = "https://kazisai.us";

    if (!blog) {
      return <div>Loading...</div>; // Fallback if blog is undefined
    }

    const { Subject, Title, Author, MetaDescription, CoverImage, MetaTitle } =
      blog;

    return (
      <section className="space-y-4">
        <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-4 py-4 items-start">
          {/* Image */}
          <div className="lg:col-span-1 sm:col-span-2 order-1">
            {(CoverImage && CoverImage.length > 0) || MetaTitle ? (
              <img
                src={
                  CoverImage && CoverImage.length > 0
                    ? `${BASE_URL}${CoverImage[0]?.formats?.small?.url}`
                    : MetaTitle // Fallback to MetaTitle if CoverImage is not available
                }
                className="w-full h-auto mx-auto mb-4 md:mb-0 md:w-3/4 sm:order-none md:order-1 order-1 lg:order-3"
                alt={Title || "Default Alt Text"} // Fallback alt text
              />
            ) : (
              <img
                src="/path/to/default-image.jpg" // Fallback to default image if neither CoverImage nor MetaTitle is available
                className="w-full h-auto mx-auto mb-4 md:mb-0 md:w-3/4 sm:order-none md:order-1 order-1 lg:order-3"
                alt={Title || "Default Alt Text"} // Fallback alt text
              />
            )}
          </div>

          {/* Blog Content */}
          {/* Blog Content */}
          <div className="lg:col-span-2 flex flex-col justify-between font-nytimes">
            {/* Subject */}
            <p className="text-[#164ED4] text-sm font-semibold break-words">
              {Subject}
            </p>

            {/* Title */}
            <h1 className="text-xl lg:text-2xl font-bold leading-tight break-words hover:text-[#164ED4] transition-colors duration-300">
              {Title}
            </h1>

            {/* Author and Time */}
            <div className="text-sm text-gray-600 mt-2 flex items-center justify-between">
              <p className="truncate max-w-full">{Author}</p>
              <span>{timeAgo}</span>
            </div>

            {/* Description */}
            <p className="text-gray-800 mt-4 line-clamp-5 break-words">
              {MetaDescription}
            </p>
          </div>
        </div>
        <hr />
      </section>
    );
  }
}

export default BlogCard;
