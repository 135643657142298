import { jsonData } from "./data";

// Function to get Petitions Filed data for a range of years
export const getPetitionsFiled = (startYear, endYear) => {
  const data = {};
  for (let year = startYear; year <= endYear; year++) {
    data[year] = jsonData.PetitionsFiled[year]?.Total || 0;
  }
  return data;
};

// Function to get Approved Petitions data for a range of years
export const getApprovedPetitions = (startYear, endYear) => {
  const data = {};
  for (let year = startYear; year <= endYear; year++) {
    data[year] = jsonData.ApprovedPetitions[year]?.Total || 0;
  }
  return data;
};

// Function to get Country Data for a range of years
export const getCountryData = (startYear, endYear) => {
  const data = {};
  for (let year = startYear; year <= endYear; year++) {
    data[year] =
      jsonData.PetitionsApprovedByCountryOfBirthAndGender[year]?.Country || {};
  }
  return data;
};

// Function to get Age Data for a range of years
export const getAgeData = (startYear, endYear, ageRange) => {
  const data = {};
  for (let year = startYear; year <= endYear; year++) {
    const yearData = jsonData.PetitionsApprovedByAgeAndGender[year]?.Age || {};
    data[year] = ageRange ? { [ageRange]: yearData[ageRange] || {} } : yearData;
  }
  return data;
};

// Function to get Education Data for a range of years
export const getEducationData = (startYear, endYear) => {
  const data = {};
  for (let year = startYear; year <= endYear; year++) {
    data[year] =
      jsonData.PetitionsApprovedByEducationAndGender[year]?.EducationLevel ||
      {};
  }
  return data;
};

// Function to get Occupation Data for a range of years
export const getOccupationData = (startYear, endYear) => {
  const data = {};
  for (let year = startYear; year <= endYear; year++) {
    data[year] =
      jsonData.PetitionsApprovedByOccupation[year]?.OccupationCategory || {};
  }
  return data;
};

// Function to get Compensation By Occupation data for a range of years
export const getCompensationByOccupation = (startYear, endYear) => {
  const data = {};
  for (let year = startYear; year <= endYear; year++) {
    data[year] =
      jsonData.CompensationByOccupationsApproved[year]?.Occupation || {};
  }
  return data;
};

// Function to get Compensation By Education data for a range of years
export const getCompensationByEducation = (startYear, endYear) => {
  const data = {};
  for (let year = startYear; year <= endYear; year++) {
    data[year] = jsonData.CompensationByEducation[year]?.EducationLevel || {};
  }
  return data;
};

// Function to get Compensation By Occupation And Gender data for a range of years
export const getCompensationByOccupationAndGender = (startYear, endYear) => {
  const data = {};
  for (let year = startYear; year <= endYear; year++) {
    data[year] =
      jsonData.CompensationOccupationAndGender[year]?.Occupation || {};
  }
  return data;
};

// Function to get Industry Sector data for a range of years
export const getIndustrySectorData = (startYear, endYear) => {
  const data = {};
  for (let year = startYear; year <= endYear; year++) {
    data[year] = jsonData.IndustrySector[year]?.Sector || {};
  }
  return data;
};

// Function to get Location And Prior Status data for a range of years
export const getLocationAndPriorStatusData = (startYear, endYear) => {
  const data = {};
  for (let year = startYear; year <= endYear; year++) {
    data[year] = jsonData.LocationAndPriorStatus[year]?.Location || {};
  }
  return data;
};

// Function to get Validity Period data for a range of years
export const getValidityPeriodData = (startYear, endYear) => {
  const data = {};
  for (let year = startYear; year <= endYear; year++) {
    data[year] = jsonData.ValidityPeriod[year]?.ValidityPeriod || {};
  }
  return data;
};

// Function to get Premium Processing data for a range of years
export const getPremiumProcessingData = (startYear, endYear) => {
  const data = {};
  for (let year = startYear; year <= endYear; year++) {
    data[year] = jsonData.PremiumProcessing[year] || {};
  }
  return data;
};

// Function to get Requests For Evidence data for a range of years
export const getRequestsForEvidenceData = (startYear, endYear) => {
  const data = {};
  for (let year = startYear; year <= endYear; year++) {
    data[year] = jsonData.RequestsForEvidence[year] || {};
  }
  return data;
};
